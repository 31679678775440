import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { getCategory } from '../../../data/catlog/category';
import { getBrand } from '../../../data/catlog/brand';
import { UiLoading } from "../../ui/uiloading";
import { getCommon } from "../../../data/common";
import urlPath from "../../../url";
import TextEditor from "../../ui/texteditor";
import { fileUpload,deletedUploadFile } from "../../../data/catlog/fileupload";
import Cookies from "js-cookie";
const FormDatas = ({isLoading,formSubmite,singleData,attribute,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [attributeData,setattributeData]= useState(attribute)
	const [dropDownLoading,setDropDownLoading]= useState(false)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const megaMenu=async (e)=>{
		const {name,value}= e.target
		console.log(name,value)
		setDropDownLoading(true)
	let url =''
	 if(value== 'brand'){
		url=`${urlPath.brand}`
	   
	 }else  if(value== 'category'){
		url=`${urlPath.category}`
	 }else if(value== 'page'){
		url=`${urlPath.pagelist}`
	 }else if(value== 'type of product'){
		url=`${urlPath.producttype}`
	 }
	 await getCommon(url,'Table',Cookies.get('jwt')).then(result=>{
		if(result.success){
			setSingleFormData({...singleFormData, linktype:value})
			setattributeData(result.data)
			setDropDownLoading(false)
		}else{
			setattributeData([])
			setDropDownLoading(false)		
		}
	})
	}
	const handleBannerImageSelect= async(e) => {
		const single ={...singleFormData}
		console.log(e.target.files[0])
        e.preventDefault();
      
			const data = new FormData();
            data.append('type', 'banner');
            data.append('image', e.target.files[0]);
         await fileUpload(data).then((result) => {
              if (result.success) {
				single.mobilebanner= result.photo
              }
            });
			single.uploadtype='banner'
		setSingleFormData({...single})
      

    };
	const handleImageSelect = async(e) => {
		const single ={...singleFormData}
		console.log(e.target.files[0])
        e.preventDefault();
      
			const data = new FormData();
            data.append('type', 'banner');
            data.append('image', e.target.files[0]);
         await fileUpload(data).then((result) => {
              if (result.success) {
				single.banner= result.photo
              }
            });
			single.uploadtype='banner'
		setSingleFormData({...single})
      

    };
	const pageAddData=(e,type)=>{
		const {name,value} = e.target;
		const single ={...singleFormData}
		single[type]=value
		setSingleFormData({...single})
	}
	const deletedFile=async (e,type,file,filetype)=>{
		e.preventDefault()
		const single ={...singleFormData}
	
	
	 await deletedUploadFile(singleFormData[filetype],type).then((result) => {
		  if (result.success) {
			delete single.uploadtype
			delete single[filetype]
			
			setSingleFormData({...single})
		  }
		});
		

	}
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
										<div class="form-group">
										<Label className="col-form-label pt-0">
												Mega Menu Link<span>*</span>
												<span>:</span>
											</Label>
											<select
												className="form-control"
												id="validationCustom0"
												type="text"
												required=""
												name="linktype"
												onChange={(e)=>megaMenu(e)}
												value={singleFormData.linktype}
											>
												<option  className="form-control">Default</option>
												<option value="category" className="form-control">Category</option>
												<option  value="brand" className="form-control">Brands</option>
												<option  value="type of product" className="form-control">Type Of Product</option>
												<option  value="page" className="form-control">Page</option>
											
											    </select>
											</div>
											<div class="form-group">
											
											
												{
													dropDownLoading?
													<UiLoading />
													:
													attributeData.length !== 0?
													<>
												
													<FormGroup>
													<Label className="col-form-label pt-0">
												Type<span>*</span>
												<span>:</span>
											</Label>
													<select
												className="form-control"
												id="validationCustom0"
												type="text"
												required=""
												name="megamenu"
												onChange={(e)=>pageAddData(e,singleFormData.linktype)}
												value={singleFormData[singleFormData.linktype]}
											>
												<option  className="form-control">Default</option>
												{
													attributeData.map((d,i)=>(
														<option value={`${d._id}`} className="form-control">{d.name}</option>
													))
												}
												
												
											</select>
											</FormGroup>
											<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Description
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="textarea"
												required={true}
												name="description"
												value={singleFormData.description}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span>Manu Url
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												name="manuurl"
												value={singleFormData.manuurl}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>	
										
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Mobile Banner
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="file"
												name="file"
												onChange={(e)=>handleBannerImageSelect(e)}
												
											/>
											
										</FormGroup>
										{
													singleFormData['mobilebanner'] != undefined && singleFormData['mobilebanner'] != null  ?
													<div  style={{ position: 'relative' }}>
                        <img src={`${urlPath.imageurl}/${singleFormData['uploadtype']}/${singleFormData['mobilebanner']}`}  style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            // onClick={() => handleImageDelete(index)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
							onClick={(e)=>deletedFile(e,singleFormData['uploadtype'],singleFormData['mobilebanner'],'mobilebanner')}
                        >
                            X
                        </button>
                    </div>
													
													:''
												}
											<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Banner
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="file"
												name="file"
												onChange={(e)=>handleImageSelect(e)}
												
											/>
										</FormGroup>
										{
													singleFormData['banner'] != undefined && singleFormData['banner'] != null  ?
													<div  style={{ position: 'relative' }}>
                        <img src={`${urlPath.imageurl}/${singleFormData['uploadtype']}/${singleFormData['banner']}`}  style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            // onClick={() => handleImageDelete(index)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
							onClick={(e)=>deletedFile(e,singleFormData['uploadtype'],singleFormData['banner'],'banner')}
                        >
                            X
                        </button>
                    </div>
													
													:''
												}
											</>
											:''
												}
												
											</div>
											<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value="Save"/>
												
											</div>
										</FormGroup>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
