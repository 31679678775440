import React, { Fragment, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Breadcrumb from "../../common/breadcrumb";
// import data from "../../../assets/data/pro_list";
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import FormDatas from './form'
import {getExportFile,getCommon, postCommon, getSingleCommon,postImportFile, getSearchData,postExportFile } from '../../../data/common'
import { UiLoading } from "../../ui/uiloading";
import { useParams } from "react-router-dom";
import urlPath, { numberOfpage } from "../../../url";
import { fileUpload } from "../../../data/catlog/fileupload";
const Category = () => {
	const [checkedValue,setCheckedValues] = useState([])
	const {slug} = useParams()
	
	const [searchValue,setSearchValue] = useState('')
	const [searchDataValue,setSearchDataValue] = useState({})
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const [searchData,setSearchData] = useState(false)
	const [saveData,setSaveData] = useState(false)
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [singleData, setSingleData] = useState({ status: true,photo:[],categoryrelation:'Yes'})
	const [data, setData] = useState([])
	const [message,setMessage] = useState('')
	const [color,setColor] = useState('')
	const [searchDataList,setsearchDataList] = useState({})
	
	const showForm = (Type,id) => {
	
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.category,Cookies.get('jwt')).then(result => {
				setSingleData({...result.data,categoryrelation:'No' })
				setIsLoading(false)
			})

		} else {
			setSingleData({ status: true,photo:[],categoryrelation:'Yes' })
			setIsLoading(false)
		}

	}
	useEffect(() => {
			if(Cookies.get('jwt') !== undefined && Cookies.get('jwt') !== null){
				getCommon(`${urlPath.category}`,'Table',Cookies.get('jwt')).then(result => {
					console.log(result)
					if(result.success){
						if(result.data.length != 0){
							const data = []

							for (let i = 0; i < result.data.length; i++) {
								const d = {
									id: result.data[i]._id,
									'Sl': `${parseInt(i + 1)}`,
									name: result.data[i].name,
									slug: result.data[i].slug,
									Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
				
								}
								data.push(d)
							}
							setData(data)
							setIsLoading(false)
						}else{
							setData([])
							setIsLoading(false)
						}
						
					}else{
						if(result.message == "User Not Register" || result.message == "you are n't a authorised user"){
							window.location.href='/'
						}
					}
				
				}).catch(err => {
					console.log(err)
					// setIsLoading(false)
					// if(result.message == "User Not Register" || result.message == "you are n't a authorised user"){
					// 	window.location.href='/'
					// }
				})
			}else{
				window.location.href='/'
			}
			
		
			
		
	

	}, [])
	
	const formSubmite = (e, singleFormData) => {
		e.preventDefault()
		singleFormData.operation=operationType
		setSaveData(true)
		postCommon(urlPath.category,singleFormData,Cookies.get('jwt')).then(async result => {
			setSaveData(false)
			setIsLoading(true)
			if (result.success) {
		await getCommon(urlPath.category,'Table',Cookies.get('jwt')).then(result => {
					
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							slug: result.data[i].slug,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
						}
						data.push(d)
						setData([...data])
					}
					setOperationType('Table')
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})

	}
	const showSearch=async(type)=>{
		setSearchData(type)
		if(type == 'export'){
			if(Object.keys(searchDataList).length == 0){
				setColor('red')
				setMessage(`Data ${type}...`)
				await postExportFile(`${urlPath.category}/search-export`,Cookies.get('jwt'),searchDataList).then(result=>{
					window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
					setColor('green')
					setMessage(`Data ${type} Successfully`)
					
				}).catch(err=>{
					console.log(err)
				})
			}else{
				setColor('red')
				setMessage(`Data ${type}...`)
				await postExportFile(`${urlPath.category}/search-export`,Cookies.get('jwt'),searchDataList).then(result=>{
					window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
					setColor('green')
					setMessage(`Data ${type} Successfully`)
					
				}).catch(err=>{
					console.log(err)
				})
			}
		
		}else if(type == 'back'){
			setIsLoading(true)
			
			getCommon(urlPath.category,'Table',Cookies.get('jwt')).then(result => {
			
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						name: result.data[i].name,
						slug: result.data[i].slug,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
	
					}
					data.push(d)
					setData([...data])
				}
				setIsLoading(false)
				setOperationType('Table')
			}).catch(err => {
				console.log(err)
			})
		}
		
	}
 const uploadFileData =async(e,search)=>{
	 setColor('red')
	setMessage(`Data ${search}...`)
	const data = new FormData();
	data.append('image', e.target.files[0]);
	await postImportFile(`${urlPath.category}/excel-report`,Cookies.get('jwt'),data).then((result) => {
	     if (result.success) {
			 setColor('green')
			setMessage(`Data ${search} Successfully`)
			window.location.reload()
	     }else{

		 }
	   });
 }
 const searchForm=(e,type)=>{
	console.log(type)
	console.log(type == 'Multi Search')
	const {name,value} = e.target;
	let single ={...searchDataValue}
	single[`${name}`]=value
	single[`operation`] = type
	if(type == 'Multi Search'){
		
		if(!searchData){
			if(checkedValue.length != 0){
				setSearchDataValue({...single})
				setIsLoading(true)
				searchDataValue['operation']= type
				searchDataValue['selectdata']= checkedValue
				searchDataValue[`${name}`]= value
				searchDataValue['searchvalue']= searchData
				postCommon(urlPath.category,searchDataValue,Cookies.get('jwt')).then(result=>{
					const data = []
					if(result.data.length != 0){
			
						for (let i = 0; i < result.data.length; i++) {
							const d = {
								id: result.data[i]._id,
								'Sl': `${parseInt(i + 1)}`,
								name: result.data[i].name,
								slug: result.data[i].slug,
								Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
			
							}
							data.push(d)
							setData([...data])
							setOperationType('Table')
								setIsLoading(false)
						}
					}else{
						setData([])
						setOperationType('Table')
						setIsLoading(false)
					}
								
				})
			}else{
				alert("Please Select At Least One Element")
			}
		}else if(searchData){
			if(Object.keys(searchDataValue).length != 0){
			setSearchDataValue({...single})
			setIsLoading(true)
			searchDataValue['operation']= type
			searchDataValue['selectdata']= checkedValue
			searchDataValue[`${name}`]= value
			searchDataValue['searchvalue']= searchData
			
			postCommon(urlPath.category,searchDataValue,Cookies.get('jwt')).then(result=>{
				const data = []
				if(result.data.length != 0){
		
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							slug: result.data[i].slug,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
		
						}
						data.push(d)
						setData([...data])
						setOperationType('Table')
							setIsLoading(false)
					}
				}else{
					setData([])
					setOperationType('Table')
					setIsLoading(false)
				}
							
			})
		}else{
			alert("Please select Status ")
		}
		}else{
			alert("Please Select At Least One Element")
		}

	}else if(type == 'Search Data'){
		setSearchData(true)
		single[`${name}`]=value
	
		setSearchDataValue({...single})
	}else if(type == 'Search'){
		if(name == 'status'){
			single[`${name}`] = value
		}else{
			single[`${value}`] = ''
			single[`operation`] = type
			setSearchValue(value)
		}
		console.log(single)
		setSearchDataValue({...single})
		// getSearchData
		
	}
 }
 const searchForamData=(e)=>{
	e.preventDefault();
	if(Object.keys(searchDataValue).length != 0){
		setIsLoading(true)
		postCommon(urlPath.category,searchDataValue,Cookies.get('jwt')).then(result=>{
			const data = []
			if(result.data.length != 0){
	
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						name: result.data[i].name,
						slug: result.data[i].slug,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
	
					}
					data.push(d)
					setData([...data])
					setOperationType('Table')
						setIsLoading(false)
				}
			}else{
				setData([])
				setOperationType('Table')
				setIsLoading(false)
			}
						
		})
	}else{
		alert("Please select Status ")
	}
 }
 const handlePageChange = (page) => {
	
	setCurrentPage(page)
   	
  };
  const handleRemoveRow =(e,id)=>{
	  const checkData =[...checkedValue]
	  if(e.target.checked){
		  checkData.push(id)
		  setCheckedValues([...checkData])
		}else{
			const d=checkData.filter(df=> `${df}` != `${id}`)
			
			setCheckedValues([...d])
		}
		setSearchData(false)

	
}
const onRowClicked=(row, event)=>{
const dataList =[...data]
	}

return (
		
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Category`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Category`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'category'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
											<CardBody>
												
												{
													searchData == 'search'?
													<Form onSubmit={(e)=>searchForamData(e)}>
												<div className="row">
												<Col md="3">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select"  onChange={(e)=>searchForm(e,"Search")} >
											<option value="">--Select--</option>
											<option value="Name">Name</option>
											<option value="Slug">Slug</option>
										</select>
									</FormGroup>
												</Col>
												{
														searchValue != '' ?
														<Col md="3" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> {searchValue}
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name={searchValue}
												value={searchDataValue[searchValue]}
												onChange={(e)=>searchForm(e,'Search Data')}
												
											/>
										</FormGroup>
										
												</Col>
														
														:''
												}
												
												<Col md="3" sm="12">

										<FormGroup >

										<Label className="col-form-label">
											<span>*</span> Status
										</Label>
										<select className="form-select" name="status" value={searchDataValue.status != undefined && searchDataValue.status != null ? searchDataList.status :""} onChange={(e)=>searchForm(e,"Search")} >
											<option value="">--Select--</option>
											<option value="true">Active</option>
											<option value="false">In Active</option>
										</select>
									</FormGroup>
									</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="12" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													onChange={(e)=>uploadFileData(e,searchData)}
													
												/>
											</FormGroup>
											
													</Col>
												
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right" style={{textAlign:'right'}}>
												<FormGroup >

										<select className="form-select" name="multisearch" value={searchDataValue.multisearch != undefined && searchDataValue.multisearch != null ? searchDataValue.multisearch :''} onChange={(e)=>searchForm(e,'Multi Search')} >
											<option value="">--Multiple Deleted--</option>
											<option value="Deleted">Deleted</option>
											<option value="Duplicate">Duplicate</option>
											<option value="Select Export">Select Export</option>
										</select>
									</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={(e) => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={(e) => showSearch('import')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-upload" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={(e) => showSearch('export')}
														style={{'marginRight':'10px'}}
													>
													<i class="fa fa-download" aria-hidden="true"></i>
													</Button>
													<Button
														type="button"
														color="primary"
														onClick={() => showForm('Add')}
													>
													<i class="fa fa-plus" aria-hidden="true"></i>
													</Button>
								
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
												<h6 style={{textAlign:'center',color:`${color}`}}>{message}</h6>
													{
														isLoading ?
														<UiLoading/>
														:	<Datatable
														myData={data}
														multiSelectOption={true}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={false}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
														checkedValues={checkedValue}
														onRowClicked={(row, event)=>onRowClicked(row, event)}
													/>
													}
												
												</div>
												</CardBody>
						</Card>
											
										:isLoading ?
											<UiLoading/>
											:
											<Card>
					
											<CardBody>
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
									<Button
											type="button"
											color="primary"
											onClick={() => showSearch('back')}
											style={{'marginRight':'10px'}}
										>
									<i class="fa fa-arrow-left" aria-hidden="true"></i>
										</Button>
								</div>
								<div className="clearfix"></div>
											<FormDatas saveData={saveData} isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData,operationType)} />
											</CardBody>
															</Card>
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
			
	);
};

export default Category;

