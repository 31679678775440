import axios from 'axios';
import Cookies from 'js-cookie';
const setAuthToken = (token) => {
  if (token) {
    Cookies.set('jwt', token, {
      // // domain: 'loclahost',
      // path: '/',
      expires: 1,
      // secure: true
    });
    axios.defaults.headers.common['x-auth-token'] =`Basic ${token}`;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export default setAuthToken;
