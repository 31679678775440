import React from 'react'
import {CKEditor} from 'ckeditor4-react';

export const TextEditor=({title,description,name,onEditorChange,datadisable})=>(
  
    <div class="form-group">
    {/* <label for="formmessage">{title}</label> */}
    <CKEditor
      id="formmessage"
      className="form-control"
      rows="3"
      name={`${name}`}
      initData={description}
     
      onChange={(e)=>onEditorChange(e)}
      disabled={datadisable}
      config={{
        extraPlugins: "justify,font,colorbutton",
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          { name: "editing", groups: ["find", "selection", "spellchecker"] },
          { name: "forms" },
          "/",
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"] // 'align' -> 'justify' plugin
          },
          { name: "links" },
          { name: "insert" },
          "/",
          { name: "styles" }, // 'font and fontsize' -> 'font' plugin
          { name: "colors" }, // 'colors' -> 'colorbutton' plugin
          { name: "tools" },
          { name: "others" },
          { name: "about" }
        ]
      }}
    />
  </div>
  )


export default TextEditor