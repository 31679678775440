import React, { useState } from 'react';
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../data/catlog/fileupload';
import  urlPath  from '../../url';
import { UiLoading } from '../ui/uiloading';
const ImageUploader = ({handleImageDelete,image,uploadtype,handleImageSelect,showAddMoreButton,imageLoading}) => {
    console.log(image)
    const [images,setImages] = useState(image)
    const handleAddMoreImages = (e) => {
        e.preventDefault();
        document.querySelector('input[type="file"]').click();
    };
    return (
        <div>
            {
                imageLoading?
                <UiLoading />
                :''
            } 
            {/* <div>
            <div
                className="selectButton"
                style={{
                    border: '2px dashed #999',
                    padding: '10px',
                    display: showAddMoreButton ? 'none' : 'flex',
                    flexDirection: 'column',
                    //   width: '60%',
                    height: '150px',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px'
                }}

            >
                <button onClick={handleAddMoreImages} style={{ fontSize: '15px', backgroundColor: '#fff', borderRadius: '10px', padding: '10px', margin: '5px', cursor: 'pointer' }}>Add files</button>
                <span>Accept Images</span>
            </div>
            <input type="file" accept="image/*" multiple onChange={handleImageSelect} style={{ display: 'none' }} />
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
                {selectedImages.map((imageUrl, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <img src={imageUrl} alt={`Selected ${index}`} style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            onClick={() => handleImageDelete(index)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
                        >
                            X
                        </button>
                    </div>
                ))}
                {showAddMoreButton && (
                    <div
                        className="selectButton"
                        style={{
                            border: '2px dashed #999',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px'
                        }}

                    >
                        <button onClick={handleAddMoreImages} style={{ fontSize: '15px', backgroundColor: '#fff', borderRadius: '10px', padding: '10px', margin: '5px', cursor: 'pointer' }}>Add</button>
                        <span>Add More</span>
                    </div>
                )}
            </div>
        </div> */}

            <div
                className="selectButton"
                style={{
                    border: '2px dashed #999',
                    padding: '10px',
                    display: showAddMoreButton ? 'none' : 'flex',
                    flexDirection: 'column',
                    //   width: '60%',
                    height: '150px',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px'
                }}

            >
                <button onClick={(e)=>handleAddMoreImages(e)} style={{ fontSize: '15px', backgroundColor: '#fff', borderRadius: '10px', padding: '10px', margin: '5px', cursor: 'pointer' }}>Add files</button>
                <span>Accept Images</span>
            </div>
            <input type="file" accept="image/*" multiple onChange={(e)=>handleImageSelect(e)} style={{ display: 'none' }} />
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
                {image.map((imageUrl, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <img src={`${urlPath.imageurl}/${imageUrl.uploadtype}/${imageUrl.image}`} alt={`Selected ${index}`} style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            onClick={(e) => handleImageDelete(e,index,imageUrl.image)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
                        >
                            X
                        </button>
                    </div>
                ))}
                {showAddMoreButton && (
                    <div
                        className="selectButton"
                        style={{
                            border: '2px dashed #999',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px'
                        }}

                    >
                        <button onClick={handleAddMoreImages} style={{ fontSize: '15px', backgroundColor: '#fff', borderRadius: '10px', padding: '10px', margin: '5px', cursor: 'pointer' }}>Add</button>
                        <span>Add More</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUploader;