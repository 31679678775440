import urlPath from '../../url';
export const getCategory=async(table,status)=>{
    return fetch(`${urlPath.category}/${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getSingleCategory=async(id)=>{
    return fetch(`${urlPath.category}/${id}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const postCategory=async(data,table,operation)=>{
    return fetch(`${urlPath.category}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}