import React, { Fragment, useState } from "react";

import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";

const FormDatas = ({saveData,isLoading,formSubmite,singleData,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})

	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		if(name == 'order'){
			if(/^[0-9]+$/.test(value)){
				singleFormData[name] =value
			  }else{
				alert('Enter Numeric')
			  }
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}

	
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Title
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required={true}
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Message
											</Label>
											<textarea
												className="form-control"
												id="validationCustom01"
												type="text"
												
												name="statusmesage"
												value={singleFormData.statusmesage !== undefined && singleFormData.statusmesage !== null ?   singleFormData.statusmesage.toString() :''}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												 Display Color
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
											
												name="colortype"
												value={singleFormData.colortype}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												 Status Order
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
											
												name="order"
												value={singleFormData.order}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Order Fainal Stage
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="orderfinalstage"
														value={'Yes'}
														checked={`${singleFormData.orderfinalstage}` =='Yes' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Yes
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="orderfinalstage"
														value={'No'}
														checked={`${singleFormData.orderfinalstage}`== 'No' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													No
												</Label>
											</div>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
										
									</div>
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											{
												saveData && operationType == 'Edit' ?
												<button className="btn btn-primary" disabled={true} >{`${operationType}...`}</button>
												:<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`} />
											}
											
											
											</div>
										</FormGroup>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
