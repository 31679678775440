import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "./common/breadcrumb";
import Cookies from 'js-cookie';
import {
	Navigation,
	Box,
	MessageSquare,
	Users,
	Briefcase,
	CreditCard,
	ShoppingCart,
	Calendar,
} from "react-feather";
import CountUp from "react-countup";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Chart } from "react-google-charts";

import { Bar, Line } from "react-chartjs-2";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
	lineOptions,
	buyOption,
	employeeData,
	employeeOptions,
} from "../constants/chartData";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarController,
	BarElement,
	ArcElement,
	Filler,
	RadialLinearScale
  } from 'chart.js';

  
// image impoer
import user2 from "../assets/images/dashboard/user2.jpg";
import user1 from "../assets/images/dashboard/user1.jpg";
import man from "../assets/images/dashboard/man.png";
import user from "../assets/images/dashboard/user.png";
import designer from "../assets/images/dashboard/designer.jpg";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Media,
	Row,
	Table,
} from "reactstrap";
import './dashboard.css';
import { getCommon } from "../data/common";
import urlPath from "../url";
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarController,
	BarElement,
	ArcElement,
	  Filler,
	RadialLinearScale
  );

const Dashboard = () => {
	const [load1,setLoad]=useState(true)
	const [typeCalender,setTypeCalender]=useState('Today')
	const [codLoading,setCodLoading]=useState(true)
	const [prepaidLoading,setPrePaidLoading]=useState(true)
	const [OrderLoading,setOrderLoading]=useState(true)
	const [salesLoading,setSalesLoading]=useState(true)
	const [start,setStart]= useState(moment().startOf("day"))
	const [end,setEnd]= useState(moment().add(24, 'min'))
	const [dashBoardList,setDashBoardList] = useState(['order','sales','COD','Prepaid','Sales Graph','State Graph','Total Device','Total Product','Total Order'])
	const [orderList,setOrderList] = useState(0)
	const [salesAmount,setSalesAmount] = useState(0)
    const [currency,setCurrency] = useState('')
	const [COD,setCOD] = useState(0)
	const [prePaid,setPrePaid] = useState(0)
	const [startTime,setStartTime] = useState([])
	const [codData,setCodData] = useState([])
	const [salesData,setsalesData] = useState([])
	const [prepaidData,setPrepaidData] = useState([])
	const [stateList,setStateList] = useState([])
	const [stateSale,setStateSale] = useState([])
	const [stateCOD,setStateCOD] = useState([])
	const [statePrepaid,setStatePrepaid] = useState([])
	const [deviceType,setDeviceType] = useState([])
	const [letOfDevice,setLetOfDevice] = useState([])
	const [orderHourList,setOrderHourList] = useState([])
	const [orderHour,setOrderHour] = useState([])
	const [topSellingProduct,setTopSellingProduct] =useState([])
	const [topSellingProductList,setTopSellingProductList] =useState([])
	// const [load1,setLoad]=useState()
	const pieOptions = {
		title: "",
		pieHole: 1,
		slices: [
			{
				color: "#ff8084",
			},
			{
				color: "#13c9ca",
			},
			{
				color: "#f0b54d",
			},
		],
		tooltip: {
			showColorCode: false,
		},
		chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
		legend: "none",
	};
	const pieOptions1 = {
		title: "",
		pieHole: 1,
		slices: [
			{
				color: "#ff8084",
			},
			{
				color: "#13c9ca",
			},
			{
				color: "#f0b54d",
			},
		],
		tooltip: {
			showColorCode: false,
		},
		chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
		legend: "none",
	};
	const LineOptions = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#ff8084"],
		legend: "none",
	};
	const LineOptions1 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#13c9ca"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const LineOptions2 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#f5ce8a"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const LineOptions3 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#a5a5a5"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const startdateEnddate=(s,e)=>{

	}
	useState(()=>(
		setLoad(false)
	),[])
	const handleCallback=(start, end, label)=>{
		setStart(moment(start._d) )
		setEnd(moment(end._d))
		setTypeCalender(label)
		setOrderLoading(true)
		for(let i=0; i < dashBoardList.length;i++){
			if(dashBoardList[i] == 'order'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					if(result.success){
						setOrderList(result.countdata)
						setOrderLoading(false)
					}else{
						setOrderList(0)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'sales'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setSalesAmount(result.amount)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setOrderList(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'COD'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setCOD(result.amount)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setCOD(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'Prepaid'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setPrePaid(result.amount)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setPrePaid(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'Sales Graph'){
				
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						// setPrePaid(result.amount)
						setStartTime(result.startTime)
						setsalesData(result.sales)
						setPrepaidData(result.prepaid)
						setCodData(result.cod)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setPrePaid(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'State Graph'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						// setPrePaid(result.amount)
						setStateList(result.state)
						setStateCOD(result.stateorder)
					
						setOrderLoading(false)
					}else{
						setPrePaid(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'Total Device'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){

						setDeviceType(result.deviceper)
						setLetOfDevice(result.list)
					}else{
						
					}
					
				})
			}else if(dashBoardList[i] == 'Total Product'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setTopSellingProduct(result.totalProductData)
						setTopSellingProductList(result.productData)
					}else{
						
					}
					
				})
			}else if(dashBoardList[i] == 'Total Order'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					
					if(result.success){
						setOrderHourList(result.total)
						setOrderHour(result.starttime)
					}else{
						
					}
					
				})
			}
		}
	}
	const handleEvent=(event, picker)=>{
		console.log(picker.startDate);
	  }
	  useEffect(()=>{
		for(let i=0; i < dashBoardList.length;i++){
			if(dashBoardList[i] == 'order'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					if(result.success){
						setOrderList(result.countdata)
						setOrderLoading(false)
					}else{
						setOrderList(0)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'sales'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setSalesAmount(result.amount)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setOrderList(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'COD'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setCOD(result.amount)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setCOD(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'Prepaid'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						setPrePaid(result.amount)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setPrePaid(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'Sales Graph'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						// setPrePaid(result.amount)
						setStartTime(result.startTime)
						setsalesData(result.sales)
						setPrepaidData(result.prepaid)
						setCodData(result.cod)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}else{
						setPrePaid(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'State Graph'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
						// setPrePaid(result.amount)
						setStateList(result.state)
						setStateCOD(result.stateorder)
					
						setOrderLoading(false)
					}else{
						setPrePaid(0)
						setCurrency(result.currency.symbol)
						setOrderLoading(false)
					}
					
				})
			}else if(dashBoardList[i] == 'Total Product'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
				
					if(result.success){
						setTopSellingProduct(result.totalProductData)
						setTopSellingProductList(result.productData)
					
					}else{
						
					}
					
				})
			}else if(dashBoardList[i] == 'Total Device'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					console.log(result)
					if(result.success){
					
						setDeviceType(result.deviceper)
						setLetOfDevice(result.list)
					}else{
						
					}
					
				})
			}else if(dashBoardList[i] == 'Total Order'){
				getCommon(`${urlPath.dashboard}/${dashBoardList[i]}/${start.format('YYYY-MM-DD HH:mm:ss')}/${end.format('YYYY-MM-DD HH:mm:ss')}`).then(result=>{
					
					if(result.success){
						setOrderHourList(result.total)
						setOrderHour(result.starttime)
					}else{
						
					}
					
				})
			}
		}
	},[])
	return (
		<Fragment>
			<Breadcrumb title="Dashboard" parent="Dashboard" />
			
        

			<DateRangePicker initialSettings={{ startDate: start, endDate: end,ranges:{ 
			   'Today': [moment(), moment()],
               'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               'Last 7 Days': [moment().subtract(6, 'days'), moment()],
               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
               'This Month': [moment().startOf('month'), moment().endOf('month')],
               'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]} }} onCallback={(start, end, label)=>handleCallback(start, end, label)}>
             {/* <span>{`${moment(start).format('YYYY/MM/DD HH:MM:SS')}` + ' - ' + `${moment(end).format('YYYY/MM/DD HH:MM:SS')}`}</span> */}
			 <div id="daterange" style={{textAlign:'right'}}>
             
             <i class="fa fa-calendar"></i>
        <span>{`${start.format('DD/MM/YYYY HH:mm:ss') + ' - ' + end.format('DD/MM/YYYY HH:mm:ss')}`}</span>
         </div>
      		</DateRangePicker>
			<Container fluid={true}>
				<Row>
					<Col xl="3 xl-50" md="6">
						<Card className=" o-hidden widget-cards">
							<CardBody className="bg-warning">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<Navigation className="font-warning" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">Order</span>
										<h3 className="mb-0">
											<CountUp className="counter" end={!OrderLoading ? orderList :orderList} /><br/>
											<small> This {typeCalender}</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className=" o-hidden  widget-cards">
							<CardBody className="bg-secondary ">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<Box className="font-secondary" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">Sales</span>
										<h3 className="mb-0">
											{currency} <CountUp className="counter" end={!salesLoading ? salesAmount:salesAmount} /><br/>
											<small> This {typeCalender}</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					
					<Col xl="3 xl-50" md="6">
						<Card className="o-hidden widget-cards">
							<CardBody className="bg-primary">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<MessageSquare className="font-primary" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">Pre Paid</span>
										<h3 className="mb-0">
										{currency} <CountUp className="counter" end={!prepaidLoading?prePaid :prePaid} /><br/>
											<small> This {typeCalender}</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					
					<Col xl="3 xl-50" md="6">
						<Card className=" o-hidden widget-cards">
							<CardBody className="bg-danger ">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<Users className="font-danger" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">COD</span>
										<h3 className="mb-0">
										{currency} <CountUp className="counter" end={!codLoading? COD:COD} /><br/>
											<small> This {typeCalender}</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Total Orders Per Hours</h5>
							</CardHeader>
							<CardBody className="sell-graph">
							<div className="order-graph xl-space">
											
											<div className="ct-4 flot-chart-container">
												<Line  type="area" data={{
    												labels: orderHour,
    												datasets: [{
																lineTension: 0.4,
																data: orderHourList,
																borderColor: '#ff8084',
																backgroundColor: 'rgba(255, 128, 132, 0.1)',
																fill: true,
															}]}} options={employeeOptions} />
											</div>
										</div>
							</CardBody>
						</Card>
					</Col>
					
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Sales Per Hours</h5>
							</CardHeader>
							<CardBody className="sell-graph">
							
								<Line
									data={{
										labels: startTime,
										datasets: [
											{
												backgroundColor: "transparent",
												borderColor: "#13c9ca",
												data: codData,
												lineTension: 0.4,
											},
											{
												backgroundColor: "transparent",
												borderColor: "#a5a5a5",
												data: prepaidData,
												lineTension: 0.4,
											},
											{
												backgroundColor: "transparent",
												borderColor: "#ff8084",
												data: salesData,
												lineTension: 0.4,
											},
											// {
											// 	backgroundColor: "transparent",
											// 	borderColor: "#ffa5a5",
											// 	data: [0, 20, 20, 200, 20, 20],
											// 	lineTension: 0.4,
											// },
										],
									}}
									options={buyOption}
									width={700}
									height={350}
								/>
							</CardBody>
						</Card>
					</Col>
					<Col xl="6 xl-100">
						<Card>
							<CardHeader>
								<h5>Orders By State</h5>
							</CardHeader>
							<CardBody>
								<div className="market-chart">
									<Bar
										data={{
											labels: stateList,
											datasets: [
												{
													data: stateCOD,
													borderColor: "#13c9ca",
													backgroundColor: "#13c9ca",
													borderWidth: 2,
													barPercentage: 0.7,
													  categoryPercentage: 0.4,
												},
											],
										}}
										options={lineOptions}
										width={778}
										height={308}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col sm="12">
						<Card>
							
							<CardBody>
								<Row>
									<Col xl="3 xl-50" sm="6">
										<div className="order-graph">
											<h6>Top Selling Product</h6>
											<div className="chart-block chart-vertical-center">
											<Chart
													chartType="PieChart"
													data={topSellingProduct}
													options={pieOptions}
												
													width={"100%"}
													height={"180px"}
													legend_toggle
												/>
											</div>
											<div className="order-graph-bottom">
												{
													topSellingProductList.map(d=>(
														<Media>
														<div className="order-color-primary"></div>
														<Media body>
															<h6 className="mb-0">
																{d.product}
																<span className="pull-right">{d.qty}</span>
															</h6>
														</Media>
													</Media>
													))
												}
											
											</div>
										</div>
									</Col>
									<Col xl="3 xl-50" sm="6">
										<div className="order-graph sm-order-space">
											<h6>Orders By Device </h6>
											<div className="peity-chart-dashboard text-center">
												<Chart
													chartType="PieChart"
													data={deviceType}
													options={pieOptions}
													
													width={"100%"}
													height={"180px"}
													legend_toggle
												/>
											</div>
											<div className="order-graph-bottom sales-location">
												{
													letOfDevice.map(d=>(
												<Media>
													<div className="order-shape-primary"></div>
													<Media body>
														<h6 className="mb-0 me-0">
															{d.device} <span className="pull-right">{d.totalper}%</span>
														</h6>
													</Media>
												</Media>

													))
												}
											
											</div>
										</div>
									</Col>
								</Row>
								
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className=" order-graph sales-carousel">
							<CardHeader>
								<h6>Current Monthly</h6>
								<Row>
									<Col className="col-6">
										<div className="small-chartjs">
											<div
												className="flot-chart-placeholder"
												id="simple-line-chart-sparkline-3"
											>
												<Chart
													height={"60px"}
													chartType="LineChart"
													loader={<div>Loading Chart</div>}
													data={[
														["x", "time"],
														[0, 20],
														[1, 5],
														[2, 120],
														[3, 10],
														[4, 140],
														[5, 15],
													]}
													options={LineOptions}
													legend_toggle
												/>
											</div>
										</div>
									</Col>
									<Col className="col-6">
										<div className="value-graph">
											<h3>
												42%{" "}
												<span>
													<i className="fa fa-angle-up font-primary"></i>
												</span>
											</h3>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Media>
									<Media body>
										<span>Last Month</span>
										<h2 className="mb-0">9054</h2>
										<p>
											0.25%{" "}
											<span>
												<i className="fa fa-angle-up"></i>
											</span>
										</p>
										<h5 className="f-w-600 f-16">Gross sales of August</h5>
										<p>
											Lorem Ipsum is simply dummy text of the printing and
											typesetting
										</p>
									</Media>
									<div className="bg-primary b-r-8">
										<div className="small-box">
											<Briefcase />
										</div>
									</div>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className=" order-graph sales-carousel">
							<CardHeader>
								<h6>Last Quater</h6>
								<Row>
									<Col className="col-6">
										<div className="small-chartjs">
											<div
												className="flot-chart-placeholder"
												id="simple-line-chart-sparkline"
											>
												<Chart
													height={"60px"}
													chartType="LineChart"
													loader={<div>Loading Chart</div>}
													data={[
														["x", "time"],
														[0, 85],
														[1, 83],
														[2, 90],
														[3, 70],
														[4, 85],
														[5, 60],
														[6, 65],
														[7, 63],
														[8, 68],
														[9, 68],
														[10, 65],
														[11, 40],
														[12, 60],
														[13, 75],
														[14, 70],
														[15, 90],
													]}
													options={LineOptions1}
													legend_toggle
												/>
											</div>
										</div>
									</Col>
									<Col className="col-6">
										<div className="value-graph">
											<h3>
												20%{" "}
												<span>
													<i className="fa fa-angle-up font-secondary"></i>
												</span>
											</h3>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Media>
									<Media body>
										<span>Current Year</span>
										<h2 className="mb-0">2154</h2>
										<p>
											0.13%{" "}
											<span>
												<i className="fa fa-angle-up"></i>
											</span>
										</p>
										<h5 className="f-w-600 f-16">Avg Gross purchase</h5>
										<p>
											Lorem Ipsum is simply dummy text of the printing and
											typesetting
										</p>
									</Media>
									<div className="bg-secondary b-r-8">
										<div className="small-box">
											<CreditCard />
										</div>
									</div>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className="order-graph sales-carousel">
							<CardHeader>
								<h6>Last Month</h6>
								<Row>
									<Col className="col-6">
										<div className="small-chartjs">
											<div
												className="flot-chart-placeholder"
												id="simple-line-chart-sparkline-2"
											>
												<Chart
													height={"60px"}
													chartType="LineChart"
													loader={<div>Loading Chart</div>}
													data={[
														["x", "time"],
														[0, 85],
														[1, 83],
														[2, 90],
														[3, 70],
														[4, 85],
														[5, 60],
														[6, 65],
														[7, 63],
														[8, 68],
														[9, 68],
														[10, 65],
														[11, 40],
														[12, 60],
														[13, 75],
														[14, 70],
														[15, 90],
													]}
													options={LineOptions2}
													legend_toggle
												/>
											</div>
										</div>
									</Col>
									<Col className="col-6">
										<div className="value-graph">
											<h3>
												28%{" "}
												<span>
													<i className="fa fa-angle-up font-warning"></i>
												</span>
											</h3>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Media>
									<Media body>
										<span>Cash on hand</span>
										<h2 className="mb-0">4672</h2>
										<p>
											0.8%{" "}
											<span>
												<i className="fa fa-angle-up"></i>
											</span>
										</p>
										<h5 className="f-w-600 f-16">Details about cash</h5>
										<p>
											Lorem Ipsum is simply dummy text of the printing and
											typesetting
										</p>
									</Media>
									<div className="bg-warning b-r-8">
										<div className="small-box">
											<ShoppingCart />
										</div>
									</div>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className="order-graph sales-carousel">
							<CardHeader>
								<h6>Current Year</h6>
								<Row>
									<Col className="col-6">
										<div className="small-chartjs">
											<div
												className="flot-chart-placeholder"
												id="simple-line-chart-sparkline-1"
											>
												<Chart
													height={"60px"}
													chartType="LineChart"
													loader={<div>Loading Chart</div>}
													data={[
														["x", "time"],
														[0, 85],
														[1, 83],
														[2, 90],
														[3, 70],
														[4, 85],
														[5, 60],
														[6, 65],
														[7, 63],
														[8, 68],
														[9, 68],
														[10, 65],
														[11, 40],
														[12, 60],
														[13, 75],
														[14, 70],
														[15, 90],
													]}
													options={LineOptions3}
													legend_toggle
												/>
											</div>
										</div>
									</Col>
									<Col className="col-6">
										<div className="value-graph">
											<h3>
												75%{" "}
												<span>
													<i className="fa fa-angle-up font-danger"></i>
												</span>
											</h3>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Media>
									<Media body>
										<span>Security Deposits</span>
										<h2 className="mb-0">5782</h2>
										<p>
											0.25%{" "}
											<span>
												<i className="fa fa-angle-up"></i>
											</span>
										</p>
										<h5 className="f-w-600 f-16">Gross sales of June</h5>
										<p>
											Lorem Ipsum is simply dummy text of the printing and
											typesetting
										</p>
									</Media>
									<div className="bg-danger b-r-8">
										<div className="small-box">
											<Calendar />
										</div>
									</div>
								</Media>
							</CardBody>
						</Card>
					</Col>
					
						
					
					
				</Row>
			</Container>
		</Fragment>
	);
};

// javascript:void(0)

export default Dashboard;
