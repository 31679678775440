import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
	Navigation,
	Box,
	MessageSquare,
	Users,
	Briefcase,
	CreditCard,
	ShoppingCart,
	Calendar,
} from "react-feather";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";
import moment from 'moment';
import { Bar, Line } from "react-chartjs-2";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
	lineOptions,
	buyOption,
	employeeData,
	employeeOptions,
} from "../../constants/chartData";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarController,
	BarElement,
	ArcElement,
	Filler,
	RadialLinearScale
  } from 'chart.js';

  
// image impoer

import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Media,
	Row,
	Table,
} from "reactstrap";


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarController,
	BarElement,
	ArcElement,
	  Filler,
	RadialLinearScale
  );

const OrderReport = () => {
	const [load1,setLoad]=useState(true)
	const [start,setStart]= useState(moment().startOf("day"))
	const [end,setEnd]= useState(moment().startOf("day"))
	const lineData = {
		labels: ["100", "200", "300", "400", "500", "600", "700", "800"],
		datasets: [
			{
				data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
				borderColor: "#ff8084",
				backgroundColor: "#ff8084",
				borderWidth: 2,
				barPercentage: 0.7,
      			categoryPercentage: 0.4,
			},
			{
				data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
				borderColor: "#a5a5a5",
				backgroundColor: "#a5a5a5",
				borderWidth: 2,
				barPercentage: 0.7,
      			categoryPercentage: 0.4,
			},
		],
	};

	const buyData = {
		labels: ["", "10", "20", "30", "40", "50"],
		datasets: [
			{
				backgroundColor: "transparent",
				borderColor: "#13c9ca",
				data: [20, 5, 80, 10, 100, 15],
				lineTension: 0.4,
			},
			{
				backgroundColor: "transparent",
				borderColor: "#a5a5a5",
				data: [0, 50, 20, 70, 30, 27],
				lineTension: 0.4,
			},
			{
				backgroundColor: "transparent",
				borderColor: "#ff8084",
				data: [0, 30, 40, 10, 86, 40],
				lineTension: 0.4,
			},
			{
				backgroundColor: "transparent",
				borderColor: "#ffa5a5",
				data: [0, 20, 20, 200, 20, 20],
				lineTension: 0.4,
			},
		],
	};

	const doughnutOptions = {
		title: "",
		pieHole: 0.35,
		pieSliceBorderColor: "none",
		colors: ["#ff8084", "#13c9ca", "#a5a5a5","#ffa5a5"],
		legend: {
			position: "none",
		},
		pieSliceText: "none",
		tooltip: {
			trigger: "none",
		},
		animation: {
			startup: true,
			easing: "linear",
			duration: 1500,
		},
		chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
		enableInteractivity: false,
	};
	const pieOptions = {
		title: "",
		pieHole: 1,
		slices: [
			{
				color: "#ff8084",
			},
			{
				color: "#13c9ca",
			},
			{
				color: "#f0b54d",
			},
		],
		tooltip: {
			showColorCode: false,
		},
		chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
		legend: "none",
	};
	const pieOptions1 = {
		title: "",
		pieHole: 1,
		slices: [
			{
				color: "#ff8084",
			},
			{
				color: "#13c9ca",
			},
			{
				color: "#f0b54d",
			},
		],
		tooltip: {
			showColorCode: false,
		},
		chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
		legend: "none",
	};
	const LineOptions = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#ff8084"],
		legend: "none",
	};
	const LineOptions1 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#13c9ca"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const LineOptions2 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#f5ce8a"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const LineOptions3 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#a5a5a5"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const startdateEnddate=(s,e)=>{

	}
	useState(()=>(
		setLoad(false)
	),[])
	return (
		<Fragment>
			<Breadcrumb title="Dashboard" parent="Dashboard" />
			<Container fluid={true}>
			<div className="row">
                  <div className="container-fluid">
                  <div className="col-sm-12 col-md-12">
                 
          <Flatpickr
                        data-enable-time
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            defaultDate: ["2016-10-20", "2016-11-04"]
                          }}
                        //   onChange={(e)=>ChageDateValue(e)}
                        />
	
      </div>
      </div>
      </div>
				<Row>
					<Col xl="3 xl-50" md="6">
						<Card className=" o-hidden widget-cards">
							<CardBody className="bg-warning">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<Navigation className="font-warning" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">Earnings</span>
										<h3 className="mb-0">
											$ <CountUp className="counter" end={6659} />
											<small> This Month</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className=" o-hidden  widget-cards">
							<CardBody className="bg-secondary ">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<Box className="font-secondary" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">Products</span>
										<h3 className="mb-0">
											$ <CountUp className="counter" end={9856} />
											<small> This Month</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className="o-hidden widget-cards">
							<CardBody className="bg-primary">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<MessageSquare className="font-primary" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">Messages</span>
										<h3 className="mb-0">
											$ <CountUp className="counter" end={8933} />
											<small> This Month</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					<Col xl="3 xl-50" md="6">
						<Card className=" o-hidden widget-cards">
							<CardBody className="bg-danger ">
								<Media className="static-top-widget row">
									<div className="icons-widgets col-4">
										<div className="align-self-center text-center">
											<Users className="font-danger" />
										</div>
									</div>
									<Media body className="col-8">
										<span className="m-0">New Vendors</span>
										<h3 className="mb-0">
											$ <CountUp className="counter" end={45631} />
											<small> This Month</small>
										</h3>
									</Media>
								</Media>
							</CardBody>
						</Card>
					</Col>
					
					
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Buy / Sell</h5>
							</CardHeader>
							<CardBody className="sell-graph">
								<Line
									data={buyData}
									options={buyOption}
									width={700}
									height={350}
								/>
							</CardBody>
						</Card>
					</Col>
					
				
					
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Sales Status</h5>
							</CardHeader>
							<CardBody>
								<Row>
									<Col xl="3 xl-50" sm="6">
										<div className="order-graph">
											<h6>Orders By States</h6>
											<div className="chart-block chart-vertical-center">
											<Chart
													chartType="PieChart"
													data={[
														["Task", "Hours per Day"],
														["Saint Lucia", 300],
														["Kenya", 50],
														["Liberia", 100],
													]}
													options={pieOptions}
													graph_id="PieChart"
													width={"100%"}
													height={"180px"}
													legend_toggle
												/>
											</div>
											<div className="order-graph-bottom">
												<Media>
													<div className="order-color-primary"></div>
													<Media body>
														<h6 className="mb-0">
															Saint Lucia{" "}
															<span className="pull-right">$157</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-secondary"></div>
													<Media body>
														<h6 className="mb-0">
															Kenya <span className="pull-right">$347</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-danger"></div>
													<Media body>
														<h6 className="mb-0">
															Liberia<span className="pull-right">$468</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-warning"></div>
													<Media body>
														<h6 className="mb-0">
															Christmas Island
															<span className="pull-right">$742</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-success"></div>
													<Media body>
														<h6 className="mb-0">
															Saint Helena{" "}
															<span className="pull-right">$647</span>
														</h6>
													</Media>
												</Media>
											</div>
										</div>
									</Col>
									<Col xl="3 xl-50" sm="6">
										<div className="order-graph">
											<h6>Orders By States</h6>
											<div className="chart-block chart-vertical-center">
											{
											load1?
											<h6>Loading.......</h6>
											:

											<Chart
													chartType="PieChart"
													data={[
														["Task", "Hours per Day"],
														["Saint Lucia", 300],
														["Kenya", 50],
														["Liberia", 100],
													]}
													options={pieOptions1}
													
													width={"100%"}
													height={"180px"}
													legend_toggle
												/>
												}
											</div>
											<div className="order-graph-bottom">
												<Media>
													<div className="order-color-primary"></div>
													<Media body>
														<h6 className="mb-0">
															Saint Lucia{" "}
															<span className="pull-right">$15</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-secondary"></div>
													<Media body>
														<h6 className="mb-0">
															Kenya <span className="pull-right">$347</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-danger"></div>
													<Media body>
														<h6 className="mb-0">
															Liberia<span className="pull-right">$468</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-warning"></div>
													<Media body>
														<h6 className="mb-0">
															Christmas Island
															<span className="pull-right">$742</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-success"></div>
													<Media body>
														<h6 className="mb-0">
															Saint Helena{" "}
															<span className="pull-right">$647</span>
														</h6>
													</Media>
												</Media>
											</div>
										</div>
									</Col>
								</Row>
								
							</CardBody>
						</Card>
					</Col>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Sales Status</h5>
							</CardHeader>
							<CardBody>
								<Row>
									<Col xl="3 xl-50" sm="6">
										<div className="order-graph">
											<h6>Orders By State</h6>
											<div className="chart-block chart-vertical-center">
											<Chart
													chartType="PieChart"
													data={[
														["Task", "Hours per Day"],
														["Saint Lucia", 300],
														["Kenya", 50],
														["Liberia", 100],
													]}
													options={pieOptions}
												
													width={"100%"}
													height={"180px"}
													legend_toggle
												/>
											</div>
											<div className="order-graph-bottom">
												<Media>
													<div className="order-color-primary"></div>
													<Media body>
														<h6 className="mb-0">
															Saint Lucia{" "}
															<span className="pull-right">$157</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-secondary"></div>
													<Media body>
														<h6 className="mb-0">
															Kenya <span className="pull-right">$347</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-danger"></div>
													<Media body>
														<h6 className="mb-0">
															Liberia<span className="pull-right">$468</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-warning"></div>
													<Media body>
														<h6 className="mb-0">
															Christmas Island
															<span className="pull-right">$742</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-color-success"></div>
													<Media body>
														<h6 className="mb-0">
															Saint Helena{" "}
															<span className="pull-right">$647</span>
														</h6>
													</Media>
												</Media>
											</div>
										</div>
									</Col>
									<Col xl="3 xl-50" sm="6">
										<div className="order-graph sm-order-space">
											<h6>Sales By Stat</h6>
											<div className="peity-chart-dashboard text-center">
												<Chart
													chartType="PieChart"
													data={[
														["Task", "Hours per Day"],
														["Saint Lucia", 300],
														["Kenya", 50],
														["Liberia", 100],
													]}
													options={pieOptions}
													
													width={"100%"}
													height={"180px"}
													legend_toggle
												/>
											</div>
											<div className="order-graph-bottom sales-location">
												<Media>
													<div className="order-shape-primary"></div>
													<Media body>
														<h6 className="mb-0 me-0">
															Germany <span className="pull-right">25%</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-shape-secondary"></div>
													<Media body>
														<h6 className="mb-0 me-0">
															Brasil <span className="pull-right">10%</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-shape-danger"></div>
													<Media body>
														<h6 className="mb-0 me-0">
															United Kingdom
															<span className="pull-right">34%</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-shape-warning"></div>
													<Media body>
														<h6 className="mb-0 me-0">
															Australia<span className="pull-right">5%</span>
														</h6>
													</Media>
												</Media>
												<Media>
													<div className="order-shape-success"></div>
													<Media body>
														<h6 className="mb-0 me-0">
															Canada <span className="pull-right">25%</span>
														</h6>
													</Media>
												</Media>
											</div>
										</div>
									</Col>
								</Row>
								
							</CardBody>
						</Card>
					</Col>
						
					
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Buy / Sell</h5>
							</CardHeader>
							<CardBody className="sell-graph">
							<div className="order-graph xl-space">
											
											<div className="ct-4 flot-chart-container">
												<Line  type="area" data={employeeData} options={employeeOptions} />
											</div>
										</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

// javascript:void(0)

export default OrderReport;
