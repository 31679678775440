import React, { useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap';
import MultiSelect from '@khanacademy/react-multi-select'
import ImageUploader from '../../common/imageuploader';
import urlPath from '../../../url';

const VarientTable = ({removeVarientProduct,imageLoading,varientProduct,varientList,onchangevarientProduct,ImageUploaderData}) => {
    console.log(varientList)
    console.log(varientProduct)
    const [varientMap, setVarientMap] = useState([1, 2, 3, 1, 2, 3, 1, 2, 3,])

    const [visibleDivs, setVisibleDivs] = useState(new Array(varientMap.length).fill(false));

    const showDiv = (index) => {
    for(let i =0; i < document.getElementsByClassName(`openBlock`).length;i++ ){
        document.getElementsByClassName(`openBlock`)[i].style.display='none'
    } 
    document.getElementById(`show${index}`).style.display='block'
        const updatedDivs = [...visibleDivs];
        updatedDivs[index] = true;
        setVisibleDivs(updatedDivs);
    };

    const hideDiv = (index) => {
        const updatedDivs = [...visibleDivs];
        updatedDivs[index] = false;
        setVisibleDivs(updatedDivs);
    };
    const handleImageSelect=(e,i,type)=>{
        console.log(type)
    }
    return (
        <>
            <hr />
            {varientProduct.map((v, i) => (
                <>
                    <div className="row digital-add needs-validation" style={{ justifyContent: 'space-between' }}>
                        {
                            varientList.map((k,j)=>(
                                <FormGroup className='col-md-3'>
								<Label className="col-form-label">
									<span>*</span> {k.label}
								</Label>
								<select className="form-select" required="" name="brand"  >
									<option value="">--Select--</option>
									{
										 k.varientdata.map((d)=>(
											<option value={`${d.value}`} selected={v.varientlist.some(l=> l== d.value)}>{d.label}</option>
										))
									}
								

								</select>
							</FormGroup>
                            ))
                        }
                      
                        <div className='col-md-2' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <span onClick={(e) => removeVarientProduct(e,i,v.id)} style={{ cursor: 'pointer' }}>Remove</span>
                            <span onClick={() => showDiv(i)} style={{ cursor: 'pointer' }} >Edit</span>
                        </div>
                    </div>
                    <hr />
                    <div className='openBlock' id={`show${i}`} style={{ display: visibleDivs[i] ? 'block' : 'none' }}>
                        <div className='row' style={{ margin: '15px' }}>
                            <div className='col-md-6'>
                            <Input
                                    className="form-control"
                                    id="validationCustom01"
                                    type="file"
                                    
                                    name='file'
                                
                                    onChange={(e)=>ImageUploaderData(e,i,'varient')}
                                />
                                 <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
                {v.photo.map((imageUrl, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <img src={`${urlPath.productImage}/${imageUrl}`} alt={`Selected ${index}`} style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            // onClick={() => handleImageDelete(index)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
                        >
                            X
                        </button>
                    </div>
                ))}
             
            </div>
                            {/* <ImageUploader showAddMoreButton={v.photo.length != 0?true  :false} imageLoading={imageLoading}  image ={v.photo} handleImageSelect={(e)=>handleImageSelect(e,i,'varient')}/> */}
                            </div>
                            <FormGroup className='col-md-6'>
                                <Label className="col-form-label pt-0">
                                    <span>*</span> SKU
                                </Label>
                                <Input
                                    className="form-control"
                                    id="validationCustom01"
                                    type="text"
                                    required={true}
                                    name='sku'
                                    value={v.sku}
                                    onChange={(e)=>onchangevarientProduct(e,i)}
                                />
                                  <Label className="col-form-label pt-0">
                                    <span>*</span> Qty.
                                </Label>
                                <Input
                                    className="form-control"
                                    id="validationCustom01"
                                    type="text"
                                    required=""
                                    name="qty"
                                    value={v.qty}
                                    onChange={(e)=>onchangevarientProduct(e,i)}
                                />
                            </FormGroup>
                            <hr />
                            <FormGroup className='col-md-6'>
                                <Label className="col-form-label pt-0">
                                    <span>*</span> Regular Price
                                </Label>
                                <Input
                                    className="form-control"
                                    id="validationCustom01"
                                    type="text"
                                    name="mrp"
                                    value={v.mrp}
                                    onChange={(e)=>onchangevarientProduct(e,i)}
                                />
                            </FormGroup>
                            <FormGroup className='col-md-6'>
                                <Label className="col-form-label pt-0">
                                    <span>*</span> Sales Price
                                </Label>
                                <Input
                                    className="form-control"
                                    id="validationCustom01"
                                    type="text"
                                    required=""
                                    name='speprice'
                                    value={v.speprice}
                                    onChange={(e)=>onchangevarientProduct(e,i)}
                                />
                            </FormGroup>
                            <FormGroup className='col-md-12'>
                                <Label className="col-form-label pt-0">
                                    Stock Status
                                </Label>
                                <select className="form-select" name='stockstatus' value={v.stockstatus} onChange={(e)=>onchangevarientProduct(e,i)}>
                                    <option value="">--Select--</option>
                                    <option value="In Stock">In Stock</option>
                                    <option value="Out of Stock">Out of Stock</option>

                                </select>
                            </FormGroup>
                        </div>
                        {/* <button onClick={() => hideDiv(i)}>Hide Div</button>
                        <hr /> */}
                    </div>
                </>
            ))}
        </>
    )
}

export default VarientTable