import React, { Fragment, useState } from "react";

import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import TextEditor from '../../ui/texteditor';

import ImageUploader from "../../common/imageuploader";
const FormDatas = ({isLoading,formSubmite,singleData,operationType}) => {
	console.log(singleData)

	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})

    const [imageLoading,setImageLoading] = useState(false)
	
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		console.log(name,value)
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	
	
	return (
		<Container fluid={true}>
				{
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Country
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												value={singleFormData.country}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> State
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="state"
												value={singleFormData.state}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Districts
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="districts"
												value={singleFormData.districts}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> City
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="city"
												value={singleFormData.city}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Pincode
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="pincode"
												value={singleFormData.pincode}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Delivery Day
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="number"
												required=""
												name="Delivery_Date"
												value={singleFormData.Delivery_Date}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
											<FormGroup>
											<Label className="col-form-label">
												<span>*</span> COD
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="cod"
														value={true}
														checked={`${singleFormData.cod}`== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="cod"
														value={false}
														checked={`${singleFormData.cod}`== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Prepaid
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="prepaid"
														value={true}
														checked={`${singleFormData.prepaid}`== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
														
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="prepaid"
														value={false}
														checked={`${singleFormData.prepaid}`== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true'?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false'?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				}
				
			</Container>
		
	);
};

export default FormDatas;
