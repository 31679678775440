import React, { Fragment, useState } from "react";

import {Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import MultiSelect from '@khanacademy/react-multi-select';
import urlPath from "../../url";
import { getCommon } from "../../data/common";
const FormDatas = ({formSubmite,singleData,attribute,operationType,cookiesValue}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [attributeData,setattributeData]= useState(attribute)
	const [dropDownLoading,setDropDownLoading]= useState(false)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'typeofdropdown'){
			delete singleFormData[singleFormData.linktype]
			delete singleFormData[`${singleFormData.linktype}s`]
			delete singleFormData['name']
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const megaMenu=async (e)=>{
		const {name,value}= e.target
	
	 let url='';
	 setSingleFormData({...singleFormData,linktype:value})
	 const linktype=value
		if(value != 'blank'){
			setDropDownLoading(true)
			if(value== 'brand'){
			   url=`${urlPath.brand}`
			}else  if(value== 'category'){
			   url=`${urlPath.category}`
			}else if(value== 'page'){
			   url=`${urlPath.pagelist}`
			}else if(value== 'typeofproduct'){
			   url=`${urlPath.producttype}`
			}
			console.log(url)
			getCommon(url,"Table",cookiesValue).then(result => {
				console.log(result.data)
					const data=[]
				   setSingleFormData({...singleFormData,linktype:value})
				   for(let i =0; i < result.data.length;i++){
					 const d={
						value: result.data[i]._id,
						label: result.data[i].name
					 }
					 data.push(d)
				   }
					   setattributeData(data)
					   setDropDownLoading(false)
		   }).catch(err => {
			   console.log(err)
		   })
		}else{
			setSingleFormData({...singleFormData,linktype:value})
			setattributeData([])
			// setDropDownLoading(false)
		}
	
	}
	const pageAddData=(e)=>{
		const {name,value} = e.target
		
		if(singleFormData.linktype == 'brand'){
			singleFormData.brand=value
		}else if(singleFormData.linktype == 'category'){
			singleFormData.category=value
		}else if(singleFormData.linktype == 'page'){
			singleFormData.page=value
		}else if(singleFormData.linktype == 'typeofproduct'){
			singleFormData.typeofproduct=value
		}
	
		singleFormData.name =attributeData.find(d=> d.value == value ).label
		setSingleFormData({...singleFormData})
	}
	const selectDropDown=(e,type)=>{
		console.log(e)
		singleFormData[type] =e
		
		setSingleFormData({...singleFormData})
	}
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
										<div class="form-group">
											<label>
												Mega Menu Link<span>*</span>
												<span>:</span>
											</label>
											<select
												className="form-control"
												id="validationCustom0"
												type="text"
												required=""
												name="megamenu"
												onChange={(e)=>megaMenu(e)}
												value={singleFormData.linktype}
											>
												<option  className="form-control">Default</option>
											
												<option value="category" className="form-control">Category</option>
												<option  value="brand" className="form-control">Brands</option>
												<option  value="typeofproduct" className="form-control">Type Of Product</option>
												<option  value="page" className="form-control">Page</option>
												<option  value="blog" className="form-control">Blog</option>
												<option  value="blank" className="form-control">Blank</option>
											</select>
											</div>
											<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="typeofdropdown"
														value='multiple'
														checked={`${singleFormData.typeofdropdown}` == 'multiple' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Multiple
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="typeofdropdown"
														value='single'
														checked={`${singleFormData.typeofdropdown}`== 'single' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Single
												</Label>
											</div>
										</FormGroup>
											
											<div class="form-group">
											
												{
													attributeData.length !== 0 ?<label>Type<span>*</span><span>:</span></label>:''
												}
												
												
										
												{
													dropDownLoading?
													// <UiLoading />
													<h6 style={{textAlign:'center'}}>Data Feching...</h6>
													:
													singleFormData.typeofdropdown =='single' ?
													<>
													{
														attributeData.length !== 0 ?
														<>
														<select
													className="form-control"
													id="validationCustom0"
													type="text"
													required=""
													name={`${singleFormData.linktype}`}
													onChange={(e)=>pageAddData(e)}
													value={singleFormData[singleFormData.linktype]}
												>
													<option  className="form-control">Default</option>
													{
														attributeData.map((d,i)=>(
															<option value={`${d.value}`} className="form-control">{d.label}</option>
														))
													}
													
													
												</select>
											
											
														</>
														
														:''
													}
														
													</>
												
													:
											
											<MultiSelect
										options={attributeData}
										selected={singleFormData[`${singleFormData.linktype}s`] !== undefined && singleFormData[`${singleFormData.linktype}s`] !== null ?singleFormData[`${singleFormData.linktype}s`]:[]}
										onSelectedChanged={(e)=>selectDropDown(e,`${singleFormData.linktype}s`)}
									/>
												}
												
											</div>
											{
													singleFormData.linktype =='blank' || attributeData.length !== 0  ?
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Customisation Menu Name
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													required={true}
													name="name"
													value={singleFormData.name}
													onChange={(e)=>onChangeFormData(e)}
													
												/>
											</FormGroup>
													
													:''
												}
											
											<FormGroup>
											<Label className="col-form-label pt-0">
												 Menual Path
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												name="menualpath"
												value={singleFormData.menualpath}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value="Save"/>
												
											</div>
										</FormGroup>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
