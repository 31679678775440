import axios from 'axios';
import Cookies from 'js-cookie'
import  urlPath  from '../../url';

export const fileUpload = async (data) => {
  return await axios
    .post(`${urlPath.uploadfile}`, data,{
      headers: { Authorization: `Basic ${Cookies.get('jwt')}` },
    })
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const urlFileUpload = async (data)=>{
  return await axios.post(`${urlPath.uploadfile}/url`,data,{
    headers: { Authorization: `Basic ${Cookies.get('jwt')}` },
  }).then((result)=>{
     return result.data
  }).catch((err)=>{
      return err.response.data;
  })
}
export const deletedUploadFile = async (image, type) => {
  return await axios
    .delete(`${urlPath.uploadfile}/${image}/${type}`,{
      headers: { Authorization: `Basic ${Cookies.get('jwt')}` },
    })
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
