import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { getCategory } from '../../../data/catlog/category';
import { getBrand } from '../../../data/catlog/brand';
import { UiLoading } from "../../ui/uiloading";
import { getCommon } from "../../../data/common";
import urlPath from "../../../url";
import TextEditor from "../../ui/texteditor";
import { fileUpload } from "../../../data/catlog/fileupload";
const FormDatas = ({isLoading,formSubmite,singleData,attribute,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [attributeData,setattributeData]= useState([])
	const [dropDownLoading,setDropDownLoading]= useState(false)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}

	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>Designation</h5>
								</CardHeader>
								<CardBody>
								<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Designation
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required={true}
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
										
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" name="Submit" className="btn btn-primary" value="Save"/>
												
											</div>
										</FormGroup>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
