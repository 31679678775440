import React, { Fragment, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Breadcrumb from "../common/breadcrumb";
// import data from "../../../assets/data/pro_list";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button } from "reactstrap";
import FormDatas from './form'
import { getCategory, postCategory, getSingleCategory } from '../../data/catlog/category'
import { UiLoading } from "../ui/uiloading";
import { getCommon } from "../../data/common";
import urlPath from "../../url";
const Vendors = () => {
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const showForm = (Type,id) => {
	
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCategory(id).then(result => {
				setSingleData(result.data)
				setIsLoading(false)
			})

		} else {
			setIsLoading(false)
		}

	}
	useEffect(() => {
		getCommon(`${urlPath.vendor}`,'Table',cookiesValue).then(result => {
			
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					name: result.data[i].name,
					Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,

				}
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})

	}, [])
	
	const formSubmite = (e, singleFormData) => {
		e.preventDefault()
		setIsLoading(true)
		singleFormData.operation=operationType
		
		postCategory(singleFormData).then(async result => {
			if (result.success) {
		await getCategory().then(result => {
					
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
		
						}
						data.push(d)
						setData([...data])
					}
					setOperationType('Table')
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})

	}
	
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`Vendor`} parent="Dashboard" operationType={operationType} sublink={'vendor'}/>
				{/* <!-- Container-fluid starts--> */}
				{
					operationType == 'Table' ?
						<Container fluid={true}>
							<Row>
								<Col sm="12">

									{
										isLoading ?
											<UiLoading/>
											: <Card><CardHeader>
												{/* <h5> Employee {operationType}</h5> */}
											</CardHeader>
												<CardBody>
												
													<div className="clearfix"></div>
													<div id="basicScenario" className="product-physical">
														<Datatable
															myData={data}
															multiSelectOption={false}
															pageSize={10}
															pagination={true}
															class="-striped -highlight"
															deleteData={(type,id) => showForm(type,id)}
															viewData={(type,id) => showForm(type,id)}
															editData={(type,id) => showForm(type,id)}
															edit={true}
															deletes={true}
															view={true}
															copy={false}
														/>
													</div>
												</CardBody>
											</Card>
									}

								</Col>
							</Row>
						</Container>
						: operationType == 'Add' ?

							<FormDatas isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData,operationType)} />
							: operationType == 'Edit' ?
								isLoading ?
								<UiLoading/>
								:
									<FormDatas isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData)} />
								: operationType == 'View' ?
										isLoading ?
										<UiLoading/>
										:
									<FormDatas isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData)} />
									
									: 
									isLoading ?
									<UiLoading/>
									:
									<FormDatas isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData)} />
				}

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Vendors;

