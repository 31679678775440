import React from "react";
import { Col, Input, Row } from "reactstrap";

const addTwoRow=({multiRow,addTwoValueChange,removeTwoValueChange})=>(
    multiRow.map((d,i)=>(
        <Row>
        <Col sm={12} md={5}>
            <label>Title</label>
        <Input
		    className="form-control"
			id="validationCustom02"
			type="text"
			name="title"
            value={multiRow[i].title}
            onChange={(e)=>addTwoValueChange(e,i)}
		/>
        </Col>
        <Col sm={12} md={6}>
        <label>Value</label>
        <Input
		    className="form-control"
			id="validationCustom02"
			type="text"
			name="value"
            value={multiRow[i].value}
            onChange={(e)=>addTwoValueChange(e,i)}
		/>
        </Col>
        <Col sm={1} md={1}>
            <label>Operation</label>
        <span >
						<i
							className="fa fa-trash"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
								cursor:'pointer'
							}}
							onClick={(e) => removeTwoValueChange(e,i)}
							
						></i>
					</span>
        </Col>
    </Row>
    ))
   
)
export default addTwoRow;