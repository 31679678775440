import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import TabsetPage from "./tabset-page";

const PageDescriptionForm = ({saveData,descriptionForm,singleData,data}) => {
	console.log(singleData)
	console.log(singleData.description)
	return (
		<Fragment>
			{/* <Breadcrumb title="Create Page" parent="Pages" /> */}
			<Container fluid={true}>
				<Card>
					{/* <CardHeader>
						<h5>{singleData.name}</h5>
					</CardHeader> */}
					<CardBody>
						<TabsetPage saveData={saveData} descriptionForm={descriptionForm} singleData={singleData} title={singleData.name} description={singleData.description} data={data}/>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default PageDescriptionForm;
