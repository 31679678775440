import React, { Fragment, useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import Breadcrumb from "../common/breadcrumb";
import urlPath, { menuUrl } from "../../url";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, CardFooter } from "reactstrap";
import { getCommonStatus } from "../../data/common";

const Menus = () => {
	const [operationType, setOperationType] = useState('Table')
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const [dataList,setDataList] =useState([
		{
		title:'Header Menu',
		name:'Header Menu',
		description:'',
		url:`${urlPath.headermenu}`,
		total:0,
		active:0,
		inactive:0,
		linkTable:`${process.env.PUBLIC_URL}/header-menu/Table`,
		linkInactive:`${process.env.PUBLIC_URL}/header-menu/Inactive`,
		linkActive:`${process.env.PUBLIC_URL}/header-menu/Active`,
		linkAdd:`${process.env.PUBLIC_URL}/header-menu/Add`,
		isLoading:true,
		isEdit:false,
		},
		{
			title:'Footer Menu',
			name:'Footer Menu',
			url:`${urlPath.footermenu}`,
			total:0,
			active:0,
			inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/footer-menu/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/footer-menu/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/footer-menu/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/footer-menu/Add`,
			isLoading:true,
			isEdit:false,
		},
		{
			title:'Header Menu Structure',
			name:'Header Menu Structure',
			url:`${urlPath.product}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/manage-megamenu`,
			linkInactive:`${process.env.PUBLIC_URL}/product/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/product/Active`,
			linkAdd:`http://localhost:3001/manage-megamenu`,
			isLoading:true,
			isEdit:true,
		},
		{
			title:'Footer Menu Structure',
			name:'Footer Menu Structure',
			url:`${urlPath.varient}`,
			total:0,
	     	active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient/Add`,
			isLoading:true,
			isEdit:true,
		},
	

	])
	let navigate = useNavigate(); 
	const urlRedirect = (path,type) =>{
		if(type == 'Header Menu Structure'){
			window.location.href=`${menuUrl}/manage-megamenu`
		}else if(type == 'Header Menu Structure'){
			window.location.href=`${menuUrl}/manage-megamenu`
			
		}else{
			navigate(path);
		}

   }
   useEffect(()=>{
	for(let i=0; i <dataList.length; i++){
		getCommonStatus(dataList[i].url,'count',cookiesValue).then(result=>{
			if(!dataList[i].isEdit){
				dataList[i].total= result.total
				dataList[i].active= result.active
				dataList[i].inactive= result.inactive
				dataList[i].isLoading=false
				console.log(dataList[i])
				setDataList([...dataList])
			}
		
			
		})
	}
	
   },[])
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`Menus`} parent="Dashboard" operationType={operationType} sublink={'menus'}/>
				{/* <!-- Container-fluid starts--> */}
		
				<Container fluid={true}>
							<Row>
								{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card>
											<CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
														{
															d.isEdit ?
															<div className="product-buttons text-center">
														
													   
														   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd,d.name)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
														   
												   
													   </div>
															
															:<div className="product-buttons text-center">
															<Button type="button" color="primary" onClick={()=>urlRedirect(d.linkTable,d.name)}>
														   <span><img src="/table.png" style={{width:'25px'}} />({!d.isLoading?`${d.total}` :<p>isLoading...</p>})</span>
														   </Button>
														   <Button type="button" color="success" style={{backgroundColor:'green'}} onClick={()=>urlRedirect(d.linkActive,d.name)}>
														   <span><img src="/active.png" style={{width:'25px'}} />({!d.isLoading?(d.active) :<p>isLoading...</p>})</span>
														   </Button>
														   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkInactive,d.name)}>
														   <span><img src="/inactive.png" style={{width:'25px'}} />({!d.isLoading?d.inactive :<p>isLoading...</p>})</span>
														   </Button>
													   
														   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd,d.name)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
														   
												   
													   </div>
														}
					  							
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
								
   
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Menus;

