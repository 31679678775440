import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import {  useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label,Row,Col } from "reactstrap";
import { postCommon } from "../../data/common";
import urlPath from "../../url";

const RegisterTabset = () => {
	const [singleData,setSingleData]= useState({role:'Super User'})
	const [errorMessage,setErrorMessage] = useState({})
	const [submitLoading,setSubmitLoading]= useState(false)
	const history = useNavigate();

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	const routeChange = () => {
		history(`${process.env.PUBLIC_URL}/dashboard`);
	};
	const changeValue=(e)=>{
		setErrorMessage({})
		const {name,value} = e.target;
		if (name === 'email') {
			const user = value.split('@')[0];
			singleData.userid=user
		  }
		  singleData[name]=value
		setSingleData({...singleData})
	}
	const FormData=(e)=>{
		e.preventDefault()
		console.log('klk')
		console.log(urlPath.admin)
		if (singleData.password === singleData.confpassword) {
		
			var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
			singleData.typeofemployee='Employee'
			if (regix.test(singleData.password)) {
				setSubmitLoading(true)
			postCommon(`${urlPath.admin}/register`,singleData).then(result=>{
				if(result.success){
					window.location.href='/'
				}else{
					errorMessage.message='User Id Not Created, Please Updated'
					setErrorMessage({...errorMessage})
				}
				
			})
	}else{
		errorMessage.message='Password Not Satiffied Its Pattern'
		setErrorMessage({...errorMessage})
	}
	}else{
		errorMessage.message='Password Not Matching'
		setErrorMessage({...errorMessage})
	}
	}
	return (
		<div>
			 <Helmet>
				<title>Register</title>
			 </Helmet>
			<Fragment>
						<Form className="form-horizontal auth-form" onSubmit={(e)=>FormData(e)}>
						<h6 style={{color:'red',textAlign:'center'}}>{errorMessage.message}</h6>
						
							<Row>
								<Col sm={12} md={12} lg={12}>
								<FormGroup>
									<label>Company Name</label>
								<Input
									required={true}
									name="companyname"
									type="text"

									className="form-control"
									placeholder="Company Name"
									id="exampleInputEmail1"
									value={singleData.companyname}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
							</Col>
							<Col sm={12} md={6} lg={6}>
							<FormGroup>
									<label>Name</label>
								<Input
								required={true}
									name="name"
									type="text"
									className="form-control"
									placeholder="Name"
									id="exampleInputEmail1"
									value={singleData.name}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
								</Col>
								<Col sm={12} md={6} lg={6}>
							<FormGroup>
									<label>Mobile</label>
								<Input
									required={true}
									name="mobile"
									type="text"
									className="form-control"
									placeholder="Mobile"
									id="exampleInputEmail1"
									value={singleData.mobile}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
								</Col>
								<Col sm={12} md={6} lg={6}>
							<FormGroup>
									<label>Email</label>
								<Input
									required={true}
									name="email"
									type="text"
									className="form-control"
									placeholder="Email"
									id="exampleInputEmail1"
									value={singleData.email}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
								</Col>
								<Col sm={12} md={6} lg={6}>
							<FormGroup>
									<label>User Id</label>
								<Input
									required={true}
									name="userid"
									type="text"
									className="form-control"
									placeholder="user id"
									id="exampleInputEmail1"
									value={singleData.userid}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
								</Col>
								<h6 style={{color:'red'}}>*Password 8 Captal,Small,Special,Numeric and Letter</h6>
								<Col sm={12} md={6} lg={6}>
							<FormGroup>
									<label>Password</label>
								<Input
									required={true}
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									id="exampleInputEmail1"
									value={singleData.password}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
								</Col>
								<Col sm={12} md={6} lg={6}>
							<FormGroup>
									<label>Conf. Password</label>
								<Input
									required={true}
									name="confpassword"
									type="password"
									className="form-control"
									placeholder="Conf. Password"
									id="exampleInputEmail1"
									value={singleData.confpassword}
									onChange={(e)=>changeValue(e)}
								/>
							</FormGroup>
								</Col>
								
							
							</Row>
							<div className="form-button" style={{textAlign:'center'}}>
								{
									submitLoading?
									<div className="form-button">
								<Button
									color="primary"
									type="submit"
									onClick={() => routeChange()}
								>
									Loading ...
								</Button>
							</div>
									
									:<input className="btn btn-primary" type="submit" value="Register" />
								}
								
									
								
									
								
							</div>
						
						</Form>
				
			</Fragment>
		</div>
	);
};

export default RegisterTabset;
