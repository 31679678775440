import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab, } from "react-tabs";
import MultiSelect from '@khanacademy/react-multi-select';
import { Button, Form,Container,Card,CardBody,Input,FormGroup,Label,Col,Row } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import urlPath from "../../../url";
import { getCommon, getSingleCommon, postCommon } from "../../../data/common";
import Cookies from "js-cookie";
import { getDropDown } from "../../../data/catlog/product";
import { UiLoading } from "../../ui/uiloading";


const UserProfile = ({title,description}) => {
	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({sociallink:[],document:[],socilalist:[],documentList:[]})
	const [sociallink,setSocialLink]= useState([])
	const [document,setDocument]=useState([])
	const [isLoading,setIsLoading] = useState(true)
	console.log(description)
	const onChange = (e) =>{
		setValue(e)
	}
	useEffect(()=>{
		getSingleCommon('',urlPath.userprofile,Cookies.get('jwt')).then(async result => {
			console.log(result.data)
			setSingleFormData(result.data)
			 await getDropDown('Document').then(result=>{
             console.log(result)
			 setDocument(result.data)
			 })
			 await getDropDown('Social Link').then(result=>{
				console.log(result.data)
				setSocialLink(result.data)
				
			 })
			 setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})
	},[])

	const onChangeFormData=(e)=>{
		const single={...singleFormData}
       const {name,vale}= e.target;
	   single[name]=value.trim()
	   setSingleFormData({...single})
 	}
	const selectDropDown=(e,type)=>{
		const single ={...singleFormData}
		const data=[]
		if(type== 'document'){
			
			if(single.documentList != undefined &&  single.documentList != null){
				for(let i=0 ; i < e.length;i++){
					if(!single.documentList.some(d=> d.name == e[i])){
						const d={
							name:e[i],
							number:'',
							file:''
						   }
						   single.documentList.push(d)
					}

				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
					const d={
						name:e[i],
						number:'',
						file:''
					   }
					   data.push(d)
				}
				single.documentList=data
            }
		}else if(type== 'sociallink'){
			if(single.sociallink != undefined &&  single.sociallink != null){
				for(let i=0 ; i < e.length;i++){
					if(!single.socilalist.some(d=> d.name == e[i])){
						const d={
							name: sociallink.find(k=> k.value == e[i]).label,
							url:''
						   }
						   single.socilalist.push(d)
					}

				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
					const d={
						name: sociallink.find(k=> k.value == e[i]).label ,
						url:''
					   }
					   data.push(d)
				}
				single.socilalist=data
            }
			
		}

		single[type] =e
		console.log(single)
		
		setSingleFormData({...single})
	}

	const socialLinkChange=(e,type,i)=>{
		const {name,vale}= e.target;
		const single={...singleFormData}
		
		single[type][i][name]= value
		setSingleFormData({...single})
	}

	const formSubmite =async (e) => {
		console.log('hello')
		e.preventDefault()
		
		singleFormData['operation']='Edit'
		console.log(singleFormData)
	await postCommon(urlPath.userprofile,singleFormData,Cookies.get('jwt')).then(async result => {
			if (result.success) {
				alert('Updated Data Successfully')
			} else {

			}
		})

	}
	return (
		<Fragment>
	<Breadcrumb title={`User Profile`} parent="Dashboard" operationType={'Edit'} mainlink={`dashboard`} sublink={'setting'} type={true}/>
		<Container fluid={true}>
			<Card>
				{/* <CardHeader>
					<h5>{operationType} Employee</h5>
				</CardHeader> */}
				<CardBody>
				<div>
				{
								isLoading ?
								<UiLoading />
								:
				<>
				<Tabs>
				<Form className="needs-validation" onSubmit={(e)=>formSubmite(e)}>
					<TabList className="nav nav-tabs tab-coupon">
								<Tab className="nav-link" >Details</Tab>
								
								<Tab className="nav-link" >Document</Tab>
								
								<Tab className="nav-link" >Social Link</Tab>
								
								
					</TabList>
					
							<TabPanel>
							
							<Row>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span> Name
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={singleFormData.name}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>
										</Col>
									
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span> Email
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													required={true}
													name="email"
													value={singleFormData.email}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span>Mobile
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													required={true}
													name="mobile"
													value={singleFormData.mobile}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
									
								
										
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Address
												</Label>
												<textarea
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="address"
													value={singleFormData.address}
													onChange={(e)=>onChangeFormData(e)}
												></textarea>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 City
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="city"
													value={singleFormData.city}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Districts
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="districts"
													value={singleFormData.districts}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 State
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="state"
													value={singleFormData.state}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Country
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="country"
													value={singleFormData.country}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Pincode
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="number"
												
													name="pincode"
													value={singleFormData.pincode}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Social Link
												</Label>
												<MultiSelect
													options={ sociallink || []}
													selected={singleFormData.sociallink || []}
													onSelectedChanged={(e)=>selectDropDown(e,'sociallink')}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
											<Label className="col-form-label">
												Document 
											</Label>
											<div className="form-group" id="colors">
												<MultiSelect
													options={ document || []}
													selected={singleFormData.document || []}
													onSelectedChanged={(e)=>selectDropDown(e,'document')}
												/>
											</div>
										</FormGroup>	
										</Col>
									</Row>
								
							
							</TabPanel>
							<TabPanel>
								<Row>
									<Col sm={4}>
										Document
									</Col>
									<Col sm={4}>
										Number
									</Col>
									<Col sm={4}>
										file
									</Col>
								</Row>
                             {
								singleFormData.documentList !== undefined && singleFormData.documentList !== null?
								singleFormData.documentList.map((d,l)=>(
									<Row>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
							
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={d.name}
												disabled={true}
												
												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
								
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="number"
													value={d.number}
													onChange={(e)=>socialLinkChange(e,'documentList',l)}
												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
						
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
											     	required={true}
													name="file"
													onChange={(e)=>socialLinkChange(e,'documentList',l)}
												/>
											</FormGroup>
										</Col>
									</Row>
								))
								
								
								:''
							 }
							</TabPanel>
					        <TabPanel>
							<Row>
									<Col sm={4}>
										Social Link
									</Col>
									<Col sm={4}>
										URL
									</Col>
									
								</Row>
							{
								singleFormData.socilalist !== undefined && singleFormData.socilalist !== null?
								singleFormData.socilalist.map((d,l)=>(
									<Row>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
							
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={d.name}
													onChange={(e)=>socialLinkChange(e,'socilalist',l)}
												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
								
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="url"
													value={d.url}
												
													onChange={(e)=>socialLinkChange(e,'socilalist',l)}
												/>
											</FormGroup>
										</Col>
										
									</Row>
								))
								
								
								:''
							 }

							</TabPanel>
					<div className="pull-right">
					<input type="submit"  color="primary" value={'Edit'} className="btn btn-primary"/>
					
				</div>
				</Form>
				</Tabs>
			
				</>
}
			</div>
				</CardBody>
			</Card>
		</Container>
	</Fragment>
	
	);
};

export default UserProfile;
