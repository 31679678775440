import React, { Fragment, useEffect, useState } from "react";
import LoginTabset from "./loginTabset";
import { Helmet } from "react-helmet";
import { ArrowLeft } from "react-feather";
import Slider from "react-slick";
import stats from "../../assets/images/dashboard/stats.png";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { UiLoading } from "../ui/uiloading";
import { getCommon, getCommonStatus } from "../../data/common";
import urlPath from "../../url";
import RegisterTabset from "./RegisterTabset";

const Login = () => {
	const [countData,setCountData] = useState()
	const [sliderData, setSliderData] = useState([])
	const [isLoading,setIsLoading] =useState(true)
	const [company,setCompanyData]= useState({company:'',favicon:''})

	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		arrows: false,
	};
	useEffect(()=>{
		getCommonStatus(urlPath.admin,'count').then(result=>{
			console.log(result.company)
			if(result.count != 0){
				localStorage.setItem('company',JSON.stringify(result.company))
				setCompanyData(result.company)
			}
			setCountData(result.count)
			setIsLoading(false)
		})	
	},[])
	return (
		<Fragment>
			<Helmet>
				<title>Login | {company.company}</title>
				<link rel="shortcut icon" href={`${urlPath.imageurl}/${company.documenttype}/${company.favicon}`} /> 
			 </Helmet>
			<div className="page-wrapper">
				<div className="authentication-box">
				
					<Container>
						<Row>
							{
							isLoading ?
							<UiLoading/>
							:
							<>
						
									{/* <div className="svg-icon">
										<img alt="" src={stats} className="Img-fluid" />
									</div> */}
									{
										countData != 0 ?
										<Col className="col-md-5 p-0 card-left">
										<Card className="bg-primary">
										<Slider className="single-item" {...settings}>
										<div>
											<div>
												<h3>Welcome to <br/>{company.company}</h3>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy.
												</p>
											</div>
										</div>
										<div>
											<div>
											<h3>Welcome to <br/>{company.company}</h3>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy.
												</p>
											</div>
										</div>
										<div>
											<div>
											<h3>Welcome to <br/>{company.company}</h3>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy.
												</p>
											</div>
										</div>
									</Slider>
									</Card>
							</Col>
										:''
									}
									
							
						
										{
											countData == 0 ?
											<Col className="col-md-12 col-sm-12 col-lg-12 p-0 card-right">
												<Card className="tab2-card">
											 <h4 className="p-2" style={{textAlign:'center'}}>Company Registration </h4>
											 <h6 class="font-weight-light" style={{textAlign:'center'}}>Please Register and access your account</h6>
													<CardBody>
														<RegisterTabset />
													</CardBody>
												</Card>
											</Col>
											:
											<Col className="col-md-7 p-0 card-right">
											<Card className="tab2-card">
												<CardBody>
											<LoginTabset />
											</CardBody>
								</Card>
							</Col>
										}
											
										
										
										
									
							</>
							}
							
							
						</Row>
						
					</Container>
				</div>
			</div>
		</Fragment>
	);
};

export default Login;
