import React, { Fragment, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { Link ,useNavigate} from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import urlPath from "../../url";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, CardFooter } from "reactstrap";
import { getCommonStatus } from "../../data/common";

const Sales = () => {
	let navigate = useNavigate(); 
	const [operationType, setOperationType] = useState('Table')
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const [dataList,setDataList] =useState([
		{
		title:'Orders',
		name:'Orders',
		description:'',
		url:`${urlPath.companyprofile}`,
		total:0,
		active:0,
		inactive:0,
		linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
		linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
		linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
		linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
		isLoading:true,
		addButton:false,
		},
		{
			title:'Invoice',
			name:'Invoice',
			url:`${urlPath.userprofile}`,
			total:0,
			active:0,
			inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
			isLoading:true,
			addButton:false,
		},
		
		{
			title:'Transection',
			name:'Transection',
			url:`${urlPath.paymentgateway}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
			isLoading:true,
			addButton:false,
		},

	])
		
	useEffect(()=>{
		
		for(let i=0; i <dataList.length; i++){
			getCommonStatus(dataList[i].url,'status',cookiesValue).then(result=>{
			
					dataList[i].total= result.total
					dataList[i].active= result.active
					dataList[i].inactive= result.inactive
					dataList[i].isLoading=false
			
					setDataList([...dataList])
				
				
			})
		}
		
	   },[])
	   
	   const urlRedirect = (path) =>{ 
		
				   navigate(path);	
						   
	  }
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
			<Breadcrumb title={`Sales`} parent="Dashboard" operationType={operationType} sublink={'sales'}/>
				{/* <!-- Container-fluid starts--> */}
				<Container fluid={true}>
							<Row>
							{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
													{
														!d.addButton ?
														<div className="product-buttons text-center">
												<Button type="button" color="primary" onClick={()=>urlRedirect(d.linkTable)}>
											   <span><img src="https://www.flaticon.com/free-icons/activities" />({!d.isLoading?`${d.total}` :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="success" style={{backgroundColor:'green'}} onClick={()=>urlRedirect(d.linkActive)}>
											   <span><img src="https://www.flaticon.com/free-icons/activities" />({!d.isLoading?(d.active) :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkInactive)}>
											   <span><img src="https://www.flaticon.com/free-icons/activities" />({!d.isLoading?d.inactive :<p>isLoading...</p>})</span>
											   </Button>
										   
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
											   
									   
											   </div>
														
														:	<div className="product-buttons text-center">
														
												   
													   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
													   
											   
													   </div>
													}
					  							
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Sales;

