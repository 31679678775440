import React, { Fragment, useState } from "react";

import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../data/catlog/fileupload';
import TextEditor from '../ui/texteditor'
import singleImage from "../common/singleimage";
import ImageUploader from "../common/imageuploader";
import urlPath from "../../url";
const FormDatas = ({isLoading,formSubmite,singleData,operationType}) => {
	console.log(singleData)

	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [showAddMoreButton, setShowAddMoreButton] = useState(singleData.url !== '' ? true:false);
    const [imageLoading,setImageLoading] = useState(false)
	
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const onEditorChange = (evt) => {
		console.log(singleFormData)
		singleFormData['description'] =evt.editor.getData()
		setSingleFormData({...singleFormData})
		
	  };
	  const handleImageSelect = async(e) => {
        e.preventDefault();
        setShowAddMoreButton(true);
        setImageLoading(true)
		let photoList =[]
		if(singleFormData.photo !== undefined && singleFormData.photo !== null){
			 photoList =[...singleFormData.photo]
		}else{

		}
       
        for(let i =0; i < e.target.files.length; i++ ){
            console.log(i)
            console.log( e.target.files[i])
            const data = new FormData();
            data.append('type', 'products');
            data.append('image', e.target.files[i]);
         await fileUpload(data).then((result) => {
              if (result.success) {
              
                photoList.push(result.photo)
              }
            });
        }
		singleFormData.photo=[...photoList]
        setSingleFormData(singleFormData)
        setImageLoading(false)

    };
	console.log(singleFormData)
	return (
		<Container fluid={true}>
			
				{
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Title
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												 Url
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="name"
												value={`${urlPath.imageurl}/${singleFormData.uploadtype}/${singleFormData.url}`}
											
												disabled
											/>
										</FormGroup>
										<h5>Gallery Image</h5>
										
										{[singleData.url].map((imageUrl, index) => (
                  								  <div key={index} style={{position: 'relative' }}>
                     		  			 <img src={`${urlPath.imageurl}/${singleFormData.uploadtype}/${imageUrl}`} alt={`Selected ${index}`} style={{ width: '100%', height: '100px' }} />
                       								</div>
               							 ))}
									</div>
								</CardBody>
							</Card>
							
						</Col>
						
					</Row>
					</Form>
				}
				
			</Container>
		
	);
};

export default FormDatas;
