import React, { Fragment, useState } from "react";
import MultiSelect from '@khanacademy/react-multi-select';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
const FormDatas = ({pageList,isLoading,formSubmite,singleData,attribute,operationType,description}) => {
	console.log(pageList)
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [descriptionList,setdescriptionList]= useState(description)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const selectDropDown=(e,type)=>{
		singleFormData.pagedescription=true
		singleFormData[type] =e
		singleData.pagedescription=true
		setSingleFormData({...singleFormData})
		
	}
	
	return (
		
			<Container fluid={true}>
				
				<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="6">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Title
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="pagetype"
														value={'Yes'}
														checked={`${singleFormData.pagetype}` == 'Yes' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Main Page
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="pagetype"
														value={'No'}
														checked={`${singleFormData.pagetype}` == 'No' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Sub Page
												</Label>
											</div>
										</FormGroup>
										{
											singleFormData.pagetype == 'Yes' ?
											<>
											<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Page Description
										</Label>
										<div className="form-group" id="colors">
											<MultiSelect
												options={descriptionList}
												selected={singleFormData.description || []}
												onSelectedChanged={(e)=>selectDropDown(e,'description')}
											/>
										</div>
									</FormGroup>
										<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Sub Page Structure
										</Label>
										<div className="form-group" id="colors">
											<MultiSelect
												options={descriptionList}
												selected={singleFormData.subpage || []}
												onSelectedChanged={(e)=>selectDropDown(e,'subpage')}
											/>
										</div>
									</FormGroup>
									</>	
											:singleFormData.pagetype == 'No' ?
											<FormGroup >
											<Label className="col-form-label">
												 Page List
											</Label>
											<select className="form-select" name='pagelist' value={singleFormData.pagelist} onChange={(e)=>onChangeFormData(e)}>
												<option value="">--Select--</option>
												{
													pageList != undefined && pageList != null && pageList.map((d,l)=>(
														<option value={`${d._id}`}>{d.name}</option>
													))
												}
												
											
											</select>
										</FormGroup>
										:''
										}
											
									
									<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Update Page Structure
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="pagestructure"
														value={'Parent'}
														
														onChange={(e)=>onChangeFormData(e)}
													/>
												 Parent
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="pagestructure"
														value={'Child'}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Child
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="pagestructure"
														value={'Both'}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Both
												</Label>
											</div>
										</FormGroup>
										
									</div>
								</CardBody>
							</Card>
							{
								description != undefined && description != null && description.length != 0 ?
								<Card>
								<CardHeader>
									<h5>Description List</h5>
								</CardHeader>
								<CardBody>
								{
									description != undefined && description != null && description.map((d,i)=>(
										singleFormData.description !== undefined && singleFormData.description !== null && singleFormData.description.some(l=> l == d.value)?<h6>{d.label}</h6>:''
										
									))
								}
									
								</CardBody>
							</Card>
								
								:''
							}
							{
								description != undefined && description != null && description.length != 0 ?
								<Card>
								<CardHeader>
									<h5>Child Page List</h5>
								</CardHeader>
								<CardBody>
								{
									description != undefined && description != null && description.map((d,i)=>(
										singleFormData.subpage !== undefined && singleFormData.subpage !== null && singleFormData.subpage.some(l=> l == d.value)?<h6>{d.label}</h6>:''
										
									))
								}
									
								</CardBody>
							</Card>
								
								:''
							}
						</Col>
						<Col xl="6">
							
							<Card>
								<CardHeader>
									<h5>Meta Data</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
									
									
										<FormGroup>
											<Label className="col-form-label pt-0"> Meta Title</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='metatitle'
												type="text"
												
												value={singleFormData.metatitle}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Meta Key Word</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='metakeyword'
												value={singleFormData.metakeyword}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Meta Description</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='metadescription'
												value={singleFormData.metadescription}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Slug</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="slug"
												value={singleFormData.slug}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Header Seo</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="textarea"
												required=""
												name="headerseo"
												value={singleFormData.headerseo}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Footer Seo</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="textarea"
												required=""
												name="footerseo"
												value={singleFormData.footerseo}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Body Seo</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="textarea"
												required=""
												name="bodyseo"
												value={singleFormData.bodyseo}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">Og</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='ogtag'
												type="textarea"
												
												value={singleFormData.ogtag}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
					</Form>
				
				
			</Container>
			
			
		
	);
};

export default FormDatas;
