import React, { Fragment, useState } from "react";
import MultiSelect from '@khanacademy/react-multi-select';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form	 } from "reactstrap";
import ImageUploader from "../../common/imageuploader";
import VarientTable from "./varientTable";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import TextEditor from "../../ui/texteditor";
const FormDatas = ({textType,saveData,isLoading,formSubmite,singleData,operationType,category,description,varient,brand,productlist}) => {

	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [showAddMoreButton, setShowAddMoreButton] = useState(singleData.photo.length !== 0 ? true:false);
    const [imageLoading,setImageLoading] = useState(false)
	const [varientList,setVarientList]  = useState([])
	const [varientProduct,setVarientProduct] = useState([])
	const [stockQuantity,setStockQuantity]= useState(singleData.stockquantity)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
	
		
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		if(name == 'order'){
			if(!/^[0-9]+$/.test(value)){
				singleFormData[name] =value
			  }
		}
		
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}

	  const handleImageSelect = async(e,i,type) => {
		console.log('hog')
		console.log(i)
        e.preventDefault();
		
        setShowAddMoreButton(true);
        setImageLoading(true)
		let photoList =[]
		if(singleFormData.photo !== undefined && singleFormData.photo !== null){
			 photoList =[...singleFormData.photo]
		}
       
        for(let i =0; i < e.target.files.length; i++ ){
        
            const data = new FormData();
            data.append('type', 'products');
            data.append('image', e.target.files[i]);
         await fileUpload(data).then((result) => {
              if (result.success) {
              		photoList.push({uploadtype:'products',image:result.photo})}
            });
        }
		singleFormData.photo=[...photoList]
		singleFormData.uploadtype='products'
        setSingleFormData(singleFormData)
        setImageLoading(false)

    };
	const selectDropDown=(e,type)=>{
	
		singleFormData[type] =e
		// setSingleFormData({...singleFormData})
		const d =[]
		const l=[]
		if(type == 'varient'){
			for (var i = 0; i < e.length; i++) { 
				for(let j=0; j < varient.length;j++ ){
					if(varient[j].value == e[i]){
						if(varient[j].varientdata.length !== 0){
							singleFormData[type] =[]
							l.push(e[i])
							d.push(varient[j])
						}else{
							alert('No Varient Data')
						}
						
					}
				}
				
			  }
			  singleFormData[type]=l
			  singleFormData['varientlist'] =d
			  setVarientList(d)
		}else{
			singleFormData[type] =e
		
		}
		setSingleFormData({...singleFormData})
	}
	const selectVarientDropDown=(e,i)=>{
		const varientData =[...varientList]
		varientData[i].selectvarient=e
		setVarientList(varientData)
	}
	const generateProduct=()=>{
		const dateList = [...varientList]
		console.log(varientList)
		const d=[]
		const a =0;
		if(dateList.length == 1){
			for(let i =0; i < dateList[0].selectvarient.length;i++ ){
				console.log(dateList[0].varientdata.find(d=> d.value == dateList[0].selectvarient[i]).label)
				const dl={
					id: parseInt(i + 1),
					varientlist:[dateList[0].selectvarient[i]],
					sku:`${singleFormData.SKU}-${dateList[0].varientdata.find(d=> d.value == dateList[0].selectvarient[i]).label}`,
					qty:0,
					photo:[],
					mrp:0,
					speprice:0,
					stockstatus:'In Stock',
					imageLoading:false,
				}
				d.push(dl)
			}
			singleFormData.varientproduct=d
				setSingleFormData({...singleFormData})
		}else if(dateList.length == 2){
			if(dateList[0].selectvarient.length != 0){
				for(let i =0; i < dateList[0].selectvarient.length;i++ ){
					
					if(dateList[1].selectvarient.length != 0){
						
						for(let j =0; j < dateList[1].selectvarient.length;j++ ){
							const dl={
								id: parseInt(j + 1),
								varientlist:[dateList[0].selectvarient[i],dateList[1].selectvarient[j]],
								sku:`${singleFormData.SKU}-${dateList[0].varientdata.find(d=> d.value == dateList[0].selectvarient[i]).label}-${dateList[1].varientdata.find(d=> d.value == dateList[1].selectvarient[j]).label}`,
								qty:0,
								photo:[],
								mrp:0,
								speprice:0,
								stockstatus:'In Stock',
								imageLoading:false,
							}
							d.push(dl)
						}
						
					}
                
				}
				singleFormData.varientproduct=d
				setSingleFormData({...singleFormData})
				// setVarientProduct(d)
			}
			}else if(dateList.length == 3){
				if(dateList[0].selectvarient.length != 0){
					for(let i =0; i < dateList[0].selectvarient.length;i++ ){
						for(let j =0; j < dateList[1].selectvarient.length;j++ ){
						if(dateList[2].selectvarient.length != 0){
							
							for(let k =0; k < dateList[2].selectvarient.length;k++ ){
								const dl={
									id: parseInt(k + 1),
									varientlist:[dateList[0].selectvarient[i],dateList[1].selectvarient[j],dateList[2].selectvarient[k]],
									sku:`${singleFormData.SKU}-${dateList[0].varientdata.find(d=> d.value == dateList[0].selectvarient[i]).label}-${dateList[1].varientdata.find(d=> d.value == dateList[1].selectvarient[j]).label}-${dateList[2].varientdata.find(d=> d.value == dateList[2].selectvarient[k]).label}`,
									qty:0,
									photo:[],
									mrp:0,
									speprice:0,
									stockstatus:'In Stock',
									imageLoading:false,
								}
								d.push(dl)
								}
							}
						}
					
					}
					singleFormData.varientproduct=d
					setSingleFormData({...singleFormData})
					// setVarientProduct(d)
				}
			}
	
 

	}
	const onChangeStockData=(e)=>{
		singleFormData.stockquantity =e.target.checked
		setSingleFormData({...singleFormData})
	}
	const onchangevarientProduct=(e,i)=>{
		const {name,value}= e.target;
		const varientData = [...singleFormData.varientproduct]
		varientData[i][name]=value
		singleFormData.varientproduct =varientData
		setSingleFormData({...singleFormData})
	}
	const ImageUploaderData=async(e,j)=>{
		e.preventDefault()
		const varientData = [...singleFormData.varientproduct]
		setImageLoading(true)
		for(let i =0; i < e.target.files.length; i++ ){
        
            const data = new FormData();
            data.append('type', 'products');
            data.append('image', e.target.files[i]);
        await fileUpload(data).then((result) => {
              if (result.success) {
              
				varientData[j].photo.push(result.photo)
              }
            });
        }
		setImageLoading(false)
		singleFormData.varientproduct =varientData
		console.log(singleFormData)
		setSingleFormData({...singleFormData})
	}
	const handleImageDelete=async(e,j,photo,type,uploadtype)=>{
		e.preventDefault()
		const single ={...singleFormData}
	
		await deletedUploadFile(photo,uploadtype).then((result) => {
			if (result.success) {
					single[type].splice(j,1)
					
					 setSingleFormData({...single})
			}else{
				single[type].splice(j,1)
				
					 setSingleFormData({...single})
			}
		  });
	}
	const removeVarientProduct=(e,i,id)=>{
		const varient = [...singleFormData.varientproduct]
		varient.splice(i,1)
		singleFormData.varientproduct=varient
		setSingleFormData({...singleFormData})
	}
	
	return (

		<Container fluid={true}>
			<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
				<Row className="product-adding">
					<Col xl="6">
					
							<CardHeader>
								<h5>Product</h5>
							</CardHeader>
						
								<div className="digital-add needs-validation">
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> Name
										</Label>
										<Input
											className="form-control"
											id="validationCustom01"
											type="text"
											name="name"
											required={true}
											value={singleFormData.name}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> Model
										</Label>
										<Input
											className="form-control"
											id="validationCustom01"
											type="text"
											name="model"
											value={singleFormData.model}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> HSN Code
										</Label>
										<Input
											className="form-control"
											id="validationCustom01"
											type="text"
											name="hsncode"
											value={singleFormData.hsncode}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> SKU
										</Label>
										<Input
											className="form-control"
											id="validationCustom02"
											type="text"
											name="SKU"
											required={true}
											value={singleFormData.SKU}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									<FormGroup>
									<Label className="col-form-label pt-0">
										Stock Product
										</Label>
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
											name="stockproduct"
											value={true}
											onChange={(e)=>onChangeStockData(e)}
											checked={singleFormData.stockquantity}
										/>
										Track Quantity Product
									
									</FormGroup>
									{
										singleFormData.stockquantity?
										<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> Quantity
										</Label>
										<Input
											className="form-control"
											id="validationCustom02"
											type="text"
											name="quantity"
											value={singleFormData.quantity}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									:''
									}
									<FormGroup>
										<Label className="col-form-label">Short Description</Label>
										<textarea rows="4" cols="12"	
											name='shortdescriptions'
											value={singleFormData.shortdescriptions}
											onChange={(e)=>onChangeFormData(e)}
											></textarea>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Type Of Product
										</Label>
										<select className="form-select" name='typeofproduct' value={singleFormData.typeofproduct}	onChange={(e)=>onChangeFormData(e)}>
											<option value="">--Select--</option>
											<option value="Single">Single Product</option>
											<option value="Varient">Varient Product</option>
											<option value="Combo">Combo Product</option>

										</select>
									</FormGroup>
									{/* <FormGroup>
											<Label className="col-form-label">Sort Summary</Label>
											<textarea rows="4" cols="12"></textarea>
										</FormGroup> */}
									{/* <div className="digital-add needs-validation">
										<FormGroup className=" mb-0">
											<div className="description-sm">
											<TextEditor title="Category Description" description={'hell'} name="category_description" onEditorChange={(e)=>onEditorChange(e)} datadisable={false}/>
											</div>
										</FormGroup>
									</div> */}
									
									<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									{/* <Label className="col-form-label pt-0"> Product Upload</Label>
									<ImageUploader /> */}
								</div>
						
						<Card>
							<CardHeader>
								<h5>Meta Data</h5>
							</CardHeader>
							<CardBody>
								<div className="digital-add needs-validation">
									<FormGroup>
										<Label className="col-form-label pt-0"><span>*</span> Meta Title</Label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
										
											name='metatitle'
											value={singleFormData.metatitle}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0"><span>*</span> Meta Keyword</Label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
											name='matatile'
											value={singleFormData.metakeyword}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">Meta Description</Label>
										<textarea rows="4" cols="12"	
											name='metadescription'
											value={singleFormData.metadescription}
											onChange={(e)=>onChangeFormData(e)}
											></textarea>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0"><span>*</span> Slug/URL</Label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
											name='slug'
											value={singleFormData.slug}
											onChange={(e)=>onChangeFormData(e)}
										/>
									</FormGroup>
								
								</div>
							</CardBody>
							<CardHeader>
								<h5>product Dimensions </h5>
							</CardHeader>
							<CardBody>
								<div className="digital-add needs-validation">
									
									<FormGroup>
										<Label className="col-form-label pt-0"><span>*</span>Weight(Kg)</Label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
											name='weight'
											value={singleFormData.weight}
											onChange={(e)=>onChangeFormData(e)}
											required
										/>
										
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0"><span>Dimensions Image</span></Label>
										<img src="https://asitbuzz.s3.ap-south-1.amazonaws.com/products/photo_663e35d25fffb23c618706b0.jpeg"  style={{ width: '100%', height: '100px' }} />
									</FormGroup>
									<Row>
										<Col md="4">
										<label><span>*</span>Height(cm)</label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
											name='height'
											value={singleFormData.height}
											onChange={(e)=>onChangeFormData(e)}
											required
										/>
										
										</Col>
										<Col md="4">
										<label><span>*</span>Length(cm)</label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
											name='length'
											value={singleFormData.length}
											onChange={(e)=>onChangeFormData(e)}
											required
										/>
										
										</Col>
										<Col md="4">
										<label><span>*</span>Breadth(cm)</label>
										<Input
											className="form-control"
											id="validationCustom05"
											type="text"
											name='breadth'
											value={singleFormData.breadth}
											onChange={(e)=>onChangeFormData(e)}
											required
										/>
										
										</Col>
									</Row>
								
									
								
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col xl="6">
						<Card>
							<CardHeader>
								<h5>Image</h5>
							</CardHeader>
							<CardBody>
							<ImageUploader handleImageDelete={(e,i,phots)=>handleImageDelete(e,i,phots,'photo',singleFormData.uploadtype)} showAddMoreButton={showAddMoreButton} uploadtype={singleFormData.uploadtype} imageLoading={imageLoading} handleImageSelect={(e)=>handleImageSelect(e,0)} image ={singleFormData.photo !== undefined && singleFormData.photo !== null ?singleFormData.photo:[]} />
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<h5>Product Pricing</h5>
							</CardHeader>
							<CardBody>
								<FormGroup>
									<Label className="col-form-label">
										<span>*</span> MRP
									</Label>
									<Input
										className="form-control"
										id="validationCustom02"
										type="text"
										name='mrp'
										value={singleFormData.mrp}
										onChange={(e)=>onChangeFormData(e)}
									/>
								</FormGroup>
								<FormGroup>
									<Label className="col-form-label">
										<span>*</span> Selling Price
									</Label>
									<Input
										className="form-control"
										id="validationCustom02"
										type="text"
										name='sellingprice'
										value={singleFormData.sellingprice}
										onChange={(e)=>onChangeFormData(e)}
									/>
								</FormGroup>
								<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Type Of Product
										</Label>
										<select className="form-select" name='textype' value={singleFormData.textype}	onChange={(e)=>onChangeFormData(e)}>
											<option value="">--Select--</option>
											{
												textType != undefined && textType != null && textType.map((d,i)=>(
													<option value={`${d.value}`}>{d.label}(%)</option>
												))
											}
											
											{/* <option value="Varient">Varient Product</option>
											<option value="Combo">Combo Product</option> */}

										</select>
									</FormGroup>
								
							</CardBody>
						</Card>
						
							{
								singleFormData.typeofproduct == "Single" || singleFormData.typeofproduct == 'Varient'?
								<Card>
										<CardHeader>
								<h5>Linking</h5>
							</CardHeader>
							<CardBody>
							<FormGroup>
									<Label className="col-form-label">
										<span>*</span> Product Description
									</Label>
									<div className="form-group" id="colors">
										<MultiSelect
											options={description || []}
											selected={singleFormData.descriptions || []}
											onSelectedChanged={(e)=>selectDropDown(e,'descriptions')}
										/>
									</div>
								</FormGroup>
								<FormGroup>
									<Label className="col-form-label">
										<span>*</span> Category
									</Label>
									<div className="form-group" id="colors">
										<MultiSelect
											options={ category || []}
											selected={singleFormData.category !== undefined && singleFormData.category !== null ? singleFormData.category : []}
											onSelectedChanged={(e)=>selectDropDown(e,'category')}
										/>
									</div>
								</FormGroup>
								<FormGroup>
									<Label className="col-form-label">
										<span>*</span> Brands
									</Label>
									<MultiSelect
											options={ brand || []}
											selected={singleFormData.brand || []}
											onSelectedChanged={(e)=>selectDropDown(e,'brand')}
										/>
									{/* <select className="form-select" required="" name="brand" value={singleFormData.brand}>
										<option value="">--Select--</option>
										{
											brand != undefined && brand != null && brand.map((d)=>(
												<option value={`${d.id}`}>{d.label}</option>
											))
										}
									

									</select> */}
								</FormGroup>
								{
									singleFormData.typeofproduct == 'Varient'?
									<FormGroup>
									<Label className="col-form-label">
										<span>*</span> Varient
									</Label>
									<div className="form-group" id="colors">
										<MultiSelect
											options={varient || []}
											selected={singleFormData.varient||[]}
											onSelectedChanged={(e)=>selectDropDown(e,'varient')}
										/>
									</div>
								</FormGroup>
									:''
								}
								
							</CardBody>
						</Card>
								: singleFormData.typeofproduct == "Combo"?<Card>
								<CardHeader>
						<h5>Linking</h5>
					</CardHeader>
					<CardBody>
					<FormGroup>
							<Label className="col-form-label">
								<span>*</span> Product Description
							</Label>
							<div className="form-group" id="colors">
								<MultiSelect
									options={description || []}
									selected={singleFormData.description || []}
									onSelectedChanged={(e)=>selectDropDown(e,'description')}
								/>
							</div>
						</FormGroup>
						<FormGroup>
							<Label className="col-form-label">
								<span>*</span> Product List
							</Label>
							<div className="form-group" id="colors">
								<MultiSelect
									options={ productlist || []}
									selected={singleFormData.productlist || []}
									onSelectedChanged={(e)=>selectDropDown(e,'category')}
								/>
							</div>
						</FormGroup>
					
					</CardBody>
				</Card>
						:''	}
						
					
					</Col>
					
					{/* <Col xs='12'>
						<Card>
							<CardHeader>
								<h5>Varient Tabs</h5>
							</CardHeader>
							<CardBody>
								<VarientTab />
							</CardBody>
						</Card>
					</Col> */}
					{
						singleFormData.varientlist.length != 0 ?
						<Col xs="12">
						<Card>
							<CardHeader>
								<h5>Generate Varient Product</h5>
							</CardHeader>
							<CardBody style={{maxHeight:'500px',overflowY:"auto"}}>
								{
									singleFormData.varientlist.map((d,i)=>(
										<FormGroup>
										<Label className="col-form-label">
											<span>*</span> {d.label}
										</Label>
										<div className="form-group" id="colors">
											<MultiSelect
												options={d.varientdata || []}
												selected={d.selectvarient ||[]}
												onSelectedChanged={(e)=>selectVarientDropDown(e,i)}
											/>
										</div>
									</FormGroup>	
									))
								}
								
							<FormGroup className="mb-0">
										<div className="product-buttons text-center">
											<Button type="button" color="primary" onClick={()=>generateProduct()}>
												Generate Product
											</Button>
										
										</div>
									</FormGroup>
							</CardBody>
						</Card>
					</Col>
						:''
					}
					{
						singleFormData.varientproduct.length != 0 ?
						<Col xs="12">
						<Card>
							<CardHeader>
								<h5>Varient</h5>
							</CardHeader>
							<CardBody style={{maxHeight:'500px',overflowY:"auto"}}>
								<VarientTable removeVarientProduct={(e,i,id)=>removeVarientProduct(e,i,id)} imageLoading={imageLoading} ImageUploaderData={(e,i,type)=>ImageUploaderData(e,i,type)} onchangevarientProduct={(e,i)=>onchangevarientProduct(e,i)} varientProduct={singleFormData.varientproduct} varientList={singleFormData.varientlist} />
							</CardBody>
						</Card>
					</Col>
						:''
					}
					<FormGroup className="mb-0">
										<div className="product-buttons text-center">
											{
												saveData ?
												<Button  className="btn btn-primary" disabled={true}>{`${operationType}...`}</Button>
												:	<input  className="btn btn-primary" type="submit" name="Submit" value={`${operationType}`}/>
											}
									
											
											{/* <Button type="button" color="light">
												Discard
											</Button> */}
										</div>
					</FormGroup>
				</Row>
				
			</Form>
		</Container>

	);
};

export default FormDatas;
