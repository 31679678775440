import React, { Fragment, useState } from "react";

import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import TextEditor from '../../ui/texteditor';

import ImageUploader from "../../common/imageuploader";
const FormDatas = ({isLoading,formSubmite,singleData,operationType}) => {
	console.log(singleData)

	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})
	
	
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}

	return (
		<Container fluid={true}>
				{
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>Payment Gate Way</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Payment Mathod
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="method"
												value={singleFormData.method}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Alternative Name
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="alternatename"
												value={singleFormData.alternatename}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Id
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="id"
												value={singleFormData.id}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> key
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="key"
												value={singleFormData.key}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> WEBSITE
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="WEBSITE"
												value={singleFormData.WEBSITE}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> INDUSTRY TYPE ID
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="WEBSITE"
												value={singleFormData.INDUSTRY_TYPE_ID}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> CHANNEL ID
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="CHANNEL_ID"
												value={singleFormData.CHANNEL_ID}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Salt
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="salt"
												value={singleFormData.salt}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> 
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												value={singleFormData.country}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*Order</span> 
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="order"
												value={singleFormData.order != undefined && singleFormData.order != null ? singleFormData.order :'' }
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Type Of Payment
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="typeofpayment"
														value={"Cash"}
														onChange={(e)=>onChangeFormData(e)}
														checked={singleFormData.typeofpayment =='Cash'?true:false}
													/>
													Cash
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="typeofpayment"
														value={"Online"}
														onChange={(e)=>onChangeFormData(e)}
														checked={singleFormData.typeofpayment =='Online'?true:false}
													/>
													Online
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="typeofpayment"
														value={"Upi"}
														onChange={(e)=>onChangeFormData(e)}
														checked={singleFormData.typeofpayment =='Upi'?true:false}
													/>
													UPI
												</Label>
											</div>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
										<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
										
									</div>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				}
				
			</Container>
		
	);
};

export default FormDatas;
