import axios from "axios";

export const getCommonStatus=async(url,status,cookiesValue)=>{
    if(cookiesValue !== undefined){
        return fetch(`${url}/${status}/${cookiesValue}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }else{
        return fetch(`${url}/${status}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }
   
}
export const getCommon=async(url,status,id)=>{
    if(status !== undefined && status !== null){
        return fetch(`${url}/${status}/${id}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }else{
        console.log(url)
        return fetch(`${url}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }
   
}
export const getSingleCommon=async(id,url,token)=>{
    console.log(`${url}/${token}/${id}`)
    if(id !== ''){
        return fetch(`${url}/single/${token}/${id}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }else{
        return fetch(`${url}/single/${token}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }

}
export const postCommon=async(url,data,id)=>{
    if(id !== undefined && id !== null){
        return fetch(`${url}/${id}`,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body:JSON.stringify(data)
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }else{
        return fetch(`${url}`,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body:JSON.stringify(data)
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }
  
}
export const postCommonPage=async(url,data,pageid,id)=>{
    if(id !== undefined && id !== null){
        return fetch(`${url}/${pageid}/${id}`,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body:JSON.stringify(data)
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }else{
        return fetch(`${url}`,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body:JSON.stringify(data)
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }
  
}

export const getExportFile =async(url,cookies)=>{
    return fetch(`${url}/${cookies}`,{
        method:'get',
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const postImportFile =async(url,cookies,data)=>{
    return await axios
    .post(`${url}/${cookies}`, data)
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}
export const getSearchData = async(url,cookies,data)=>{
   
    return await axios
    .post(`${url}/search/${cookies}`, data)
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}
export const postOrderExportFile =async(url,type,cookies,data)=>{
    return await axios
    .post(`${url}/${type}/${cookies}`, data)
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

export const postExportFile = async(url,cookies,data)=>{
    console.log(url)
    return await axios
    .post(`${url}/${cookies}`, data)
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}
export const postGallery=async(url,data,id)=>{
 
    return await axios
    .post(`${url}/${id}`, data)
    .then((result) => {
      console.log(result);
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}