import React, { Fragment, useState } from "react";

import MyDropzone from "../../common/dropzone";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import TextEditor from '../../ui/texteditor';
import singleImage from "../../common/singleimage";
import ImageUploader from "../../common/imageuploader";
const FormDatas = ({isLoading,formSubmite,singleData,operationType}) => {
	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [showAddMoreButton, setShowAddMoreButton] = useState(singleData.photo.length !== 0 ? true:false);
    const [imageLoading,setImageLoading] = useState(false)
	
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const onEditorChange = (evt) => {
		console.log(singleFormData)
		singleFormData['description'] =evt.editor.getData()
		setSingleFormData({...singleFormData})
		
	  };
	  const handleImageSelect = async(e) => {
        e.preventDefault();
        setShowAddMoreButton(true);
        setImageLoading(true)
		let photoList =[]
		if(singleFormData.photo !== undefined && singleFormData.photo !== null){
			 photoList =[...singleFormData.photo]
		}else{

		}
       
        for(let i =0; i < e.target.files.length; i++ ){
            console.log(i)
            console.log( e.target.files[i])
            const data = new FormData();
            data.append('type', 'products');
            data.append('image', e.target.files[i]);
         await fileUpload(data).then((result) => {
              if (result.success) {
              
                photoList.push(result.photo)
              }
            });
        }
		singleFormData.photo=[...photoList]
        setSingleFormData(singleFormData)
        setImageLoading(false)

    };
	return (
		<Container fluid={true}>
				{
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Name
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Email
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="email"
												value={singleFormData.email}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Mobile
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="mobile"
												value={singleFormData.mobile}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Ratting
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="rating"
												value={singleFormData.rating}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Description
											</Label>
											<textarea
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="review"
												value={singleFormData.review}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
										
									</div>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<h5>Review Image</h5>
								</CardHeader>
								<CardBody>
								
									<ImageUploader showAddMoreButton={showAddMoreButton} imageLoading={imageLoading} uploadtype={singleFormData.uploadtype} handleImageSelect={(e)=>handleImageSelect(e)} image ={singleFormData.photo !== undefined && singleFormData.photo !== null ?singleFormData.photo:[]} />
								</CardBody>
							</Card>
						</Col>
						
					</Row>
					<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name={`${operationType}`} value="Save"/>
												
											</div>
										</FormGroup>
					</Form>
				}
				
			</Container>
		
	);
};

export default FormDatas;
