import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Cookies from 'js-cookie';
import { Card, CardBody, CardHeader, Col, Container, Row, Button, CardFooter } from "reactstrap";
import { Link ,useNavigate} from "react-router-dom";
import urlPath from "../../url";
import { getCommonStatus } from "../../data/common";

const Coupon = () => {
	const [operationType, setOperationType] = useState('Table')
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))


	const [dataList,setDataList] =useState([
		{
		title:'Product Coupon',
		name:'Product Coupon',
		description:'',
		url:`${urlPath.productcoupon}`,
		total:0,
		active:0,
		inactive:0,
		linkTable:`${process.env.PUBLIC_URL}/product-coupon/Table`,
		linkInactive:`${process.env.PUBLIC_URL}/product-coupon/Inactive`,
		linkActive:`${process.env.PUBLIC_URL}/product-coupon/Active`,
		linkAdd:`${process.env.PUBLIC_URL}/product-coupon/Add`,
		isLoading:true,
		},
        {
            title:'Sales Coupon',
            name:'Sales Coupon',
            description:'',
            url:`${urlPath.salescoupon}`,
            total:0,
            active:0,
            inactive:0,
            linkTable:`${process.env.PUBLIC_URL}/sales-coupon/Table`,
            linkInactive:`${process.env.PUBLIC_URL}/sales-coupon/Inactive`,
            linkActive:`${process.env.PUBLIC_URL}/sales-coupon/Active`,
            linkAdd:`${process.env.PUBLIC_URL}/sales-coupon/Add`,
            isLoading:true,
            },

	])
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	let navigate = useNavigate(); 
	const urlRedirect = (path) =>{ 
	navigate(path);
   }
   useEffect(()=>{
	for(let i=0; i <dataList.length; i++){
		getCommonStatus(dataList[i].url,'count',cookiesValue).then(result=>{
			console.log(result)
			dataList[i].total= result.total
			dataList[i].active= result.active
			dataList[i].inactive= result.inactive
			dataList[i].isLoading=false
			console.log(dataList[i])
			setDataList([...dataList])
		})
	}
	
   },[])
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`Catlog`} parent="Dashboard" operationType={operationType} sublink={'catlog'}/>
				{/* <!-- Container-fluid starts--> */}
		
						<Container fluid={true}>
							<Row>
								{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
					  							<div className="product-buttons text-center">
												  <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkTable)}>
											   <span><img src="/table.png" style={{width:'25px'}} />({!d.isLoading?`${d.total}` :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="success" style={{backgroundColor:'green'}} onClick={()=>urlRedirect(d.linkActive)}>
											   <span><img src="/active.png" style={{width:'25px'}} />({!d.isLoading?(d.active) :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkInactive)}>
											   <span><img src="/inactive.png" style={{width:'25px'}} />({!d.isLoading?d.inactive :<p>isLoading...</p>})</span>
											   </Button>
										   
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
											   
									   
										   </div>
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
								
   
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Coupon;

