import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import Cookies from 'js-cookie';
import App from '../components/app'
import Createcoupons from '../components/coupons/create-coupons'
import ListCoupons from '../components/coupons/list-coupons'
import Dashboard from '../components/dashboard'
import Invoice from '../components/sales/invoice'
import Media from '../components/media/media'
import Createpage from '../components/pages/create-page'
import ListPages from '../components/pages/pagelist'
import Category from '../components/catlog/category'
import Orders from '../components/sales/orders'
import Transactionsales from '../components/sales/transactions'
import Product from '../components/catlog/product'
import Brand from '../components/catlog/brand'
import AddAttribute from '../components/catlog/addattribute'
import Attribute from '../components/catlog/attribute'
import PageContent from '../components/pages/pagelist'
import HeaderMenu from '../components/menus/headermenu'
import HeaderMenuTree from '../components/menus/headermenustructure'
import FooterMenu from '../components/menus/footermenu'
import DescriptionType from '../components/pages/descriptionType/descriptiontype'
import TypeOfProduct from '../components/catlog/typeofproduct'
import ProductDescription from '../components/pages/productdescription/productdescription'
import ProductBanner from '../components/pages/banner'
import Setting from '../components/settings'
import UserList from '../components/user'
import CartList from '../components/marketing/cart'
import WishList from '../components/marketing/wishlist'
import Transactions from '../components/sales/transactions'
import Employee from '../components/employee'
import Vendors from '../components/vendors'
import CMS from "../components/pages"
import AddEmployee from '../components/addemployee'
import Catlog from '../components/catlog'
import Sales from '../components/sales'
import Menus from '../components/menus'
import Marketing from '../components/marketing'
import AllReport from '../components/reports'
import Coupon from '../components/coupons'
import CompanyProfile from '../components/settings/companyprofile'
import UserProfile from '../components/settings/userprofile'
import Pincode from '../components/settings/pincode'
import Country from '../components/settings/currency'
import PaymentGateway from '../components/settings/paymentgateway'
import TypeOfLink from '../components/pages/typeoflink'
import DesignationType from '../components/pages/designation'
import DepartmentType from '../components/pages/department'
import DocumentType from '../components/pages/document'
import PageContentDescription from '../components/pages/pagelist'
import PageDescription from '../components/pages/pagedescription/pagedescription'
import PrivateRouter from './PrivateRouter'
import CategoryRlation from '../components/pages/categoryrelation';
import FooterMenuTree from '../components/menus/footermenustructure';
import PageStructueRelation from '../components/pages/pagestructure';
import SalesAttribute from '../components/sales/attribute';
import Tarsh from '../components/trash';
import ReviewList from '../components/marketing/review';
import SocialMediaPost from '../components/marketing/socialmediapost';
import EmailMarketing from '../components/marketing/emailmarketing';
import SmsCampaign from '../components/marketing/smscampaign';
import SocialConfiguration from '../components/marketing/socialconfiguration';
import TextType from '../components/pages/texttype';
import ServiceLink from '../components/pages/servicelink';
import EmployeeType from '../components/pages/employeetype';
import LiveView from '../components/marketing/LiveView';
import OrderReport from '../components/orderreport/orderreport';
import SalesReport from '../components/salesreport/salesReport';
import ProductReport from '../components/marketing/product';
import CategoryReport from '../components/marketing/category';
import BrandReport from '../components/marketing/brand';
import SubPageStructueRelation from '../components/pages/subpagestructure';
import Shiprocket from '../components/shiprocket';
import ShiprocketAddress from '../components/shiprocket/address';
import ShiprocketCouriers from '../components/shiprocket/couriers';
import ShiprocketNDR from '../components/shiprocket/ndr';
import ShiprocketShipments from '../components/shiprocket/shipments';
import ShiprocketReturn from '../components/shiprocket/shipments';
import SEO from '../components/pages/SEO';
const LayoutRoutes = () => {
	return (
		<Fragment>
			
			<Routes>
				<Route element={<App />}>
					<Route
						path={`${process.env.PUBLIC_URL}/dashboard`}
						element={<Dashboard />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/employee`}
						element={<Employee />}
					/>
				

					<Route
						path={`${process.env.PUBLIC_URL}/category`}
						element={ <Category />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/brand`}
						element={<Brand />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/product`}
						element={<Product />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/varient`}
						element={<Attribute />}
					/>
					
					<Route
						path={`${process.env.PUBLIC_URL}/varient-list`}
						element={<AddAttribute />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/type-of-page-content`}
						element={<PageDescription />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/different-type-product`}
						element={<TypeOfProduct />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/type-of-page`}
						element={<ListPages />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/category-relation`}
						element={<CategoryRlation />}
					/>
						
					<Route
						path={`${process.env.PUBLIC_URL}/sales-attribute`}
						element={<SalesAttribute />}
					/>	
					<Route
						path={`${process.env.PUBLIC_URL}/page-banner`}
						element={<ProductBanner />}
					/>	
					<Route
						path={`${process.env.PUBLIC_URL}/header-menu`}
						element={<HeaderMenu />}
					/>
					
					<Route
						path={`${process.env.PUBLIC_URL}/header-menu-structure`}
						element={<HeaderMenuTree />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/footer-menu-structrue`}
						element={<FooterMenuTree />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/dynamic-page-structure/:pageid`}
						element={<PageStructueRelation />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/dynamic-sub-page-structure/:pageid`}
						element={<SubPageStructueRelation />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/footer-menu`}
						element={<FooterMenu />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/footer-menu-structure`}
						element={<HeaderMenuTree />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/description-type`}
						element={<DescriptionType />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/product-description`}
						element={<ProductDescription />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/setting`}
						element={<Setting />}
					/>
				

					<Route
						path={`${process.env.PUBLIC_URL}/orders`}
						element={<Orders />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/transactions`}
						element={<Transactionsales />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/coupons/list-coupons`}
						element={<ListCoupons />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/coupons/create-coupons`}
						element={<Createcoupons />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/pages/list-page`}
						element={<ListPages />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/pages/create-page`}
						element={<Createpage />}
					/>
					{/* Sales */}
					<Route path={`${process.env.PUBLIC_URL}/orders`} element={<Orders />} />
					<Route path={`${process.env.PUBLIC_URL}/transactions`} element={<Transactions />} />
					<Route path={`${process.env.PUBLIC_URL}/media`} element={<Media />} />
					<Route path={`${process.env.PUBLIC_URL}/users`} element={<UserList />} />
					{/* Martking */}
					<Route path={`${process.env.PUBLIC_URL}/cart`} element={<CartList />} />
					<Route path={`${process.env.PUBLIC_URL}/wish-list`} element={<WishList />} />
			

					<Route
						path={`${process.env.PUBLIC_URL}/invoice`}
						element={<Invoice />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/cms`}
						element={<CMS />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/add-employee`}
						element={<AddEmployee />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/catlog`}
						element={<Catlog />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sales`}
						element={<Sales />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/menus`}
						element={<Menus />}
					/>
						<Route
						path={`${process.env.PUBLIC_URL}/marketing`}
						element={<Marketing />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/report`}
						element={<AllReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/coupons`}
						element={<Coupon />}
					/>
					
					{/* setting */}
					<Route
						path={`${process.env.PUBLIC_URL}/company-profile`}
						element={<CompanyProfile />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/user-profile`}
						element={<UserProfile />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/pincode`}
						element={<Pincode />}
					/>
						<Route
						path={`${process.env.PUBLIC_URL}/country`}
						element={<Country />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/payment-gateway`}
						element={<PaymentGateway />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/vendors`}
						element={<Vendors />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/document-type`}
						element={<DocumentType />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/type-of-department`}
						element={<DepartmentType />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/type-of-designation`}
						element={<DesignationType />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/social-link`}
						element={<TypeOfLink />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/service-link`}
						element={<ServiceLink />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/tarsh`}
						element={<Tarsh />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/review`}
						element={<ReviewList />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/social-media-post`}
						element={<SocialMediaPost />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/email-campaign`}
						element={<EmailMarketing />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/email-campaign`}
						element={<EmailMarketing />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sms-campaign`}
						element={<SmsCampaign />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/social-media-configuration`}
						element={<SocialConfiguration />}
					/>
						<Route
						path={`${process.env.PUBLIC_URL}/text-type`}
						element={<TextType />}
					/>
						<Route
						path={`${process.env.PUBLIC_URL}/type-of-employee`}
						element={<EmployeeType />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/live-view`}
						element={<LiveView />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/order-report`}
						element={<OrderReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/order-report`}
						element={<OrderReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sales-report`}
						element={<SalesReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sales-report`}
						element={<SalesReport />}
					/>
					
					<Route
						path={`${process.env.PUBLIC_URL}/category-report`}
						element={<CategoryReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/brand-report`}
						element={<BrandReport />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/product-report`}
						element={<ProductReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shiprocket-order`}
						element={<Shiprocket />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shiprocket-couriers`}
						element={<ShiprocketCouriers />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shiprocket-ndr`}
						element={<ShiprocketNDR />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shiprocket-address`}
						element={<ShiprocketAddress />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shiprocket-shipments`}
						element={<ShiprocketShipments />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/shiprocket-return`}
						element={<ShiprocketReturn />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/seo`}
						element={<SEO />}
					/>
				</Route>
			</Routes>
			
		</Fragment>
	)
}

export default LayoutRoutes