// const url =`http://localhost:5000`
const url =`https://abuzz.store` 
const imageurl = `https://asitbuzz.s3.ap-south-1.amazonaws.com`;

// export const menuUrl = `http://localhost:3001`;
export const menuUrl = `https://menu.abuzz.store`; 
export const numberOfpage = 10
const urlPath={
    imageurl:`https://asitbuzz.s3.ap-south-1.amazonaws.com`,
    // fileurl:`http://localhost:5000`,
    fileurl:`https://abuzz.store`,
    productImage:`${imageurl}/products`,
    //Authentication
    //Dashboard
    dashboard:`${url}/api/v1/dashboard`,
    admin:`${url}/api/v1/admin`,
    //Emploee
    employee:`${url}/api/v1/employee`,
     //Vendor
    vendor:`${url}/api/v1/vendor`,
    //Catlog
    category:`${url}/api/v1/category`,
    brand:`${url}/api/v1/brand`,
    product:`${url}/api/v1/product`,
    varient:`${url}/api/v1/varient`,
    varientlist:`${url}/api/v1/varient-list`,
    uploadfile:`${url}/api/v1/uploadfile`,
    headermenu:`${url}/api/v1/header-menu`,
    headermenuthree:`${url}/api/v1/header-menu-tree`,
    footermenu:`${url}/api/v1/footer-menu`,
    footermenuthree:`${url}/api/v1/footer-menu-tree`,
    //Sales
    order:`${url}/api/v1/order`,
    transection:`${url}/api/v1/transection`,
    invoice:`${url}/api/v1/invoice`,
    salesAtrribute: `${url}/api/v1/sales-attribute`,
     //Coupon
    salescoupon:`${url}/api/v1/sales-coupon`,
    productcoupon:`${url}/api/v1/product-coupon`,
    //cms
    textype:`${url}/api/v1/tex-type`,
    descriptiontype:`${url}/api/v1/description-type`,
    producttype:`${url}/api/v1/product-type`,
    productDescription:`${url}/api/v1/product-description`,
    pagelist:`${url}/api/v1/page-list`,
    pageDescription:`${url}/api/v1/page-description`,
    pagebanner:`${url}/api/v1/page-banner`,
    typeoflink:`${url}/api/v1/type-of-link`,
    documenttype:`${url}/api/v1/document-type`,
    deparmenttype:`${url}/api/v1/department-type`,
    designationtype:`${url}/api/v1/designation-type`,
    //media
    media:`${url}/api/v1/gallery`,
    //user
    user:`${url}/api/v1/user`,
    //Marketing
    cart:`${url}/api/v1/cart`,
    wishlist:`${url}/api/v1/wish-list`,
    review:`${url}/api/v1/review`,
    //Report
    productreport:`${url}/api/v1/product-report`,
    salesreport:`${url}/api/v1/sales-report`,
     headerMenuTree:`${url}/api/v1/header-menu-tree`,
     footerMenuTree :`${url}/api/v1/footer-menu-tree`,
     pageStructure :`${url}/api/v1/page-structure-tree`,
     subpageStructure :`${url}/api/v1/sub-page-structure-tree`,
    categoryRelation :`${url}/api/v1/category-relation`,
    //Setting
    companyprofile:`${url}/api/v1/company-profile`,
    userprofile:`${url}/api/v1/user-profile`,
    country:`${url}/api/v1/country`,
    pincode:`${url}/api/v1/picode-list`,
    paymentgateway:`${url}/api/v1/payment`,
    //Export File
    excelreport:`${url}/api/v1/excelreport`,
    // Shiprocket
    shiprocket:`${url}/api/v1/shiprocket-api`,
    // SEO
    seo:`${url}/api/v1/seo`,
}

export default urlPath;