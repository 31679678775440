import React, { Fragment, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
// import data from "../../../assets/data/pro_list";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import FormDatas from './form'
import { getCommon, postCommon, getSingleCommon } from '../../data/common'
import { UiLoading } from "../ui/uiloading";
import urlPath, { numberOfpage } from "../../url";
const HeaderMenu = () => {
	const [checkedValue,setCheckedValues] = useState([])
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const [searchDataList,setsearchDataList] = useState({})
	const [searchValue,setSearchValue] = useState('')
	const {slug} = useParams()
	const [searchData,setSearchData] = useState('')
	const [saveData,setSaveData] = useState(false)
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [attributeList,setAttributeList] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,linktype:'',typeofdropdown:'single' })
	const showForm = (Type,id) => {
	
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.footermenu,Cookies.get('jwt')).then(async result => {
				setSingleData(result.data)
				console.log(result.data)
				let url='';
				if(result.data.linktype !== 'blank' && result.data.linktype !== "" ){
					if(result.data.linktype== 'brand'){
						url=`${urlPath.brand}`
					 }else  if(result.data.linktype== 'category'){
						url=`${urlPath.category}`
					 }else if(result.data.linktype== 'page'){
						url=`${urlPath.pagelist}`																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				
					 }else if(result.data.linktype== 'typeofproduct'){
						url=`${urlPath.producttype}`
					 }
				await getCommon(url,"Table",Cookies.get('jwt')).then(result => {
				
					const datas=[]
					for(let i =0; i < result.data.length;i++){
					           const d={
								   value: result.data[i]._id,
								   label: result.data[i].name
								}
								datas.push(d)
					         }
						  console.log(datas.length)
						   setAttributeList(datas)
						   setIsLoading(false)
					})
				}else{
					setIsLoading(false)
				}
				
			
			})


		} else {
			setSingleData({ status: true,linktype:'',typeofdropdown:'single' })
			setAttributeList([])
			setIsLoading(false)
		}

	}
	useEffect(() => {
		console.log(slug)
		if(slug == 'Add'){
			setIsLoading(false)
		}else {
			let status ='';
		
			if(slug == 'Table'){
				status='Table'
			}else if(slug== 'Active'){
				status = true
			}else if(slug== 'Inactive'){
				status =false
			}
			getCommon(urlPath.footermenu,'Table',cookiesValue).then(result => {
				if(result.success){
					if(result.data.length != 0){
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						name: result.data[i].name,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
	
					}
					data.push(d)
				}
				setData([...data])
				setIsLoading(false)
			}else{
				setIsLoading(false)
				setData([])
			}
			
		}else{
			if(result.message == "User Not Register" || result.message == "you are n't a authorised user"){
				window.location.href='/'
			}
		}
			}).catch(err => {
				console.log(err)
			})
		}
			
		
	

	}, [])
	
	const formSubmite = (e, singleFormData) => {
		e.preventDefault()
		setIsLoading(true)
		singleFormData.operation=operationType
		
		postCommon(urlPath.footermenu,singleFormData,Cookies.get('jwt')).then(async result => {
			if (result.success) {
		await getCommon(urlPath.footermenu,'Table',Cookies.get('jwt')).then(result => {
					
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
		
						}
						data.push(d)
						setData([...data])
					}
					setOperationType('Table')
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})
	}
	const showSearch=(type)=>{
	
		setSearchData(type)
	}
	const searchForm=(e)=>{
		const {name,value} = e.target;
		const single ={...searchDataList}
		single[name] = value
		setsearchDataList(single)
		// getSearchData
		setSearchValue(value)
	 }
	 const searchForamData=(e)=>{
		e.preventDefault();
		if(Object.keys(searchDataList).length != 0){
	
			searchDataList.operation='Search'
			setIsLoading(true)
			postCommon(urlPath.product,searchDataList,Cookies.get('jwt')).then(result=>{
				const data = []
				if(result.data.length != 0){
		
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							image: <img alt={`${result.data[i].name}`} src={`${urlPath.imageurl}/${result.data[i].photo.length != 0?`${result.data[i].photo[0].uploadtype}/${result.data[i].photo[0].image}`:''}`} style={{width:50,height:50}}/>,
							name: result.data[i].name,
							slug: result.data[i].slug,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
		
						}
						data.push(d)
						setData([...data])
						setOperationType('Table')
							setIsLoading(false)
					}
				}else{
					setData([])
					setOperationType('Table')
					setIsLoading(false)
				}
							
			})
		}else{
			alert("Please select Status ")
		}
		setSingleData({})
	 }
	 const handlePageChange = (page) => {
		
		setCurrentPage(page)
		   
	  };
	  const handleRowsPerPageChange = (newRowsPerPage) => {
		console.log(newRowsPerPage)
		setRowsPerPage(newRowsPerPage)
		
	};
	return (
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Header Menu`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Header Menu`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'header-menu'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
											<CardBody>
												{
													searchData == 'search'?
													<Form>
												<div className="row">
												<Col md="5">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select" >
											<option value="">--Select--</option>
											<option value="1">eBooks</option>
											<option value="2">Graphic Design</option>
											<option value="3">3D Impact</option>
											<option value="4">Application</option>
											<option value="5">Websites</option>
										</select>
									</FormGroup>
												</Col>
												<Col md="5" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Country
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												
												
											/>
										</FormGroup>
										
												</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="10" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													
													
												/>
											</FormGroup>
											
													</Col>
												<Col sm="2" style={{paddingTop:"30px"}}>
												
												<FormGroup className="mb-0">
												<div className="product-buttons text-center">
												<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
													
												</div>
											</FormGroup>
											
												</Col>
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right">
												<FormGroup >
												<select className="form-select" name="status" value={searchDataList.status} onChange={(e)=>searchForm(e)} >
													<option value="">--Multiple Deleted--</option>
													<option value="Deleted">Deleted</option>
													<option value="Duplicate">Duplicate</option>
													<option value="Select Export">Select Export</option>
												</select>
												</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
											
													<Button
														type="button"
														color="primary"
														onClick={() => showForm('Add')}
													>
													<i class="fa fa-plus" aria-hidden="true"></i>
													</Button>
								
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
													{
														isLoading ?
														<UiLoading/>
														:	<Datatable
														myData={data}
														multiSelectOption={false}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={true}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRowsPerPageChange={handleRowsPerPageChange}
														checkedValues={checkedValue}
													/>
													}
												
												</div>
												</CardBody>
						</Card>
											
										:isLoading ?
										<UiLoading/>
										:
										<Card>
					
											<CardBody>
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
									<Button
											type="button"
											color="primary"
											onClick={() => showSearch('back')}
											style={{'marginRight':'10px'}}
										>
									<i class="fa fa-arrow-left" aria-hidden="true"></i>
										</Button>
								</div>
								<div className="clearfix"></div>
										<FormDatas isLoading={isLoading} attribute={attributeList} cookiesValue={cookiesValue} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData,operationType)} />
										</CardBody>
										</Card>
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
	);
};

export default HeaderMenu ;