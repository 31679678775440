import React, { Fragment, useState } from "react";
import MultiSelect from '@khanacademy/react-multi-select';
import MyDropzone from "../../common/dropzone";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import TextEditor from '../../ui/texteditor';
import ImageUploader from "../../common/imageuploader";
const FormDatas = ({isLoading,formSubmite,singleData,product,productlist,operationType}) => {
	let i = 1;
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value.trim()
			singleFormData['metatitle']=value.trim()
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value.trim()
		setSingleFormData({...singleFormData})
		
	}

	 
	const selectDropDown=(e,type)=>{
		console.log(e)
		singleFormData[type] =e
		console.log(singleFormData)
		setSingleFormData({...singleFormData})
	}

	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="6">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Title
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<Label className="col-form-label">
									<span>*</span> Product Description
								</Label>
							    <div className="form-group" id="colors">
									<MultiSelect
										options={productlist || []}
										selected={singleFormData.productlist || []}
										onSelectedChanged={(e)=>selectDropDown(e,'productlist')}
									/>
								</div>
									
										
									</div>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<h5>Product List</h5>
								</CardHeader>
								<CardBody>
									{
										productlist !== undefined && productlist !== null && productlist.map(d=>(
											singleFormData.productlist.some(l=> l==d.value)?
											<h6>{i++}:<span style={{color:'red'}}>{d.label}</span></h6>
											:''
										))
									}
									
								</CardBody>
							</Card>
						</Col>
						<Col xl="6">
							
							<Card>
								<CardHeader>
									<h5>Meta Data</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0"> Meta Title</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='metatitle'
												type="text"
												
												value={singleFormData.metatitle}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Meta Key Word</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='metakeyword'
												value={singleFormData.metakeyword}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Meta Description</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='metadescription'
												value={singleFormData.metadescription}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Slug</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="slug"
												value={singleFormData.slug}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										
										<FormGroup>
											<Label className="col-form-label">Meta Description</Label>
											<textarea rows="4" cols="12"></textarea>
										</FormGroup>
										<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
