import React, { Fragment, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { Link ,useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, CardFooter } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import urlPath from "../../url";
import { getCommonStatus } from "../../data/common";


const AllReport = () => {
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const [dataList,setDataList] =useState([
		{
		title:'Product Report',
		name:'Product Report',
		description:'',
		url:`${urlPath.productreport}`,
		total:0,
		active:0,
		inactive:0,
		linkTable:`${process.env.PUBLIC_URL}/category/Table`,
		linkInactive:`${process.env.PUBLIC_URL}/category/Inactive`,
		linkActive:`${process.env.PUBLIC_URL}/category/Active`,
		linkAdd:`${process.env.PUBLIC_URL}/category/Add`,
		isLoading:true,
		},
		{
			title:'Sales Report',
			name:'Sales Report',
			description:'',
			url:`${urlPath.salesreport}`,
			total:0,
			active:0,
			inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/category/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/category/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/category/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/category/Add`,
			isLoading:true,
			},

	])
	let navigate = useNavigate(); 
	const urlRedirect = (path) =>{ 
	navigate(path);
   }
   useEffect(()=>{
	for(let i=0; i <dataList.length; i++){
		getCommonStatus(dataList[i].url,'count',cookiesValue).then(result=>{
			console.log(result)
			dataList[i].total= result.total
			dataList[i].active= result.active
			dataList[i].inactive= result.inactive
			dataList[i].isLoading=false
			console.log(dataList[i])
			setDataList([...dataList])
		})
	}
	
   },[])
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`REPORT`} parent="Dashboard" operationType={operationType} sublink={'report'}/>
				{/* <!-- Container-fluid starts--> */}
		
				<Container fluid={true}>
							<Row>
								{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
					  							<div className="product-buttons text-center">
												
										   
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
											   
									   
										   </div>
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
								
   
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default AllReport;

