import React, { Fragment,useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Breadcrumb from "../../common/breadcrumb";
import data from "../../../assets/data/pro_list";
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import { getProduct,getSingleProduct,postProduct,getDropDown } from '../../../data/catlog/product'
import FormDatas from './form'
import { UiLoading } from "../../ui/uiloading";
import urlPath, { numberOfpage } from "../../../url";
import { getCommon,getSingleCommon, postCommon,getExportFile,postImportFile,postExportFile, } from "../../../data/common";
import ImageUploads from "../../ui/imageupload";
import ProductDescriptionForm from "../../pages/productdescription/product_description";
const Product= () => {
	const [checkedValue,setCheckedValues] = useState([])
	const [editType,setEditType] = useState(false)
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [bulkData,setBulkData] = useState([])
	const [currentPage,setCurrentPage] = useState(1);
	const {slug} = useParams()
	const [message,setMessage] = useState('')
	const [color,setColor] = useState('')
	const [searchData,setSearchData] = useState('')
	const [searchValue,setSearchValue] = useState('')
	const [searchDataValue,setSearchDataValue] = useState({})
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [category,setCategory] = useState([])
	const [textType,setTextType] = useState([])
	const [productlist,setProductList] = useState([])
	const [brand,setBrands]= useState([])
	const [varient,setVarient]= useState([])
	const [varientlist,setVarientList]= useState([])
	const [description,setDescription]= useState([])
	const [saveData,setSaveData] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [dataList,setDataList] = useState([])
	const [singleData, setSingleData] = useState({sellingprice:0, status: true,typeofproduct:'Single', stockquantity:false,photo:[],brand:'',category:[],varient:[],descriptions:[],productlist:[],brand:[],varientlist:[],varientproduct:[] })
	const [searchDataList,setsearchDataList] = useState({})
	const showForm =async (Type,id) => {

		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
		await getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.product,Cookies.get('jwt')).then(async result => {
			// setSingleData(result.data)
			await getDropDown("Varient").then(results=>{
				if(results.success){
					setTextType(results.tex)
					setCategory(results.category)
					// setCategory(results.category)
					setBrands(results.brands)
					setVarient(results.varientlist)
					setProductList(results.product)
					setDescription(results.description)
					setIsLoading(false)
					setOperationType(Type)
				}
			})
				console.log(result)
				setSingleData(result.data)
				setIsLoading(false)
			})

		} else {
			await getDropDown("Varient").then(result=>{
				if(result.success){
					setTextType(result.tex)
					setCategory(result.category)
					setBrands(result.brands)
					setVarient(result.varientlist)
					setProductList(result.product)
					setDescription(result.description)
					setIsLoading(false)
					setOperationType(Type)
				}
			})
			setSingleData({sellingprice:0, status: true,typeofproduct:'Single', stockquantity:false,photo:[],brand:'',category:[],varient:[],descriptions:[],productlist:[],brand:[],varientlist:[],varientproduct:[] })	
		}

	}
	useEffect(() => {
	
			getCommon(urlPath.product,'Table',cookiesValue).then(result => {
				if(result.success){
				
					if(result.data.length != 0){
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					console.log(result.data[i].uploadtype)
					console.log(result.data[i]._id)
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						image: <img alt={`${result.data[i].name}`} src={`${urlPath.imageurl}/${result.data[i].photo.length != 0?`${result.data[i].photo[0].uploadtype}/${result.data[i].photo[0].image}`:''}`} style={{width:50,height:50}}/>,
						name: result.data[i].name,
						// slug: result.data[i].slug,
						Qty: result.data[i].quantity,
						Mrp: result.data[i].mrp,
						'Selling': result.data[i].sellingprice,
						// description:result.data[i].descriptions!== undefined && result.data[i].descriptions !== null && result.data[i].descriptions.length !==0?  <Button className="btn btn-primary" onClick={()=>showProductDescription("Description",result.data[i]._id)}>Description</Button>:'No Description',
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
	
					}
					data.push(d)
				}
				setData([...data])
				setIsLoading(false)
			}else{
				setIsLoading(false)
				setData([])
			}
			
		}else{
			if(result.message == "User Not Register" || result.message == "you are n't a authorised user"){
				window.location.href='/'
			}
		}
			}).catch(err => {
				console.log(err)
			})
		
			
		
	

	}, [])
	const showProductDescription = (Type,id) => {
		console.log(Type)
		
		setIsLoading(true)
		
			getSingleCommon(id,urlPath.productDescription,Cookies.get('jwt')).then(result => {
				console.log(result)
				setDataList(result.data)
				setSingleData(result.product)
				setIsLoading(false)
			})
			setOperationType(Type)
			// setOperationType('Edit')

	}
	const formSubmite =async (e, singleFormData) => {
	console.log('hello')
	e.preventDefault()
	singleFormData.operation=operationType
	setSaveData(true)
	postCommon(urlPath.product,singleFormData,Cookies.get('jwt')).then(async result => {
		if (result.success) {
			setSaveData(false)
				setIsLoading(true)
		await getCommon(urlPath.product,'Table',Cookies.get('jwt')).then(result => {
					
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							image: <img alt={`${result.data[i].name}`} src={`${urlPath.imageurl}/${result.data[i].photo.length != 0?`${result.data[i].photo[0].uploadtype}/${result.data[i].photo[0].image}`:''}`} style={{width:50,height:50}}/>,
							name: result.data[i].name,
							slug: result.data[i].slug,
							description:result.data[i].descriptions!== undefined && result.data[i].descriptions !== null && result.data[i].descriptions.length !==0?  <Button className="btn btn-primary" onClick={()=>showProductDescription('Description',result.data[i]._id)}>Description</Button>:'No Description',
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
		
						}
						data.push(d)
						setData([...data])
					}
					setOperationType('Table')
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})
	}
	
	const formProductDescription =async (e, singleFormData) => {
		e.preventDefault()
		console.log(singleFormData)
		
		setSaveData(true)
	await postCommon( urlPath.productDescription,singleFormData,Cookies.get('jwt')).then(async result => {
			if (result.success) {
				alert("Updated Data Successfully")
				setSaveData(false)
				// await getCommon(urlPath.productDescription,'Table',Cookies.get('jwt')).then(result => {
					
				// 	console.log(result)
				// 	const data = []
				// 	for (let i = 0; i < result.data.length; i++) {
				// 		const d = {
				// 			id: result.data[i]._id,
				// 			'Sl': `${parseInt(i + 1)}`,
				// 			name: result.data[i].name,
				// 			Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
							
				// 		}
				// 		data.push(d)
				// 		setData([...data])
				// 	}
				// 	setIsLoading(false)
				// }).catch(err => {
				// 	console.log(err)
				// })
				// setIsLoading(false)
			} else {

			}
		})

	}
	const showSearch=async(type)=>{
		setSearchData(type)
		if(type == 'export'){
			if(Object.keys(searchDataList).length == 0){
				setColor('red')
				setMessage(`Data ${type}...`)
				await postExportFile(`${urlPath.product}/search-export`,Cookies.get('jwt'),searchDataList).then(result=>{
					window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
					setColor('green')
					setMessage(`Data ${type} Successfully`)
					
				}).catch(err=>{
					console.log(err)
				})
			}else{
				setColor('red')
				setMessage(`Data ${type}...`)
				await postExportFile(`${urlPath.product}/search-export`,Cookies.get('jwt'),searchDataList).then(result=>{
					window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
					setColor('green')
					setMessage(`Data ${type} Successfully`)
					
				}).catch(err=>{
					console.log(err)
				})
			}
		
		}else if(type == 'back'){
			setIsLoading(true)
			setCurrentPage(1)
			getCommon(urlPath.product,'Table',Cookies.get('jwt')).then(result => {
			
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						image: <img alt={`${result.data[i].name}`} src={`${urlPath.imageurl}/${result.data[i].photo.length != 0?`${result.data[i].photo[0].uploadtype}/${result.data[i].photo[0].image}`:''}`} style={{width:50,height:50}}/>,
						name: result.data[i].name,
						slug: result.data[i].slug,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
	
					}
					data.push(d)
					setData([...data])
				}
				setIsLoading(false)
				setOperationType('Table')
				setSingleData({})
			}).catch(err => {
				console.log(err)
			})
		}
		
	}
 const uploadFileData =async(e,search)=>{
	 setColor('red')
	setMessage(`Data ${search}...`)
	const data = new FormData();
	data.append('image', e.target.files[0]);
	await postImportFile(`${urlPath.product}/excel-report`,Cookies.get('jwt'),data).then((result) => {
	     if (result.success) {
			 setColor('green')
			setMessage(`Data ${search} Successfully`)
			window.location.reload()
	     }else{

		 }
	   });
 }
 const searchForm=(e,type)=>{
	
	const {name,value} = e.target;
	let single ={...searchDataValue}
	if(type == 'Multi Search'){
		single[`${name}`]=value
		if(Object.keys(checkedValue).length != 0){
			searchDataList.operation='Multi Search'
			setSearchDataValue({...single})
			setMessage(`${value} Product Update Loading...`)
			postCommon(urlPath.product,{type:value,operation:'Multi Search',checkValue:checkedValue},Cookies.get('jwt')).then(result=>{
			   if(result.success){
				setMessage(`${value} Product Update Successfully`)
			   }else{
                setMessage(`${value} Product Not Update Successfully`)
			   }
							
			})
		}else{
			alert("Please Select At Least One Element")
		}
	}else if(type == 'Search Data'){
		single[`${name}`]=value
	
		setSearchDataValue({...single})
	}else if(type == 'Search'){
		if(name == 'status'){
			single[`${name}`] = value
		}else{
           single[`${value}`] = ''
		   single[`operation`] = type
		   setSearchValue(value)
		}
		console.log(single)
		setSearchDataValue({...single})
		// getSearchData
	
	}
 }
 const searchForamData=(e)=>{
	e.preventDefault();
	
	if(Object.keys(searchDataValue).length != 0){

		searchDataList.operation='Search'
		setIsLoading(true)
		postCommon(urlPath.product,searchDataValue,Cookies.get('jwt')).then(result=>{
			const data = []
			if(result.data.length != 0){
	
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						image: <img alt={`${result.data[i].name}`} src={`${urlPath.imageurl}/${result.data[i].photo.length != 0?`${result.data[i].photo[0].uploadtype}/${result.data[i].photo[0].image}`:''}`} style={{width:50,height:50}}/>,
						name: result.data[i].name,
						slug: result.data[i].slug,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
	
					}
					data.push(d)
					setData([...data])
					setOperationType('Table')
						setIsLoading(false)
				}
			}else{
				setData([])
				setOperationType('Table')
				setIsLoading(false)
			}
						
		})
	}else{
		alert("Please select Status ")
	}
	setSingleData({})
 }
 const handlePageChange = (page) => {
	
	setCurrentPage(page)
   	
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
	console.log(newRowsPerPage)
	setRowsPerPage(newRowsPerPage)
	
};
const handleRemoveRow =(e,id)=>{
	
	const checkData =[...checkedValue]
	if(e.target.checked){
		checkData.push(id)
		setCheckedValues([...checkData])
	}else{
	 const d=checkData.filter(df=> console.log(`${df}` != `${id}`))

	setCheckedValues([...d])
	}

	
}
const onRowClicked=(row, event)=>{
	console.log(row)
	console.log(event)
}
const changeRowData=(id,type)=>{
     document.querySelectorAll(`.mrp-value`).forEach(function(el) {
		el.style.display = 'block';
	 })
	 document.querySelectorAll('.mrp-hide').forEach(function(el) {
		el.style.display = 'none';
	 })
     document.getElementById(`${type}${id}`).style.display='block'
	document.getElementById(`${type}-value-${id}`).style.display='none'
	
	
}
const handleChangrRow=(e,id)=>{
	const dataList =[...data]
	const bulkList = [...bulkData]
	const {name,value}= e.target;
	
	dataList[dataList.findIndex(d=> `${d.id}` == `${id}`)][name] = value
	dataList[dataList.findIndex(d=> `${d.id}` == `${id}`)]['typeofedit']='row-edit'
	console.log(dataList[dataList.findIndex(d=> `${d.id}` == `${id}`)])
	
	if(!bulkList.some(d=> d.id == id)){
		setBulkData([...bulkList,dataList[dataList.findIndex(d=> `${d.id}` == `${id}`)]])
	}else{
		bulkList[bulkList.findIndex(d=> `${d.id}` == `${id}`)][name] = value
		setBulkData([...bulkList])
	}
	setData([...dataList])
	setEditType(true)
	
}
const saveBulkData=()=>{
	const bulkList =[...bulkData]
	setMessage('Bulk Data Update Loading...')
	postCommon(urlPath.product,{operation:'Bulk',bulkList:bulkList},Cookies.get('jwt')).then(async result => {
		if (result.success) {
			setMessage('Bulk Data Update Successfully...')
			  document.querySelectorAll(`.mrp-value`).forEach(function(el) {
		el.style.display = 'block';
	 })
	 document.querySelectorAll('.mrp-hide').forEach(function(el) {
		el.style.display = 'none';
	 })
			
		}else{

		}
	})
}

const allOPeration=(e)=>{
	const dataList =[...data]
	if(e.target.checked){
		const dl = dataList.map(d=> d.id)
		console.log(dl)
		setCheckedValues(dl)
	}else{
		setCheckedValues([])
	}
}
	return (
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Product`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Product`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'product'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
											<CardBody>
												{
													searchData == 'search'?
													<Form onSubmit={(e)=>searchForamData(e)}>
												<div className="row">
												<Col md="3">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select"  onChange={(e)=>searchForm(e,"Search")} >
											<option value="">--Select--</option>
											<option value="Product">Product</option>
											<option value="SKU">SKU</option>
											<option value="Model">Model Name</option>
											<option value="HSN">HSN Code</option>		
										</select>
									</FormGroup>
												</Col>
												{
													searchValue != '' ?
													<Col md="3" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> {searchValue}
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													required=""
													name={searchValue}
													value={searchDataValue[searchValue]}
													onChange={(e)=>searchForm(e,'Search Data')}
												/>
											</FormGroup>
													</Col>
													:''
												}
											
										<Col md="3">

										<FormGroup >

										<Label className="col-form-label">
											<span>*</span> Status
										</Label>
										<select className="form-select" name="status" value={searchDataValue.status != undefined && searchDataValue.status != null ? searchDataList.status :""} onChange={(e)=>searchForm(e,'Search')} >
											<option value="">Default</option>
											<option value="true">Active</option>
											<option value="false">In Active</option>
											
										
										</select>
									</FormGroup>
										</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	
													
													<div className="row">
												
													<Col md="12" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													onChange={(e)=>uploadFileData(e,searchData)}
													
												/>
											</FormGroup>
											
													</Col>
												
										
														<div>
													
														</div>
	
													</div>
													
													:''
												}
												
												<div className="btn-popup pull-right">
												<FormGroup >
												<select className="form-select" name="multisearch" value={searchDataValue.multisearch != undefined && searchDataValue.multisearch != null ? searchDataValue.multisearch:'' } onChange={(e)=>searchForm(e,'Multi Search')} >
													<option value="">--Multiple Deleted--</option>
													<option value="Deleted">Deleted</option>
													<option value="Duplicate">Duplicate</option>
													<option value="Select Export">Select Export</option>
													<option value="Google">Google Product</option>
													<option value="Facebook">Facebook Product</option>
												</select>
												</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('import')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-upload" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('export')}
														style={{'marginRight':'10px'}}
													>
													<i class="fa fa-download" aria-hidden="true"></i>
													</Button>
													<Button
														type="button"
														color="primary"
														onClick={() => showForm('Add')}
														style={{'marginRight':'10px'}}
													>
													<i class="fa fa-plus" aria-hidden="true"></i>
													</Button>
													{editType ? 
													<Button
														type="button"
														color="primary"
														onClick={() => saveBulkData()}
													>
													<i class="fa fa-save" aria-hidden="true"></i>
													</Button>
													:'' }
												
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
												<h6 style={{textAlign:'center',color:`${color}`}}>{message}</h6>
													{
														isLoading ?
														<UiLoading/>
														:<Datatable
														myData={data}
														multiSelectOption={false}
														pageSize={data.length}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={true}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRowsPerPageChange={handleRowsPerPageChange}
														handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
														checkedValues={checkedValue}
														onRowClicked={(row, event)=>onRowClicked(row, event)}
														changeRowData={(id,type)=>changeRowData(id,type)}
														handleChangrRow={(e,id)=>handleChangrRow(e,id)}
														allOPeration={(e)=>allOPeration(e)}
													/>
													}
													
												</div>
												</CardBody>
						</Card>
											
										:operationType == 'Description' ?
										
											isLoading ?
											<UiLoading/>
											:
											<Card>
													{/* <CardHeader>
														<h5>Description</h5>
													</CardHeader>	 */}
											<CardBody>
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
									<Button
											type="button"
											color="primary"
											onClick={() => showSearch('back')}
											style={{'marginRight':'10px'}}
										>
									<i class="fa fa-arrow-left" aria-hidden="true"></i>
										</Button>
								</div>
								<div className="clearfix"></div>
									<div id="basicScenario" className="product-physical">

									</div>
									<ProductDescriptionForm  saveData={saveData} data={dataList}  singleData={singleData} formSubmite={(e, singleFormData) => formProductDescription(e, singleFormData)} />		
							</CardBody>
												</Card>
										
										:isLoading ?
											<UiLoading/>
											:
											<Card>
												{/* <CardHeader>
														<h5>Description</h5>
														
													</CardHeader>	 */}
											<CardBody>
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
									<Button
											type="button"
											color="primary"
											onClick={() => showSearch('back')}
											style={{'marginRight':'10px'}}
										>
									<i class="fa fa-arrow-left" aria-hidden="true"></i>
										</Button>
								</div>
								<div className="clearfix"></div>
									<div id="basicScenario" className="product-physical">

									</div>
									<FormDatas textType={textType} isLoading={isLoading} operationType={operationType} productlist={productlist} category={category} description={description} brand={brand} varient={varient} varientlist={varientlist} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData)} />
							</CardBody>
												</Card>
										
										
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
	);
};

export default Product;

