import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab, } from "react-tabs";
import MultiSelect from '@khanacademy/react-multi-select';
import { Button, Form,Container,Card,CardBody,Input,FormGroup,Label,Col,Row } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import urlPath from "../../../url";
import { getCommon, getSingleCommon, postCommon } from "../../../data/common";
import Cookies from "js-cookie";
import { getDropDown } from "../../../data/catlog/product";
import { UiLoading } from "../../ui/uiloading";
import { fileUpload } from "../../../data/catlog/fileupload";


const CompanyProfile = ({title,description}) => {
	const [singleFormData,setSingleFormData] = useState({operation:'', sociallink:[],document:[],sociallist:[],documentlist:[]})
	const [isLoading,setIsLoading] = useState(true)
	const [sociallink,setSocialLink]= useState([])
	const [document,setDocument]=useState([])
	const [country,setCountry]=useState([])
	const [value, setValue] = useState('')
	console.log(description)
	useEffect(()=>{
		getSingleCommon('',urlPath.companyprofile,Cookies.get('jwt')).then(async results => {
			console.log(results.data)
			setSingleFormData(results.data)
	        setIsLoading(false)
			
		}).catch(err => {
			console.log(err)
		})
	},[])
	const selectDropDown=(e,type)=>{
		const single ={...singleFormData}
		const data=[]
		if(type== 'document'){
			
			if(single.documentlist != undefined &&  single.documentlist != null){
				for(let i=0 ; i < e.length;i++){
					if(!single.documentlist.some(d=> d.value == e[i])){
						const d={
							value:document.find(k=> k.value == e[i]).value,
							name:document.find(k=> k.value == e[i]).label,
							number:'',
							file:''
						   }
						   single.documentlist.push(d)
					}

				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
					const d={
						value:document.find(k=> k.value == e[i]).value,
						name:document.find(k=> k.value == e[i]).label,
						number:'',
						file:''
					   }
					   data.push(d)
				}
				single.documentlist=data
            }
		}else if(type== 'sociallink'){
			if(single.sociallist != undefined &&  single.sociallist != null){
				for(let i=0 ; i < e.length;i++){
					
					if(!single.sociallist.some(d=> d.value == e[i])){
						const d={
							value:sociallink.find(k=> k.value == e[i]).value,
							name: sociallink.find(k=> k.value == e[i]).label,
							url:''
						   }
						   single.sociallist.push(d)
					}

				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
					const d={
						value:sociallink.find(k=> k.value == e[i]).value,
						name: sociallink.find(k=> k.value == e[i]).label ,
						url:''
					   }
					   data.push(d)
				}
				single.sociallist=data
            }
			
		}

		single[type] =e
		console.log(single)
		
		setSingleFormData({...single})
	}
	const onChangeFormData=(e)=>{
		const single={...singleFormData}
       const {name,value}= e.target;
	   single[name]=value
	   console.log(single)
	   setSingleFormData({...single})
 	}
	const socialLinkChange=(e,type,i)=>{
		const {name,value}= e.target;
		const single={...singleFormData}
		
		single[type][i][name]= value
		setSingleFormData({...single})
	}
	const onChangeFileUplaodData=async(e,type)=>{
		console.log(type)
		e.preventDefault()
		const single = {...singleFormData}
		
		for(let i =0; i < e.target.files.length; i++ ){
        
            const data = new FormData();
            data.append('type', 'company');
            data.append('image', e.target.files[i]);
        await fileUpload(data).then((result) => {
              if (result.success) {
				single[type]= result.photo
				
              }
            });
        }
	single['documenttype']='company'
	console.log(single)
	setSingleFormData({...single})
	}
	const formSubmite =async (e) => {
		console.log('hello')
		e.preventDefault()
		
		singleFormData['operation']='Edit'
		console.log(singleFormData)
	await postCommon(urlPath.companyprofile,singleFormData,singleFormData._id).then(async result => {
			if (result.success) {
				alert('Updated Data Successfully')
			} else {

			}
		})

	}
	return (
		<Fragment>
	<Breadcrumb title={`Company Profile`} parent="Dashboard" operationType={'Edit'} mainlink={`dashboard`} sublink={'setting'} type={true}/>
		<Container fluid={true}>
			<Card>
				{/* <CardHeader>
					<h5>{operationType} Employee</h5>
				</CardHeader> */}
				<CardBody>
				<div>
				{
								isLoading ?
								<UiLoading />
								:
								<>
								<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						
								<Tab className="nav-link" >Company Details</Tab>
								<Tab className="nav-link" >Logo / Favicon</Tab>
								
								<Tab className="nav-link" >Document</Tab>
								
								
								<Tab className="nav-link" >Social Link</Tab>
								
					</TabList>
							<Form className="needs-validation" onSubmit={(e)=>formSubmite(e)}>
								<TabPanel>
									<Row>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span> Company Name
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="company"
													value={singleFormData.company}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Business Name
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="bissnesstorename"
													value={singleFormData.bissnesstorename}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Email
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
											
													name="email"
													value={singleFormData.email}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Mobile
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="mobile"
													value={singleFormData.mobile}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Support Email
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="supportemail"
													value={singleFormData.supportemail}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Company Url
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													name="companyurl"
													value={singleFormData.companyurl}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Company Short
												</Label>
												<textarea
													className="form-control"
													id="validationCustom01"
													type="text"
													name="shortdescription"
													value={singleFormData.shortdescription}
													onChange={(e)=>onChangeFormData(e)}
												></textarea>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Support Mobile
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="supportemobile"
													value={singleFormData.supportemobile}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
								
										
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Address
												</Label>
												<textarea
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="address"
													value={singleFormData.address}
													onChange={(e)=>onChangeFormData(e)}
												></textarea>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 City
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="city"
													value={singleFormData.city}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Districts
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="districts"
													value={singleFormData.districts}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 State
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="state"
													value={singleFormData.state}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Country
												</Label>
												
													<select
												className="form-control"
												id="validationCustom0"
											
												required=""
												name="country"
												onChange={(e)=>onChangeFormData(e)}
												value={singleFormData.country}
											>
												<option  className="form-control">Default</option>
												{
													country.map((d,i)=>(
														<option value={`${d.value}`} className="form-control">{d.label}</option>
													))
												}
												
												
											</select>
										
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Pincode
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="number"
													
													name="pincode"
													value={singleFormData.pincode}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Face Book
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="facebook"
													value={singleFormData.facebook}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Google Id
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="googleid"
													value={singleFormData.googleid}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Social Link
												</Label>
												<MultiSelect
													options={ sociallink || []}
													selected={singleFormData.sociallink || []}
													onSelectedChanged={(e)=>selectDropDown(e,'sociallink')}
												/>
											</FormGroup>	
										</Col>
										<Col sm={4} md={4} lg={4}>
										<FormGroup>
											<Label className="col-form-label">
												Document 
											</Label>
											<div className="form-group" id="colors">
												<MultiSelect
													options={ document || []}
													selected={singleFormData.document || []}
													onSelectedChanged={(e)=>selectDropDown(e,'document')}
												/>
											</div>
										</FormGroup>	
										</Col>
									</Row>
												
									
								</TabPanel>
								<TabPanel>
								<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span> Logo
												</Label>
												<Input
													className="form-control"
													
													type="file"
												
													name="file"
													
													onChange={(e)=>onChangeFileUplaodData(e,'logo')}
												/>
												{
													singleFormData['logo'] != undefined && singleFormData['logo'] != null  ?
													<div  style={{ position: 'relative' }}>
                        <img src={`${urlPath.imageurl}/${singleFormData['documenttype']}/${singleFormData['logo']}`}  style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            // onClick={() => handleImageDelete(index)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
                        >
                            X
                        </button>
                    </div>
													
													:''
												}
												
											</FormGroup>
											<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span> Fav Icon
												</Label>
												<Input
													className="form-control"
													
													type="file"
													
													onChange={(e)=>onChangeFileUplaodData(e,'favicon')}
												/>
													{
													singleFormData['favicon'] != undefined && singleFormData['favicon'] != null  ?
													<div  style={{ position: 'relative' }}>
                        <img src={`${urlPath.imageurl}/${singleFormData['documenttype']}/${singleFormData['favicon']}`}  style={{ width: '100%', height: '100px' }} />
                        <button
                            className="deleteButton"
                            // onClick={() => handleImageDelete(index)}
                            style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
                        >
                            X
                        </button>
                    </div>
													
													:''
												}
											</FormGroup>
								</TabPanel>
								<TabPanel>
								<Row>
									<Col sm={4}>
										Document
									</Col>
									<Col sm={4}>
										Number
									</Col>
									<Col sm={4}>
										file
									</Col>
								</Row>
                             {
								singleFormData.documentlist !== undefined && singleFormData.documentlist !== null?
								singleFormData.documentlist.map((d,l)=>(
									<Row>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
							
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={d.name}
												disabled={true}
												onChange={(e)=>socialLinkChange(e,'documentlist',l)}
												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
								
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="number"
													value={d.number}
													onChange={(e)=>socialLinkChange(e,'documentlist',l)}

												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
						
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
											     
													name="file"
													onChange={(e)=>socialLinkChange(e,'documentlist',l)}
												/>
											</FormGroup>
										</Col>
									</Row>
								))
								
								
								:''
							 }
							</TabPanel>
								<TabPanel>
							<Row>
									<Col sm={4}>
										Social Link
									</Col>
									<Col sm={4}>
										URL
									</Col>
									
								</Row>
							{
								singleFormData.sociallist !== undefined && singleFormData.sociallist !== null?
								singleFormData.sociallist.map((d,l)=>(
									<Row>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
							
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={d.name}
													onChange={(e)=>socialLinkChange(e,'sociallist',l)}
												/>
											</FormGroup>
										</Col>
										<Col sm={4} md={4} lg={4}>
								<FormGroup>
								
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="url"
													value={d.url}
													onChange={(e)=>socialLinkChange(e,'sociallist',l)}
												/>
											</FormGroup>
										</Col>
										
									</Row>
								))
								
								
								:''
							 }
							</TabPanel>
							<div className="pull-right">
					<input type="submit"  color="primary" value={'Edit'} className="btn btn-primary"/>
					
				</div>
								</Form>
							
							
					
					
					
				</Tabs>
				
								</>
				}	
				
			</div>
				</CardBody>
			</Card>
		</Container>
	</Fragment>
	
	);
};

export default CompanyProfile;
