import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import daterangepicker from 'daterangepicker'
export class DatePickerComponent extends Component{
    constructor(props){
        super(props)
        this.state={
            start:'',
            end:''
        }
    }
    DatePicker=() =>{
        $('#daterange').daterangepicker({
            startDate: this.props.start,
            endDate:this.props.end,
            ranges: {
               'Today': [moment(), moment()],
               'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               'Last 7 Days': [moment().subtract(6, 'days'), moment()],
               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
               'This Month': [moment().startOf('month'), moment().endOf('month')],
               'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, (start, end)=>{
            this.props.startdateEnddate(start,end)
            $('#daterange span').html(start.format('DD/MM/YYYY HH:mm:ss') + ' - ' + end.format('DD/MM/YYYY HH:mm:ss'));
           
           });
          
          
    
    }
    render(){
       console.log(this.props.start)
      
        return(
<div id="daterange" class="selectbox pull-right" onClick={this.DatePicker()}>
             
             <i class="fa fa-calendar"></i>
        <span>{`${this.props.start.format('DD/MM/YYYY HH:mm:ss') + ' - ' + this.props.end.format('DD/MM/YYYY HH:mm:ss')}`}</span>
         </div>
        )
    }
    
}
export default DatePickerComponent;