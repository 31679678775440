import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link ,useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form,CardFooter } from "reactstrap";
import urlPath from "../../url";
import { getCommonStatus,getSingleCommon,getCommon,postCommon} from "../../data/common";
import Cookies from 'js-cookie';
import DeletedDatatable from "../common/deleteddatatable";
import { UiLoading } from "../ui/uiloading";
const Tarsh = () => {
	const [searchData,setSearchData] = useState('')
	const [data, setData] = useState([])
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [idList,setIdList]= useState('')
	const [operationType, setOperationType] = useState('Card')
	const [isLoading, setIsLoading] = useState(true)
	const [message,setMessage]= useState('')
	const [singleData, setSingleData] = useState({ })
	const [findValue,setFindValue] = useState('')
	const [urls,setUrls] = useState()
	const [dataList,setDataList] =useState([
		{
		title:'Category',
		name:'Category',
		description:'',
		url:`${urlPath.category}`,
		total:0,
		active:0,
		inactive:0,
		linkAdd:`${process.env.PUBLIC_URL}/category/deleted-list`,
		isLoading:true,
		addButton:true,
		findValue:'name'
		},
		{
			title:'Brand',
			name:'Brand',
			description:'',
			url:`${urlPath.brand}`,
			total:0,
			active:0,
			inactive:0,
			linkAdd:`${process.env.PUBLIC_URL}/brand/deleted-list`,
			isLoading:true,
			addButton:true,
			},
			{
				title:'Product',
				name:'Product',
				description:'',
				url:`${urlPath.product}`,
				total:0,
				active:0,
				inactive:0,
				linkAdd:`${process.env.PUBLIC_URL}/product/deleted-list`,
				isLoading:true,
				addButton:true,
				},	
				{
					title:'Attribute',
					name:'Attribute',
					description:'',
					url:`${urlPath.varient}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/varient/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Attribute List',
					name:'Attribute List',
					description:'',
					url:`${urlPath.varientlist}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/varient-list/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Order',
					name:'Order',
					description:'',
					url:`${urlPath.order}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/orders/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Transection',
					name:'Transection',
					description:'',
					url:`${urlPath.order}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/transactions/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Invoice',
					name:'Invoice',
					description:'',
					url:`${urlPath.order}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/invoice/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Sales Attribute',
					name:'Sales Attribute',
					description:'',
					url:`${urlPath.salesAtrribute}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/sales-attribute/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Description Type',
					name:'Description Type',
					description:'',
					url:`${urlPath.descriptiontype}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/description-type/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Different Type Product',
					name:'Different Type Product',
					description:'',
					url:`${urlPath.producttype}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/different-type-product/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Product Description',
					name:'Product Description',
					description:'',
					url:`${urlPath.productDescription}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/product-description/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Page List',
					name:'Page List',
					description:'',
					url:`${urlPath.pagelist}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/type-of-page/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Page Content',
					name:'Page Content',
					description:'',
					url:`${urlPath.pageDescription}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/type-of-page-content/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Page Banner',
					name:'Page Banner',
					description:'',
					url:`${urlPath.pagebanner}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/page-banner/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Social / Service',
					name:'Social / Service',
					description:'',
					url:`${urlPath.typeoflink}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/social-link/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Document Type',
					name:'Document Type',
					description:'',
					url:`${urlPath.documenttype}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/document-type/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Department Type',
					name:'Department Type',
					description:'',
					url:`${urlPath.deparmenttype}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/type-of-department/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Designation Type',
					name:'Designation Type',
					description:'',
					url:`${urlPath.designationtype}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/type-of-designation/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Media',
					name:'Media',
					description:'',
					url:`${urlPath.media}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/media/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Header Menu',
					name:'Header Menu',
					description:'',
					url:`${urlPath.headermenu}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/header-menu/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Footer Menu',
					name:'Footer Menu',
					description:'',
					url:`${urlPath.footermenu}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/footer-menu/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Users',
					name:'Users',
					description:'',
					url:`${urlPath.user}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/users/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				
				{
					title:'Cart',
					name:'Cart',
					description:'',
					url:`${urlPath.cart}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/cart/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Wish List',
					name:'Wish List',
					description:'',
					url:`${urlPath.wishlist}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/wish-list/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Country',
					name:'Country',
					description:'',
					url:`${urlPath.country}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/country/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Pincode',
					name:'Pincode',
					description:'',
					url:`${urlPath.pincode}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/pincode/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Payment Gateway',
					name:'Payment Gateway',
					description:'',
					url:`${urlPath.paymentgateway}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/payment-gateway/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				{
					title:'Tex Type',
					name:'Tex Type',
					description:'',
					url:`${urlPath.textype}`,
					total:0,
					active:0,
					inactive:0,
					linkAdd:`${process.env.PUBLIC_URL}/textype/deleted-list`,
					isLoading:true,
					addButton:true,
				},
				
	])
	const showTable =async(url,find)=>{
		setIsLoading(true)
		setOperationType('Table')
		setFindValue(find)
		setUrls(url)
		getCommon(`${url}`,'DeletedTable',Cookies.get('jwt')).then(result => {
			console.log(result)
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					name: result.data[i].name,
					Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,

				}
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})
		// setIsLoading(false)
	}
	const showForm = (Type,id ,name,message) => {
		const dataDeleted ={}
		setMessage(`${name} data ${Type} Permanently...`)
		alert(message)
		dataDeleted._id =id;
		dataDeleted.operation=Type
		postCommon(urls,dataDeleted,Cookies.get('jwt')).then(async result => {
			if (result.success) {
				setMessage(`Successfully ${name} data ${Type} Permanently`)
				setOperationType('Table')
				setIsLoading(true)
				getCommon(urls,'DeletedTable',Cookies.get('jwt')).then(result => {
					setIsLoading(false)
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
		
						}
						data.push(d)
						setData([...data])
					}
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})
	}
	useEffect(()=>{
		
		for(let i=0; i <dataList.length; i++){
			getCommonStatus(dataList[i].url,'deleted-list',cookiesValue).then(result=>{
					dataList[i].total= result.total
					dataList[i].active= result.active
					dataList[i].inactive= result.inactive
					dataList[i].isLoading=false
					setDataList([...dataList])
				
				
			})
		}
		setIsLoading(false)
	   },[])
	   let navigate = useNavigate(); 
	const urlRedirect = (path) =>{ 
		 
				navigate(path);	
	}
	const urlRedirectId=(path,id)=>{
		navigate(`${path}/${id}`);	
	}
	const showSearch=(type)=>{
	
		setSearchData(type)
	}
	const surchSubmie=async(e)=>{
		e.preventDefault()
		console.log(singleData)
		singleData['searchtype'] =findValue
		singleData['operation']='Search'
		await postCommon(urls,singleData,Cookies.get('jwt')).then(result=>{
			console.log(result)
		}).catch(err=>{
			console.log(err)
		})
	}
	const submiteFormData=(e)=>{
		const {name,value} = e.target;
		singleData[name] = value;
		setSingleData(singleData)

	}
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`Tarsh`} parent="Dashboard" operationType={operationType} sublink={''}/>
				{/* <!-- Container-fluid starts--> */}
				<Card>
									<CardBody>
						{
						operationType == "Card"?
							<Container fluid={true}>
							<Row>
							{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
												
														<div className="product-buttons text-center">
											<Button type="button" color="primary" disabled={true} onClick={()=>urlRedirect(d.linkTable,)}>
											   <span><img src="/table.png" style={{width:'25px'}} />({!d.isLoading?`${d.total}` :<p>loading...</p>})</span>
											   </Button>
											   <Button type="button" color="success" style={{backgroundColor:'green',}} disabled={true} onClick={()=>urlRedirect('All')}>
											   <span><img src="/active.png" style={{width:'25px'}} />({!d.isLoading?(d.active) :<p>loading...</p>})</span>
											   </Button>
											   <Button type="button" color="primary" onClick={()=>showTable(d.url,d.findValue)}>
											   <span><img src="/inactive.png" style={{width:'25px'}} />({!d.isLoading?d.inactive :<p>loading...</p>})</span>
											   </Button>
										   
										
											   
									   
											   </div>
											</CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
							</Row>
						</Container>
							:
							<Container fluid={true}>
								
									<Row>
									{
													searchData == 'search'?
													<Form onSubmit={(e)=>surchSubmie(e)}>
												<div className="row">
												
												<Col md="10" sm="10" >
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> {findValue}
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name={`${findValue}`}
												value={singleData[findValue]}
												onChange={(e)=>submiteFormData(e)}
												
											/>
										</FormGroup>
										
												</Col>
											<Col md="2" sm="2" style={{paddingTop:"25px"}}>
											
											<FormGroup className="mb-0" >

											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
											</div>
												</Form>
													
													:''
												}
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
											</div>
											<div className="clearfix"></div>
											<h6 style={{textAlign:'center',color:'red'}}>{message}</h6>
												<div id="basicScenario" className="product-physical">
													{
														isLoading?
														<UiLoading />
														:
													<DeletedDatatable
														myData={data}
														multiSelectOption={false}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id,name) => showForm(type,id,name,`This name is ${name},Deleted Data Permanently`)}
													
														restoreData={(type,id,name) => showForm(type,id,name,`This name is ${name},Restore Data Permanently`)}
														edit={true}
														deletes={false}
														view={true}
														copy={false}
														findValue={findValue}
													/>
													}
												</div>
									</Row>
									
						
							</Container>
						}
					
					</CardBody>
								</Card>

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Tarsh;

