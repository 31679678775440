import React, { Fragment, useState } from "react";
import MultiSelect from '@khanacademy/react-multi-select';
import ImageUploader from "../../common/imageuploader";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
const FormDatas = ({saveData,isLoading,categoryList,formSubmite,singleData,attribute,operationType,description,mainCategory,childCategory}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [selectCategory,setSelectCategory] = useState([])
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const selectDropDown=(e,type)=>{
		console.log('hh')
		console.log(e)
		const d =[...singleData.category]
		if(d.length < e.length){

			const da = []
			for(let i = 0; i < e.length; i++){
				if(!d.some(l=> `${l}` == `${e[i]}`)){
					console.log(e[i])
					da.push(e[i])
				}
			}
			console.log(da)
			setSelectCategory(da)
			singleFormData[type] =e
			singleData.pagedescription=true
			setSingleFormData({...singleFormData})
		}else{
			alert('You Cant Not Remove')
		}
		
	}

	return (
	
			<Container fluid={true}>
				
				<Form onSubmit={(e)=>formSubmite(e,selectCategory)}>
						<Row className="product-adding">
						<Col xl="12">
						<FormGroup>
									<Label className="col-form-label">
										<span>*</span> Category
									</Label>
									<div className="form-group" id="colors">
										<MultiSelect
											options={ categoryList || []}
											selected={singleFormData.category !== undefined && singleFormData.category !== null ? singleFormData.category : []}
											onSelectedChanged={(e)=>selectDropDown(e,'category')}
										/>
									</div>
								</FormGroup>
								<FormGroup className="mb-0">
										<div className="product-buttons text-center">
										{
												saveData ?
												<Button  className="btn btn-primary" disabled={true}>{`${operationType}...`}</Button>
												:	<input  className="btn btn-primary" type="submit" name="Submit" value={`${operationType}`}/>
											}
										</div>
								</FormGroup>
						</Col>
						</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
