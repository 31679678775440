
import React, { useState,Fragment } from 'react';
import SortableTree from 'react-sortable-tree';
import { removeNodeAtPath } from 'react-sortable-tree';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    CardHeader
  } from "reactstrap"
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import Breadcrumb from "../../common/breadcrumb";
import { useEffect } from 'react';

import { UiLoading } from "../../ui/uiloading";
import urlPath from '../../../url';
import { getCommon,postCommon } from '../../../data/common';
import FormDatas from './form';
import Cookies from 'js-cookie';
import { getDropDown } from '../../../data/catlog/product';
export  const  CategoryRlation =()=> {
  const [treeData,setTreeData]=useState([])
  const [isLoading,setIsLoading]=useState(true)
  const [saveData,setSaveData] = useState(false)
  const [removeData,setRemoveData] = useState([])
  const [operationType,setoperationType] = useState('Tree')
  const [categoryList,setcategoryList] = useState([])
  const [categorySelect,setcategorySelect] = useState([])
  const [singleData, setSingleData] = useState({ status: true,photo:[],category:[]})
  useEffect(() => {
    const dk =[]
    getCommon(urlPath.categoryRelation).then(result=>{
      console.log(result)
      const treeDatas =[]
      for(let i=0;i<result.data.length;i++){
		console.log(result.data[i])
          if(result.data[i].titles !== undefined && result.data[i].titles !== null){
            dk.push(result.data[i].titles._id)
          }
          const d={
            title:result.data[i].titles !== undefined && result.data[i].titles !== null ? result.data[i].titles.name :'',
            titles:result.data[i].titles !== undefined && result.data[i].titles !== null ? result.data[i].titles._id :'',
            children:[],
            expanded: true,
            ...result.data[i].titles
          }
          if(result.data[i].children !== 0){
            for(let j=0;j<result.data[i].children.length;j++){
              if(result.data[i].children[j].titles !== undefined && result.data[i].children[j].titles !== null){
                dk.push(result.data[i].children[j].titles._id)
              }
              d.children.push({
                title:result.data[i].children[j].titles !== undefined && result.data[i].children[j].titles !== null ? result.data[i].children[j].titles.name :'',
                titles:result.data[i].children[j].titles !== undefined &&result.data[i].children[j].titles !== null ? result.data[i].children[j].titles._id :'',
                children:[],
                expanded: true,
                ...result.data[i].children[j].titles
              })
              if(result.data[i].children[j].children !== 0){

                for(let k=0;k<result.data[i].children[j].children.length;k++){
                  if(result.data[i].children[j].children[k].titles !== undefined && result.data[i].children[j].children[k].titles !== null){
                    dk.push(result.data[i].children[j].children[k].titles._id)
                  }
                  d.children[j].children.push({
                    title:result.data[i].children[j].children[k].titles !== undefined && result.data[i].children[j].children[k].titles !== null ? result.data[i].children[j].children[k].titles.name :'',
                    titles:result.data[i].children[j].children[k].titles && result.data[i].children[j].children[k].titles !== null ? result.data[i].children[j].children[k].titles._id :'',
                    children:[],
                    expanded: true,
                    ...result.data[i].children[j].children[k].titles
                  })
                  if(result.data[i].children[j].children[k].children !== 0){
                    for(let l=0;l<result.data[i].children[j].children[k].children.length;l++){
                      if(result.data[i].children[j].children[k].children[l].titles !== undefined && result.data[i].children[j].children[k].children[l].titles !== null){
                        dk.push(result.data[i].children[j].children[k].children[l].titles._id)
                      }
                      d.children[j].children[k].children.push({
                        title:result.data[i].children[j].children[k].children[l].titles !== undefined && result.data[i].children[j].children[k].children[l].titles !== null ? result.data[i].children[j].children[k].children[l].titles.name :'',
                        titles:result.data[i].children[j].children[k].children[l].titles !== undefined && result.data[i].children[j].children[k].children[l].titles !== null ? result.data[i].children[j].children[k].children[l].titles._id :'',
                        children:[],
                        expanded: true,
                        ...result.data[i].children[j].children[k].children[l].titles
                      })
                      if(result.data[i].children[j].children[k].children[l].children !== 0){
                        for(let m=0;m<result.data[i].children[j].children[k].children[l].children.length;m++){
                          if(result.data[i].children[j].children[k].children[l].children[m].titles !== undefined && result.data[i].children[j].children[k].children[l].children[m].titles !== null){
                            dk.push(result.data[i].children[j].children[k].children[l].children[m].titles._id)
                          }
                          d.children[j].children[k].children[l].children.push({
                            title:result.data[i].children[j].children[k].children[l].children[m].titles !== undefined && result.data[i].children[j].children[k].children[l].children[m].titles !== null ? result.data[i].children[j].children[k].children[l].children[m].titles.name :'',
                            titles:result.data[i].children[j].children[k].children[l].children[m].titles !== undefined && result.data[i].children[j].children[k].children[l].children[m].titles !== null ? result.data[i].children[j].children[k].children[l].children[m].titles._id :'',
                            children:[],
                            expanded: true,
                            ...result.data[i].children[j].children[k].children[l].children[m].titles
                          })
                        }
                        
                      }
                    }
                   
                  }
                }
              
              }
            
            }
        
              
          }
         
          treeDatas.push(d)
          
      }
      setcategorySelect(dk)
      singleData.category=dk
      setSingleData(singleData)
      setTreeData(treeDatas)
      setIsLoading(false)
       
    })
  },[])
  const treeDataValue=(tree)=>{
   
    setTreeData([...tree])
  }
     
  const removeNode =(e,rowInfo)=>{
  
    let {node, treeIndex, path} = rowInfo;
    console.log(rowInfo)
    setRemoveData(rowInfo.node)
    
  const remove_data = removeNodeAtPath({
      treeData: treeData,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })

   setTreeData(remove_data)
   
  //  postCommon(urlPath.categoryRelation,{
  //  data:rowInfo.node,
  //  operation:'Delete',
  //  _id:rowInfo.node._id,
  //  treeData:remove_data
  // } ).then(result=>{
  //   console.log(result)
  // })
  }
    const submitHandeler=async(type)=>{
      setoperationType(type)
      if(type == 'Add'){
        setIsLoading(true)
        await getDropDown("Varient").then(result=>{
          if(result.success){
            setcategoryList(result.category)
           
            setIsLoading(false)
           
          }
        })
      }else if(type == 'Tree'){
        setSaveData(true)
        postCommon(urlPath.categoryRelation,{treeData:treeData,remove:removeData}).then(result=>{
          console.log(result)
          if(result.success){
            window.location.reload()
            alert('Updated Data Successfully')
            setSaveData(false)
          }
        })
      }
    
    }
    const formSubmite=(e, selectCategory)=>{
      e.preventDefault()
      console.log(selectCategory)
      setSaveData(true)
      postCommon(urlPath.categoryRelation,{data:selectCategory,operation:'Add'}).then(result=>{
        console.log(result)
        if(result.success){
          alert(`Data ${operationType} Successfully`)
          window.location.reload()
          setSaveData(false)
        } 
      })
    }
    return (
      <Fragment>
      <Breadcrumb title={`Category Relation`} parent="Dashboard" operationType={'Tree'} sublink={'category-relation'}/>
      
          <Container fluid={true}>
            <Row>
              <Col sm="12">
 <Card><CardHeader>
                      {/* <h5> Header Menu </h5> */}
                      {
                        operationType !== 'Add' ?
                        <div className="btn-popup pull-right" style={{margin:0,textAlign:'end'}}>
                        {saveData?
                          <i
                            class="fa fa-save btn btn-primary"
                         
                            onClick={() => submitHandeler()}
                          
                          >Loading...</i>
                          :
                          <i
                          class="fa fa-save btn btn-primary"
                        
                          onClick={() => submitHandeler('Tree')}
                        ></i>
                        }
                         
                          <i
                          class="fa fa-plus btn btn-primary"
                          style={{marginRight:"20px"}}
                          onClick={() => submitHandeler('Add')}
                        ></i>
                        
                          </div>
                        :''
                      }
                     
                    </CardHeader>
                      <CardBody>
                      
                        <div className="clearfix"></div>
                        {
                          isLoading ?
                          <UiLoading/>
                          :operationType == 'Tree'?
                          <>
                         
                          <div style={{ height:  parseInt(treeData.length * 400) }}>
                          <div style={{ height: parseInt(treeData.length * 400) }}>

                          <SortableTree
                          treeData={treeData}
                          onChange={treeData => treeDataValue(treeData)}
                          isVirtualized={false}
                          generateNodeProps={(rowInfo ,index) => ({
                            buttons: [
                              <button label='Delete'
                              onClick={(event) => removeNode(event,rowInfo)}>Remove</button>
                              
                                      
                                    
                                      
                                   ],
                                })}
                        />
                        </div>
                        </div>
                        </>
                        :<FormDatas  saveData={saveData} categoryList={categoryList} isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, selectCategory) => formSubmite(e, selectCategory,operationType)} />
                        }
                       
                      </CardBody>
                    </Card>
               

              </Col>
            </Row>
          </Container>
     </Fragment>
    );
  
}

export default CategoryRlation;