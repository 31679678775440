import urlPath from '../../url';
export const getProduct=async(table,status)=>{
    return fetch(`${urlPath.product}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getSingleProduct=async(id)=>{
    return fetch(`${urlPath.product}/${id}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getDropDown=async(slug)=>{
    if(slug !== undefined && slug !== null){
        return fetch(`${urlPath.product}/dropdown/${slug}`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }else{
        return fetch(`${urlPath.product}/dropdown`,{
            method:'GET'
        })
        .then(res=>res.json())
        .then(result=>{
            return result
        }).catch(err=>{
            return err;
        })
    }
  
}
export const postProduct=async(data,table,operation)=>{
    return fetch(`${urlPath.product}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}