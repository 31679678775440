import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link ,useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, CardFooter } from "reactstrap";
import urlPath from "../../url";
import Cookies from 'js-cookie';
import { getCommonStatus } from "../../data/common";
const Marketing = () => {
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const [dataList,setDataList] =useState([
		{
			title:'Cart',
			name:'Cart',
			url:`${urlPath.cart}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/cart/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/cart/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/cart/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/cart/Add`,
			isLoading:true,
			addButton:false,
		},
		{
			title:'Wish List',
			name:'Wish List',
			url:`${urlPath.wishlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/wish-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/wish-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/wish-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/wish-list/Add`,
			isLoading:true,
			addButton:false,
		},

	])

	let navigate = useNavigate(); 
	const urlRedirect = (path) =>{ 
		
				navigate(path);	
			
			
   }
   useEffect(()=>{
		
	for(let i=0; i <dataList.length; i++){
		getCommonStatus(dataList[i].url,'count',cookiesValue).then(result=>{
		
				dataList[i].total= result.total
				dataList[i].active= result.active
				dataList[i].inactive= result.inactive
				dataList[i].isLoading=false
				console.log(dataList[i])
				setDataList([...dataList])
			
			
		})
	}
	
   },[])
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`Marketing`} parent="Dashboard" operationType={operationType} sublink={'marketing'}/>
				{/* <!-- Container-fluid starts--> */}
				<Container fluid={true}>
							<Row>
							{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
													{
														!d.addButton ?
														<div className="product-buttons text-center">
												<Button type="button" color="primary" onClick={()=>urlRedirect(d.linkTable)}>
											   <span><img src="/table.png" style={{width:'25px'}} />({!d.isLoading?`${d.total}` :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="success" style={{backgroundColor:'green'}} onClick={()=>urlRedirect(d.linkActive)}>
											   <span><img src="/active.png" style={{width:'25px'}} />({!d.isLoading?(d.active) :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkInactive)}>
											   <span><img src="/inactive.png" style={{width:'25px'}} />({!d.isLoading?d.inactive :<p>isLoading...</p>})</span>
											   </Button>
										   
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
											   
									   
											   </div>
														
														:	<div className="product-buttons text-center">
														
												   
													   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
													   
											   
													   </div>
													}
					  							
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Marketing;

