import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import TabsetPage from "./tabset-page";

const ProductDescriptionForm = ({formSubmite,saveData,singleData,data}) => {
	console.log(singleData)
	return (
		<TabsetPage formSubmite={(e,data)=>formSubmite(e,data)} saveData={saveData}  singleData={singleData} title={singleData.name} description={singleData.descriptions} data={data}/>
	);
};

export default ProductDescriptionForm;
