import React, { Fragment,useState,useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";

import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row,Button, } from "reactstrap";
import { getProductDescription,getSingleProductDescription,postProductDescription, } from '../../../data/page/productDescription'
import FormDatas from '../pagelist/form'
import { UiLoading } from "../../ui/uiloading";
import ProductDescriptionForm from "./product_description";
import { getCommon, getSingleCommon } from "../../../data/common";
import urlPath from "../../../url";
import Cookies from "js-cookie";
import PageStructureTable from "../../common/pagestructuretable";
const ProductDescription= () => {
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true, })
	const [dataList,setDataList] = useState([])
	const showForm = (Type,id) => {
		console.log('h')
		setOperationType(Type)
		setIsLoading(true)
		
			getSingleCommon(id,urlPath.productDescription,Cookies.get('jwt')).then(result => {
				console.log(result)
				setDataList(result.data)
				setSingleData(result.product)
				setIsLoading(false)
			})

			setOperationType('Edit')

	}
	useEffect(() => {
		getCommon(urlPath.productDescription,'Table',Cookies.get('jwt')).then(result => {
			console.log( result.data)
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					name: result.data[i].name,
					description:result.data[i].descriptions!== undefined && result.data[i].descriptions !== null && result.data[i].descriptions.length !==0?  <Button className="btn btn-primary" onClick={()=>showForm(operationType,result.data[i]._id)}>Description</Button>:'No Description'

				}
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})

	}, [])
	
	const formSubmite =async (e, singleFormData) => {
		e.preventDefault()
		console.log(singleFormData)
		setOperationType('Table')
		singleFormData.operation=operationType
		setIsLoading(true)
	await postProductDescription(singleFormData).then(async result => {
			if (result.success) {
				await getProductDescription().then(result => {
					console.log(result)
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
							
						}
						data.push(d)
						setData([...data])
					}
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
				// setIsLoading(false)
			} else {

			}
		})

	}
	
	return (
		<Fragment>
				<Breadcrumb title={operationType=='Table' ?'Product List' : 'Product List'} parent="Dashboard" operationType={operationType} sublink={`product`} mainlink={'dashboard'} type={true}/>
				{/* <Breadcrumb title={`${singleData.name}`} parent="Dashboard" operationType={operationType} sublink={'product'} /> */}
				{/* <!-- Container-fluid starts--> */}
				{
					operationType == 'Table' ?
						<Container fluid={true}>
							<Row>
								<Col sm="12">

									{
										isLoading ?
										<UiLoading />
										
											: <Card><CardHeader>
												<h5> Product {operationType}</h5>
											</CardHeader>
												<CardBody>
												
													<div className="clearfix"></div>
													<div id="basicScenario" className="product-physical">
														<PageStructureTable
															myData={data}
															multiSelectOption={false}
															pageSize={10}
															pagination={true}
															class="-striped -highlight"
															deleteData={(type,id) => showForm(type,id)}
															viewData={(type,id) => showForm(type,id)}
															editData={(type,id) => showForm(type,id)}
															edit={true}
															deletes={true}
															view={true}
															copy={false}
														/>
													</div>
												</CardBody>
											</Card>
									}

								</Col>
							</Row>
						</Container>
						: 
								isLoading ?
								<UiLoading />
								:
							<ProductDescriptionForm   data={dataList}  singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData)} />
							
				}

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
	);
};

export default ProductDescription;

