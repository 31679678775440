import React, { useState } from "react";
import {  Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile } from '../../data/catlog/fileupload';
import TextEditor from '../ui/texteditor'
import ImageUploader from "../common/imageuploader";
const FormDatas = ({saveData,formSubmite,singleData,operationType}) => {
	console.log(singleData)

	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [showAddMoreButton, setShowAddMoreButton] = useState(singleData.photo.length !== 0 ? true:false);
    const [imageLoading,setImageLoading] = useState(false)
	
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const onEditorChange = (evt) => {
		console.log(singleFormData)
		singleFormData['description'] =evt.editor.getData()
		setSingleFormData({...singleFormData})
		
	  };
	  const handleImageSelect = async(e,uploadtype) => {
        e.preventDefault();
        setShowAddMoreButton(true);
        setImageLoading(true)
		let photoList =[]
		if(singleFormData.photo !== undefined && singleFormData.photo !== null){
			 photoList =[...singleFormData.photo]
		}else{

		}
       
        for(let i =0; i < e.target.files.length; i++ ){
            console.log(i)
            console.log( e.target.files[i])
            const data = new FormData();
            data.append('type', uploadtype);
            data.append('image', e.target.files[i]);
         await fileUpload(data).then((result) => {
              if (result.success) {
              
                photoList.push({uploadtype:uploadtype,image:result.photo})
              }
            });
        }
		singleFormData.photo=[...photoList]
        setSingleFormData(singleFormData)
        setImageLoading(false)

    };
	const handleImageDelete=async(e,j,photo,type,uploadtype)=>{
		e.preventDefault()
		const single ={...singleFormData}
	
		await deletedUploadFile(photo,uploadtype).then((result) => {
			if (result.success) {
					single[type].splice(j,1)
					
					 setSingleFormData({...single})
			}else{
				single[type].splice(j,1)
					
					 setSingleFormData({...single})
			}
		  });
	}
	return (
		<Container fluid={true}>
			
				{
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="6">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Title
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<Label className="col-form-label">
											Category Image
											</Label>
										<ImageUploader handleImageDelete={(e,i,phots)=>handleImageDelete(e,i,phots,'photo',singleFormData.uploadtype)} showAddMoreButton={showAddMoreButton} imageLoading={imageLoading} handleImageSelect={(e)=>handleImageSelect(e,'category')} image ={singleFormData.photo !== undefined && singleFormData.photo !== null ?singleFormData.photo:[]} />
									
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
										
									</div>
								</CardBody>
							</Card>
							
						</Col>
						<Col xl="6">
							
							<Card>
							
								<CardHeader>
									<h5>Meta Data</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0"> Meta Title</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='metatitle'
												type="text"
												
												value={singleFormData.metatitle}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Meta Key Word</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='metakeyword'
												value={singleFormData.metakeyword}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Meta Description</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='metadescription'
												value={singleFormData.metadescription}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Slug</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="slug"
												value={singleFormData.slug}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										
									
									</div>
								</CardBody>
							</Card>
						</Col>
						<TextEditor title="Category Description" description={singleFormData.description !== undefined && singleFormData.description !== null ?singleFormData.description:''} name="category_description" onEditorChange={(e)=>onEditorChange(e)} datadisable={false}/>
					</Row>
					<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											{
												saveData?
												<button className="btn btn-primary" value={`${operationType}`}>{`${operationType}...`}</button>
												:<input type="submit" name="Submit" className="btn btn-primary" value={`${operationType}`}/>
											}
											
											
											</div>
										</FormGroup>
					</Form>
				}
				
			</Container>
		
	);
};

export default FormDatas;
