import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TabsetPage from "./tabset-page";

const Forms = ({operationType,menuItem,singleData}) => {
	return (
		
				
						<TabsetPage operationType={operationType} menuItem={menuItem} singleData={singleData}/>
					
		
	);
};

export default Forms;
