import {Home,Box,DollarSign,Tag,Clipboard,Camera,AlignLeft,UserPlus,BarChart,Settings,Trash,MinusCircle} from 'react-feather';
export const MENUITEMS = [
    {
        path: '/dashboard', value:"Dashboard", label:'Dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',
    },
    // {
    //     path: '/add-employee', title: 'Add Employee', icon: UserPlus, type: 'link', active: false,
    // },
    {
        path: '/employee',value:"Employee", label:'Employee', title: 'Employee', icon: UserPlus, type: 'link', active: false,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',
    },
    // {
    //     path: '/vendors',title: 'Vendors', icon: Users, type: 'link', active: false, 
    // },
    {
        path: '/catlog',value:"Catlog", label:'Catlog', title: 'Catlog', icon: Box, type: 'sub', active: false,
        
        children: [
            {
                 path: '/category', title: 'Category', type: 'link',active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
            },
           
            {
                path: '/brand', title: 'Brand', type: 'link',active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
           },
           {
                path: '/product', title: 'Product', type: 'link',active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
            },
            {
                path: '/varient', title: 'Attribute', type: 'link',active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
            },
            {
                path: '/varient-list', title: 'Attribute List', type: 'link',active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
            },
            // {
            //     title: 'Attribute', type: 'sub', active: false, children: [
            //         { path: '/varient', title: 'Attribute', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            //         { path: '/varient-list', title: 'Attribute List', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            //     ]
            // },
          
        ]
    },
    {
        path: '/sales',value:"Sales", label:'Sales', title: 'Sales', icon: DollarSign, type: 'sub', active: false, 
        children: [
            { path: '/orders', title: 'Orders', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/transactions', title: 'Transactions', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/invoice', title: 'Invoice', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/sales-attribute', title: 'Sales Attribute', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
        ]
    },
    {
        path: '/#',value:"Shiprocket", label:'Shiprocket', title: 'Shiprocket', icon: DollarSign, type: 'sub', active: false, 
        children: [
            // { path: '/authentication', title: 'Authentication', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/shiprocket-couriers', title: 'Couriers', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/shiprocket-order', title: 'Orders', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/shiprocket-return', title: 'Return Orders', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/shiprocket-shipments', title: 'Shipments', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/shiprocket-invoice', title: 'Labels | Manifests | Invoice', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/shiprocket-ndr', title: 'NDR', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/invoice', title: 'Tracking', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/shiprocket-address', title: 'Pickup Addresses', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/invoice', title: 'Account', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/invoice', title: 'Products', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/invoice', title: 'Listings', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/invoice', title: 'Inventory', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/sales-attribute', title: 'Statement Details', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
        ]
    },
    // {
    //     title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
    // },
    {
        title: 'Coupons', value:"Coupons", label:'Coupons', path: '/coupons', icon: Tag, type: 'sub', active: false,
        children: [
            { path: '/orders', title: 'Product Coupon', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/transactions', title: 'Sales Coupon', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
           
        ]
    },
    {
        path: '/cms',value:"CMS", label:'CMS', title: 'CMS', icon: Clipboard , type: 'sub', active: false, 
        children: [
         
            { path: '/different-type-product', title: 'Product Type', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/type-of-page', title: 'Page List', type: 'link' , active: true,},
            { path: '/seo', title: 'SEO', type: 'link' , active: true,},
            { path: '/page-banner', title: 'Page Banner', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: `/category-relation`, title: 'Category Relation', type: 'link',active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
         
            ]
    },
    {
        title: 'Media',value:"Media", label:'Media', path: '/media', icon: Camera, type: 'link', active: false,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
    },
    {
        path: '/menus',value:"Menus", label:'Menus', title: 'Menus', icon: AlignLeft, type: 'sub', active: false, 
        children: [
            { path: '/header-menu', title: 'Header Menu', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            // { path: `${menuUrl}/header-menu-structure/${Cookies.get('jwt')}`, title: 'Header Menu Structure', type: 'link', active: true, },
            { path: `/header-menu-structure `, title: 'Header Menu Structure', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/footer-menu', title: 'Footer Menu', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: `/footer-menu-structrue`, title: 'Footer Menu Structure', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            
        ]
    },
    {
        title: 'Customer',value:"Customer", label:'Customer', path:'/users', icon: UserPlus, type: 'link', active: false,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  
    },
    {
        path:'/marketing',value:"Marketing", label:'Marketing', title: 'Marketing', icon: UserPlus, type: 'sub', active: false, 
        children: [
            { path: '/reView', title: 'ReView', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/cart', title: 'Cart', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/wish-list', title: 'Wish List', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/social-media-post', title: 'Social Media Post', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/email-campaign', title: 'Email Campaign', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/sms-campaign', title: 'Sms Campaign', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/social-media-configuration', title: 'Social Configuration', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
        ]
    },
   
    {
        title: 'Reports', value:"Reports", label:'Reports', path:'/report', icon: BarChart, type: 'sub',active: false,
        children: [
            { path: '/live-view', title: 'Live View', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/product-report', title: 'Product Report', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/category-report', title: 'Category Report', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/brand-report', title: 'Brand Report', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
           
       
        ]
    },
    {
        title: 'Settings',value:"Settings", label:'Settings', path:'/setting', icon: Settings, type: 'sub', active: false,
        children: [
            { path: '/company-profile', title: 'Company Profile', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/user-profile', title: 'User Profile', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/country', title: 'Operation Country', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/pincode', title: 'Pincode Setup', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
            { path: '/payment-gateway', title: 'Payment Gateway', type: 'link' , active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', },
        ]
    },
    
    {
        title: 'Miscellaneous',value:"Miscellaneous", label:'Miscellaneous', path:'/setting', icon: MinusCircle, type: 'sub', active: false,
        children: [
            { path: '/text-type', title: 'Text Type', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/description-type', title: 'Description Type', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/social-link', title: 'Social', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/service-link', title: 'Service', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/document-type', title: 'Document Type', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/type-of-department', title: 'Type Of Department', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/type-of-designation', title: 'Type Of Designation', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            { path: '/type-of-employee', title: 'Type Of Employee', type: 'link', active: true,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'',  },
            // { path: '/company-profile', title: 'Company Profile', type: 'link', active: true, },
            // { path: '/user-profile', title: 'User Profile', type: 'link', active: true, },
            // { path: '/country', title: 'Operation Country', type: 'link', active: true, },
            // { path: '/pincode', title: 'Pincode Setup', type: 'link' , active: true,},
            // { path: '/payment-gateway', title: 'Payment Gateway', type: 'link' , active: true,},
        ]
    },
    {
        title: 'Tarsh',value:"Tarsh", label:'Tarsh', path:'/Tarsh', icon: Trash, type: 'link', active: false,Read:'',Write:'',Delete:'',View:'',Tarsh:'',Module:'', 
       
    },
   
]
