import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link ,useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Button,CardFooter } from "reactstrap";
import urlPath from "../../url";
import { getCommonStatus } from "../../data/common";
import Cookies from 'js-cookie';
const Setting = () => {
	console.log(Cookies.get('jwt'))
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [idList,setIdList]= useState('')
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const [dataList,setDataList] =useState([
		{
		title:'Company Profile',
		name:'Company Profile',
		description:'',
		url:`${urlPath.companyprofile}`,
		total:0,
		active:0,
		inactive:0,
		linkAdd:`${process.env.PUBLIC_URL}/company-profile/Edit`,
		isLoading:true,
		addButton:true,
		},
		{
			title:'User Profile',
			name:'User Profile',
			url:`${urlPath.userprofile}`,
			total:0,
			active:0,
			inactive:0,
			linkAdd:`${process.env.PUBLIC_URL}/user-profile/Edit`,
			isLoading:true,
			addButton:true,
		},
		{
			title:'Operation Country',
			name:'Operation Country',
			url:`${urlPath.country}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/product/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/product/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/product/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/product/Add`,
			isLoading:true,
			addButton:false,
		},
		{
			title:'Pincode Setup',
			name:'Pincode Setup',
			url:`${urlPath.pincode}`,
			total:0,
	     	active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient/Add`,
			isLoading:true,
			addButton:false,
		},
		{
			title:'Payment Gateway',
			name:'Payment Gateway',
			url:`${urlPath.paymentgateway}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
			isLoading:true,
			addButton:false,
		},

	])
	useEffect(()=>{
		
		for(let i=0; i <dataList.length; i++){
			getCommonStatus(dataList[i].url,'count',cookiesValue).then(result=>{
				console.log(result)
				if(dataList[i].addButton){
					if(Object.keys(result.data).length != 0){
						setIdList(result.data._id)
					}
					
				}else{
					dataList[i].total= result.total
					dataList[i].active= result.active
					dataList[i].inactive= result.inactive
					dataList[i].isLoading=false
					console.log(dataList[i])
					setDataList([...dataList])
				}
				
			})
		}
		
	   },[])
	   let navigate = useNavigate(); 
	const urlRedirect = (path) =>{ 
		 
				navigate(path);	
	}
	const urlRedirectId=(path,id)=>{
		navigate(`${path}/${id}`);	
	}
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`Setting`} parent="Dashboard" operationType={operationType} sublink={'setting'}/>
				{/* <!-- Container-fluid starts--> */}
		
						<Container fluid={true}>
							<Row>
							{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
													{
														!d.addButton ?
														<div className="product-buttons text-center">
											<Button type="button" color="primary" onClick={()=>urlRedirect(d.linkTable)}>
											   <span><img src="/table.png" style={{width:'25px'}} />({!d.isLoading?`${d.total}` :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="success" style={{backgroundColor:'green'}} onClick={()=>urlRedirect(d.linkActive)}>
											   <span><img src="/active.png" style={{width:'25px'}} />({!d.isLoading?(d.active) :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkInactive)}>
											   <span><img src="/inactive.png" style={{width:'25px'}} />({!d.isLoading?d.inactive :<p>isLoading...</p>})</span>
											   </Button>
										   
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
											   
									   
											   </div>
														
														:	<div className="product-buttons text-center">
														
												   
													   <Button type="button" color="primary" onClick={()=>urlRedirectId(d.linkAdd,idList)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
													   
											   
													   </div>
													}
					  							
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Setting;

