import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
// import data from "../../../assets/data/pro_list";
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
// import { Card, CardBody, CardHeader, Col, Container, Row, Button } from "reactstrap";
import FormDatas from './form'
import { getCommon,getSingleCommon,postCommon } from '../../../data/common'
import { UiLoading } from "../../ui/uiloading";
import urlPath,{numberOfpage} from "../../../url";
import { getDropDown } from "../../../data/catlog/product";
import Cookies from "js-cookie";
const SEO = () => {
	const [checkedValue,setCheckedValues] = useState([])
	const [saveData,setSaveData] = useState(false)
	const [searchValue,setSearchValue] = useState('')
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const [operationType, setOperationType] = useState('Table')
	const [attribute,setAttribute] = useState([])
	const [productlist,setProductList]= useState()
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[],productlist:[],mobilebanner:[] })
	const [searchData,setSearchData] = useState('')
	const [searchDataList,setsearchDataList] = useState({})
	const showForm = (Type,id) => {
		let url='';
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
		getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.seo,Cookies.get('jwt')).then(async result => {
				if(result.data.linktype== 'brand'){
					url=`${urlPath.brand}`
				}else  if(result.data.linktype== 'category'){
					url=`${urlPath.category}`
				 }else if(result.data.linktype== 'page'){
					url=`${urlPath.pagelist}`
				 }else if(result.data.linktype== 'type of product'){
					url=`${urlPath.producttype}`
				 }
				 await getCommon(url,'Table',Cookies.get('jwt')).then(result=>{
					if(result.success){
					
						setAttribute(result.data)
					
					}else{
						setAttribute([])
						
					}
				})
				setSingleData(result.data)
				setIsLoading(false)
			})

		} else {
			setIsLoading(false)
		}

	}
	useEffect(() => {
	
		getCommon(urlPath.seo,'Table',Cookies.get('jwt')).then(result => {
		
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					'Script Type': result.data[i].typeofscript,
					Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,

				}
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})
	
		
	


}, [])
	
const formSubmite = (e, singleFormData) => {
	e.preventDefault()
	setIsLoading(true)
	singleFormData.operation=operationType
	console.log(singleFormData)
	postCommon(urlPath.seo,singleFormData,Cookies.get('jwt')).then(async result => {
		if (result.success) {
	await getCommon(urlPath.seo,'Table',Cookies.get('jwt')).then(result => {
				
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Script Type': result.data[i].typeofscript,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
	
					}
					data.push(d)
					setData([...data])
				}
				setOperationType('Table')
				setIsLoading(false)
			}).catch(err => {
				console.log(err)
			})
		} else {

		}
	})

}
const showSearch=(type)=>{
	
	setSearchData(type)
}
const searchForm=(e)=>{
	const {name,value} = e.target;
	const single ={...searchDataList}
	single[name] = value
	setsearchDataList(single)
	// getSearchData
	setSearchValue(value)
 }
 const searchForamData=(e)=>{
	e.preventDefault();
	if(Object.keys(searchDataList).length != 0){

		searchDataList.operation='Search'
		setIsLoading(true)
		postCommon(urlPath.seo,searchDataList,Cookies.get('jwt')).then(result=>{
			const data = []
			if(result.data.length != 0){
	
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						'Script Type': result.data[i].typeofscript,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>Active</p> : <p style={{ color: '#ff0000' }}>In Active</p>,
	
					}
					data.push(d)
					setData([...data])
					setOperationType('Table')
					setIsLoading(false)
				}
			}else{
				setData([])
				setOperationType('Table')
				setIsLoading(false)
			}
						
		})
	}else{
		alert("Please select Status ")
	}
	setSingleData({})
 }
 const handlePageChange = (page) => {
	
	setCurrentPage(page)
	   
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
	console.log(newRowsPerPage)
	setRowsPerPage(newRowsPerPage)
	
};
	return (
		<Fragment>

		{/* <!-- Container-fluid starts--> */}
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Page Banner`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Page Banner`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'page-banner'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
											<CardBody>
												{
													searchData == 'search'?
													<Form>
												<div className="row">
												<Col md="5">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select" >
											<option value="">--Select--</option>
											<option value="1">eBooks</option>
											<option value="2">Graphic Design</option>
											<option value="3">3D Impact</option>
											<option value="4">Application</option>
											<option value="5">Websites</option>
										</select>
									</FormGroup>
												</Col>
												<Col md="5" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Country
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												
												
											/>
										</FormGroup>
										
												</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="10" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													
													
												/>
											</FormGroup>
											
													</Col>
												<Col sm="2" style={{paddingTop:"30px"}}>
												
												<FormGroup className="mb-0">
												<div className="product-buttons text-center">
												<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
													
												</div>
											</FormGroup>
											
												</Col>
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right">
												<FormGroup >
												<select className="form-select" name="status" value={searchDataList.status} onChange={(e)=>searchForm(e)} >
													<option value="">--Multiple Deleted--</option>
													<option value="Deleted">Deleted</option>
													<option value="Duplicate">Duplicate</option>
													<option value="Select Export">Select Export</option>
												</select>
												</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												
													<Button
														type="button"
														color="primary"
														onClick={() => showForm('Add')}
													>
													<i class="fa fa-plus" aria-hidden="true"></i>
													</Button>
								
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
													{
														isLoading ?
														<UiLoading/>
														:	<Datatable
														myData={data}
														multiSelectOption={false}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={true}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRowsPerPageChange={handleRowsPerPageChange}
														checkedValues={checkedValue}
													/>
													}
												
												</div>
												</CardBody>
						</Card>
											
										:isLoading ?
										<UiLoading/>
										:	<FormDatas isLoading={isLoading} attribute={attribute} productlist={productlist} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData,operationType)} />
										
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
			</Fragment>
	);
};

export default SEO;

