import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Form, Input, Label } from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import TextEditor from "../ui/texteditor";
import ImageUploader from "../common/imageuploader";

const TabsetPage = ({title,description}) => {
	const [value, setValue] = useState('')
	console.log(description)
	const onChange = (e) =>{
		setValue(e)
	}
	const handleImageSelect = async(e) => {
        // e.preventDefault();
        // setShowAddMoreButton(true);
        // setImageLoading(true)
		// let photoList =[]
		// if(singleFormData.photo !== undefined && singleFormData.photo !== null){
		// 	 photoList =[...singleFormData.photo]
		// }else{

		// }
       
        // for(let i =0; i < e.target.files.length; i++ ){
        //     console.log(i)
        //     console.log( e.target.files[i])
        //     const data = new FormData();
        //     data.append('type', 'products');
        //     data.append('image', e.target.files[i]);
        //  await fileUpload(data).then((result) => {
        //       if (result.success) {
              
        //         photoList.push(result.photo)
        //       }
        //     });
        // }
		// singleFormData.photo=[...photoList]
        // setSingleFormData(singleFormData)
        // setImageLoading(false)

    };
	const onEditorChange = (evt) => {
		// console.log(singleFormData)
		// singleFormData['description'] =evt.editor.getData()
		// setSingleFormData({...singleFormData})
		
	  };

	return (
		<Fragment>
			<div>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						
								<Tab className="nav-link" >Employee Details</Tab>
								<Tab className="nav-link" >Authentication</Tab>
								<Tab className="nav-link" >Document</Tab>
								<Tab className="nav-link" >Module Assign</Tab>
								<Tab className="nav-link" >Social Link</Tab>

						
					
					</TabList>
					
							<TabPanel>
							<Form className="needs-validation">
							
								
							</Form>
						</TabPanel>
					
					
					
				</Tabs>
				<div className="pull-right">
					<Button type="button" color="primary">
						Save
					</Button>
				</div>
			</div>
		</Fragment>
	);
};

export default TabsetPage;
