import React, { Fragment, useState } from "react";

import MyDropzone from "../common/dropzone";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../data/catlog/fileupload';
import TextEditor from '../ui/texteditor';
import singleImage from "../common/singleimage";
import ImageUploader from "../common/imageuploader";
import DatatableCommmon from "../common/datatableCommon";
import { postCommon } from "../../data/common";
const FormDatas = ({formSubmite,saveData,orderStatus,orderListStatus,isLoading,singleData,operationType,pickLocation}) => {
	console.log(pickLocation)
	console.log(singleData)
	const [value, setValue] = useState('')
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [orderStatusList,setOrderStatusList] = useState([...orderStatus])
	const [orderListStatusList,setorderListStatusList] = useState([...orderListStatus])

	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		if(name == 'currentlist'){
			singleFormData['currentdescription']=value
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const onEditorChange = (evt) => {
		console.log(singleFormData)
		singleFormData['description'] =evt.editor.getData()
		setSingleFormData({...singleFormData})
		
	  };

const megaMenu=(e)=>{
	const {name,value} = e.target;
	const single ={...singleFormData }
	const ds =[...orderStatusList]
	single['currentstatus'] =ds.find(d=> `${d._id}` == `${value}`)
	setorderListStatusList([...ds.find(d=> `${d._id}` == `${value}`).statusmesage])
	single['currentdescription']=''
	setSingleFormData({...single})
}

	return (
		<Container fluid={true}>
			
				{
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="6">
							
							<Card>
								<CardHeader>
									<h5>Order Details</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
									<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Name
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="order"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Email
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="email"
												value={singleFormData.email}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Mobile
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="phone"
												value={singleFormData.phone}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Order
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="order"
												value={singleFormData.order}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span>Invoice
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="invoice"
												value={singleFormData.invoice}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
									
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Product Amount
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="totalamount"
												value={singleFormData.amount}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Shipping Ammount
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="shipping"
												value={singleFormData.shipping}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Tax Ammount
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="tax"
												value={singleFormData.tax}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span></span> Total Paid Amount
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="tax"
												value={singleFormData.totalamount}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Pick Up Location
										</Label>
										<select className="form-select" name='picklocation' value={singleFormData.picklocation}	onChange={(e)=>onChangeFormData(e)}>
											<option value="">--Select--</option>
											{
											  pickLocation != undefined && pickLocation != null && pickLocation.map(d=>(
												<option value={`${d.label}`}>{d.label}</option>
											  ))
											}
										</select>
									</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Shiprocket
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="shiprocket"
														value={true}
														checked={`${singleFormData.shiprocket}` == `true` || singleFormData.shiprocket== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="shiprocket"
														value={false}
														checked={`${singleFormData.shiprocket}` == `false` || singleFormData.shiprocket== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
										
									</div>
								</CardBody>
							</Card>
							
						</Col>
						
						<Col xl="6">
							
							<Card>
							
								<CardHeader>
									<h5>Transection Details</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
										<FormGroup>
											<Label className="col-form-label pt-0">Mode Payment</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='modeofpayment'
												type="text"
												disabled
												value={singleFormData.modeofpayment}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Payment Status</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="paymentstatus"
												value={singleFormData.paymentstatus}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Ref. Number</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="refid"
												value={singleFormData.refid}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Transection Id</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="slug"
												value={singleFormData.slug}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
										
										<FormGroup>
											<Label className="col-form-label">Mode Payment</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="slug"
												value={singleFormData.slug}
												onChange={(e)=>onChangeFormData(e)}
												disabled
											/>
										</FormGroup>
									
										<div class="form-group">
											<label>
												Order Status
												<span>:</span>
											</label>
											<select
												className="form-control"
												id="validationCustom0"
												type="text"
												required=""
												name="currentstatus"
												onChange={(e)=>megaMenu(e)}
												value={singleFormData.currentstatus._id}
											>
												<option  className="form-control">Default</option>
												{
													orderStatusList.map((d,i)=>(
														
														<option value={`${d._id}`} className="form-control">{d.name}</option>
														
													))
												}
											
												
											</select>
											</div>
											<div class="form-group">
											<label>
												Order Description
												<span>:</span>
											</label>
											<select
												className="form-control"
												id="validationCustom0"
												type="text"
												required=""
												name="currentlist"
												onChange={(e)=>onChangeFormData(e)}
												value={singleFormData.currentlist}
											>
												<option  className="form-control">Default</option>
												{
													orderListStatusList.map((d,i)=>(
													
														<option value={`${d}`} className="form-control">{d}</option>
														
													))
												}
											
												
											</select>
											</div>
											<div class="form-group">
											<label>
												Description
												<span>:</span>
											</label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name="currentdescription"
												value={singleFormData.currentdescription}
												onChange={(e)=>onChangeFormData(e)}
												
											></textarea>
											</div>
											{/* <FormGroup>
											<Label className="col-form-label">
												<span>*</span> Add Current Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="activestatus"
														value={'Yes'}
														checked={`${singleFormData.activestatus}` == 'Yes'  ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Yes
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="activestatus"
														value={'No'}
														checked={`${singleFormData.activestatus}` == 'No'  ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													No
												</Label>
											</div>
										</FormGroup> */}
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="6">
							
							<Card>
							
								<CardHeader>
									<h5>Billing Address</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
									<FormGroup>
											<Label className="col-form-label">Billing Name</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='billingname'
												type="text"
												
												value={singleFormData.billingname}
												onChange={(e)=>onChangeFormData(e)}
											/>
											
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Billing Mobile</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='billingmobile'
												type="text"
												
												value={singleFormData.billingmobile}
												onChange={(e)=>onChangeFormData(e)}
											/>
											
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">Address</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												name='billingaddress'
												type="text"
												
												value={singleFormData.billingaddress}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Landmark</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='billinglandmark'
												type="text"
												
												value={singleFormData.billinglandmark}
												onChange={(e)=>onChangeFormData(e)}
											/>
											
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">City</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='billingcity'
												type="text"
												
												value={singleFormData.billingcity}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">State</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='billingstate'
												type="text"
												
												value={singleFormData.billingstate}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										
										<FormGroup>
											<Label className="col-form-label">Pincode</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='billingpincode'
												type="text"
												
												value={singleFormData.billingpincode}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="6">
							
							<Card>
							
								<CardHeader>
									<h5>Shipping Address</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
									<FormGroup>
											<Label className="col-form-label">Shipping Name</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='shippingname'
												type="text"
												
												value={singleFormData.shippingname}
												onChange={(e)=>onChangeFormData(e)}
											/>
											
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Shipping Mobile</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='shippingmobile'
												type="text"
												
												value={singleFormData.shippingmobile}
												onChange={(e)=>onChangeFormData(e)}
											/>
											
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">Address</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												name='shippingaddress'
												type="text"
												
												value={singleFormData.shippingaddress}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">Landmark</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='shippinglandmark'
												type="text"
												
												value={singleFormData.shippinglandmark}
												onChange={(e)=>onChangeFormData(e)}
											/>
											
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">City</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='shippingcity'
												type="text"
												
												value={singleFormData.shippingcity}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">State</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='shippingstate'
												type="text"
												
												value={singleFormData.shippingstate}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										
										<FormGroup>
											<Label className="col-form-label">Pincode</Label>
											<Input
												className="form-control"
												id="validationCustom05"
												name='shippingpincode'
												type="text"
												
												value={singleFormData.shippingpincode}
												onChange={(e)=>onChangeFormData(e)}
											/>
										</FormGroup>
										
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="12">
							
							<Card>
							
								<CardHeader>
									<h5>Order Status</h5>
								</CardHeader>
								<CardBody>
									<div className="digital-add needs-validation">
									
										<FormGroup>
											<Label className="col-form-label">Order History</Label>
											
										</FormGroup>
										{
											singleFormData.ordersatauslist !== undefined && singleFormData.ordersatauslist !== null ?
											<DatatableCommmon
											multiSelectOption={false}
											myData={singleFormData.ordersatauslist}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											edit={true}
											deletes={true}
											view={true}
											checkedValues={[]}
											/>
											
											:''
										}
										
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="12">
							
							<Card>
							
								<CardHeader>
									<h5>Product Details</h5>
								</CardHeader>
								<CardBody className="order-datatable">
								{
											singleFormData.productitemlist !== undefined && singleFormData.productitemlist !== null ?
											<DatatableCommmon
											multiSelectOption={false}
											myData={singleFormData.productitemlist}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											action={false}
											/>
											
											:''
										}
									
								
								</CardBody>
							</Card>
						</Col>
					</Row>
					<FormGroup className="mb-0">
											<div className="product-buttons text-center">
												{
													saveData?
													<button  className="btn btn-primary" >{`${operationType}...`}</button>
													:
													<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`} />
												}
											
											
											</div>
										</FormGroup>
					</Form>
				}
				
			</Container>
		
	);
};

export default FormDatas;
