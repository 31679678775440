import React, { Fragment,useState,useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import { getProductDescription,getSingleProductDescription,postProductDescription, } from '../../../data/page/productDescription'
import FormDatas from '../pagelist/form'
import { UiLoading } from "../../ui/uiloading";
// import from "";
import { getCommon, getSingleCommon, postCommon } from "../../../data/common";
import urlPath, { numberOfpage } from "../../../url";
import Cookies from "js-cookie";
import PageStructureTable from "../../common/pagestructuretable";
import PageDescriptionForm from "./page_description";
const ProductDescription= () => {
	const [checkedValue,setCheckedValues] = useState([])
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const [searchData,setSearchData] = useState('')
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true, })
	const [dataList,setDataList] = useState([])
	const showForm = (Type,id) => {
		console.log('h')
		
		setIsLoading(true)
		
			getSingleCommon(id,urlPath.pageDescription,Cookies.get('jwt')).then(result => {
				console.log(result)
				setDataList(result.data)
				setSingleData(result.product)
				setOperationType('Edit')
				setIsLoading(false)
			})

			setOperationType('Edit')

	}
	useEffect(() => {
		getCommon(urlPath.pageDescription,'Table',Cookies.get('jwt')).then(result => {
			
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					name: result.data[i].name,
					description:result.data[i].description!== undefined && result.data[i].description !== null && result.data[i].description.length !==0?  <Button className="btn btn-primary" onClick={()=>showForm(operationType,result.data[i]._id)}>Description</Button>:'No Description'

				}
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})

	}, [])
	
	const formSubmite =async (e, singleFormData) => {
		e.preventDefault()
		console.log(singleFormData)
		setOperationType('Table')
		singleFormData.operation=operationType
		setIsLoading(true)
	await postCommon(urlPath.pageDescription, singleFormData,Cookies.get('jwt')).then(async result => {
			if (result.success) {
				await getCommon(urlPath.pageDescription,'Table',Cookies.get('jwt')).then(result => {
					console.log(result)
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
							
						}
						data.push(d)
						setData([...data])
					}
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
				// setIsLoading(false)
			} else {

			}
		})

	}
	const showSearch=(type)=>{
	
		setSearchData(type)
	}
	const handlePageChange = (page) => {
	
		setCurrentPage(page)
		   
	  };
	  const handleRowsPerPageChange = (newRowsPerPage) => {
		console.log(newRowsPerPage)
		setRowsPerPage(newRowsPerPage)
		
	};
	const handleRemoveRow =(e,id)=>{
		console.log(e,id)
		console.log(e.target.checked)
		const checkData =[...checkedValue]
		if(e.target.checked){
			checkData.push(id)
			setCheckedValues([...checkData])
		}else{
		 const d=checkData.filter(df=> console.log(`${df}` != `${id}`))
	
		setCheckedValues([...d])
		}
	
		
	}
	return (
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Page Description`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Page Description`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'type-of-page-content'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									isLoading ?
									<UiLoading/>
									:operationType == 'Table' ?
						<Card>
											<CardBody>
												{
													searchData == 'search'?
													<Form>
												<div className="row">
												<Col md="5">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select" >
											<option value="">--Select--</option>
											<option value="1">eBooks</option>
											<option value="2">Graphic Design</option>
											<option value="3">3D Impact</option>
											<option value="4">Application</option>
											<option value="5">Websites</option>
										</select>
									</FormGroup>
												</Col>
												<Col md="5" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Country
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												
												
											/>
										</FormGroup>
										
												</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="10" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													
													
												/>
											</FormGroup>
											
													</Col>
												<Col sm="2" style={{paddingTop:"30px"}}>
												
												<FormGroup className="mb-0">
												<div className="product-buttons text-center">
												<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
													
												</div>
											</FormGroup>
											
												</Col>
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right" style={{textAlign:'right'}}>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('import')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-upload" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('export')}
														style={{'marginRight':'10px'}}
													>
													<i class="fa fa-download" aria-hidden="true"></i>
													</Button>
													<Button
														type="button"
														color="primary"
														onClick={() => showForm('Add')}
													>
													<i class="fa fa-plus" aria-hidden="true"></i>
													</Button>
								
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
													<Datatable
														myData={data}
														multiSelectOption={false}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={false}
														deletes={false}
														view={false}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRowsPerPageChange={handleRowsPerPageChange}
														handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
														checkedValues={checkedValue}
													/>
												</div>
												</CardBody>
						</Card>
											
										:<PageDescriptionForm   data={dataList}  singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData)} />
										
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
	);
};

export default ProductDescription;

