import React, { Fragment, useState } from "react";

import MyDropzone from "../../common/dropzone";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { fileUpload, deletedUploadFile,urlFileUpload } from '../../../data/catlog/fileupload';
import TextEditor from '../../ui/texteditor';
import ImageUploader from "../../common/imageuploader_demo";
const FormDatas = ({isLoading,formSubmite,singleData,attribute,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	// const [attributeData,setattributeData]= useState(attribute)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		if(name == 'name'){
			singleFormData[name] =value
			singleFormData['metatitle']=value
			singleFormData['slug'] =value.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
		}
		if(name == 'order'){
			if(!/^[0-9]+$/.test(value)){
				singleFormData[name] =value
			
			  }else{
				alert('Enter Numeric')
			  }
		}
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}

	
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
								<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
									<div className="form-group row">
										<Label className="col-form-label pt-0">Varient List</Label>
										<div className="col-md-12">
											<select className="form-select" name='varient' value={singleFormData.varient} onChange={(e)=>onChangeFormData(e)}>
											<option value="">--Select--</option>
												{
													attribute.map(d=>(
														<option value={`${d.value}`}>{d.label}</option>
													))
												}
											</select>
										</div>
									</div>
									{
										operationType == 'Edit' || operationType == 'Deleted' || operationType == 'View'?
										<>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Title
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required={true}
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Order
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required={true}
												name="order"
												value={singleFormData.order}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										</>
										
										:<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> Title
										</Label>
										<textarea
											className="form-control"
											id="validationCustom01"
											type="text"
											required=""
											name="list"
											value={singleFormData.list}
											onChange={(e)=>onChangeFormData(e)}
										></textarea>
									</FormGroup>
									}
										
										
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
										
									</div>
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
