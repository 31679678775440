import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Button,Spinner } from "reactstrap";
export const UiLoading=()=>(
    <Card style={{ height: '900px',textAlign:'center' }}>
    <CardBody>
    <Button variant="dark" disabled>
<Spinner
as="span"
variant="light"
size="sm"
role="status"
aria-hidden="true"
animation="border"/>
Loading...
</Button>
    </CardBody>
</Card>
)