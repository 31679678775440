import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import moment from 'moment';
// import data from "../../assets/data/orders";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
// import urlPath from "../../url";
import urlPath, { numberOfpage } from "../../url";
import Cookies from "js-cookie";
import { UiLoading } from "../ui/uiloading";
import FormDatas from "./form";
import { getCommon, postCommon, getSingleCommon, getSearchData, getExportFile,postOrderExportFile } from '../../data/common'
import ImageUploads from "../ui/imageupload";
import DatePickerComponent from "../ui/datepicker";
const Invoice = () => {
	const [checkedValue,setCheckedValues] = useState([])
	const [searchDataLists,setsearchDataList] = useState({})
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const [searchData,setSearchData] = useState('')
	const [message,setMessage] = useState({message:'',color:''})
	const [searchDataList,setSearchDataList] = useState({})
	const [saveData,setSaveData] = useState(false)
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[],ordersatauslist:[],productitemlist:[],activestatus:'No' })
	
	const [orderStatus,setOrderStatus]= useState([])
	const [orderListStatus,setOrderListStatus]= useState([])
	const [orderSearch,setOrderSearch] = useState('')
	const [statusLoading,setStatusLoading] = useState(false)
	const [modePayment,setModePayment] = useState(false)
	useEffect(()=>{
		
	getCommon(urlPath.order,'Table',Cookies.get('jwt')).then(async result => {
		await getCommon(urlPath.salesAtrribute,'Table',Cookies.get('jwt')).then(results=>{
			setOrderStatus(results.data)
			})
			await getCommon(urlPath.paymentgateway,'Table',Cookies.get('jwt')).then(results=>{
				setModePayment(results.data)
				})
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					'Invoice': result.data[i].invoice,
					 "Date" :result.data[i].orderplacedate,
					"Shipping":result.data[i].shipping,
					"Amount":result.data[i].amount, 	
					"Tax":result.data[i].tax,
					"Total":result.data[i].totalamount,	
					"Order_Status":<p style={{color:`${result.data[i].currentstatus.colortype}`}}>{result.data[i].currentlist}</p> 
			     }
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})
	},[])
	const showForm = (Type,id) => {
	
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.order,Cookies.get('jwt')).then(async result => {
				const da =[]
				
				if(result.data.orderstatus !== undefined && result.data.orderstatus !== null && result.data.orderstatus.length !==0){
					for(let i =0; i < result.data.orderstatus.length;i++){
						const d={
							'Sl': `${parseInt(i + 1)}`,
							 'Statuse':<p style={{color:`${result.data.orderstatus[i].title.colortype}`}}>{result.data.orderstatus[i].title.name}</p>,
							 'Stage':<p style={{color:`${result.data.orderstatus[i].title.colortype}`}}>{result.data.orderstatus[i].list}</p>,
							 'Desc':<p style={{color:`${result.data.orderstatus[i].title.colortype}`}}>{result.data.orderstatus[i].description}</p>,
							 'Date':result.data.orderstatus[i].statusdata
						}
						da.push(d)
					
					}
					result.data.ordersatauslist=da
					
				}
				if(result.data.productitem !== undefined && result.data.productitem !== null && result.data.productitem.length !==0){
					const photos=[]
					const das =[]
					for(let i =0; i < result.data.productitem.length;i++){
						for(let j =0 ; j < result.data.productitem[i].photo.length;j++ ){
							photos.push(`${urlPath.imageurl}/products/${result.data.productitem[i].photo[j]}`)
						}
						const d={
							'Sl': `${parseInt(i + 1)}`,
							image: <ImageUploads alt="" data={photos} style={{width:50,height:50}}/>,
							 'Name':result.data.productitem[i].name,
							 'qty':result.data.productitem[i].qty,
							 'varient':result.data.productitem[i].varientlist.length !== 0?result.data.productitem[i].varientlist.map((k,l)=>(
								<span>{k.title}:{k.value}</span>)) :'No Varient',
							 'mrp':result.data.productitem[i].amount,
							
						}
						das.push(d)
					
					}
					result.data.productitemlist=das
				}
				setSingleData({...result.data,activestatus:'No'})
				await getCommon(urlPath.salesAtrribute,'Table',Cookies.get('jwt')).then(results=>{
					setOrderStatus(results.data)
					if(results.data.some(d=> d._id ==  result.data.currentstatus._id)){
						
						setOrderListStatus(results.data.find(d=> d._id ==  result.data.currentstatus._id).statusmesage)
					}
					
				})
				// await getCommon(urlPath.varientlist,'Table',Cookies.get('jwt')).then(resul=>{
				// 	setOrderListStatus(resul.data)
				// })
				setIsLoading(false)
			})

		} else {
			setIsLoading(false)
		}

	}
	
	const formSubmite = (e, singleFormData) => {
		e.preventDefault()
		singleFormData.operation=operationType
		delete singleFormData.orderstatus

		setSaveData(true)
		postCommon(urlPath.order,singleFormData,Cookies.get('jwt')).then(async result => {
			setSaveData(false)
			alert(`Data ${operationType} Successfully`)
			setIsLoading(true)
			if (result.success) {
				setOperationType('Table')
				getCommon(urlPath.order,'Table',Cookies.get('jwt')).then(result => {
			
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							'Order_id': result.data[i].order,
							"Status":result.data[i].currentstatus !== undefined && result.data[i].currentstatus !== null ? <p style={{color:`${result.data[i].currentstatus.colortype}`}}>{result.data[i].currentstatus.name}</p> :'',
							"Method":result.data[i].modeofpayment,
							"Order_Status":<p style={{color:`${result.data[i].currentstatus.colortype}`}}>{result.data[i].currentlist}</p> 
						 }
						data.push(d)
					}
					setData([...data])
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})

	}
	const showSearch=async(type)=>{
		setSearchData(type)
		if(type == 'export'){
			setSearchData(type)
			message.message='Exporting Data ...'
			message.color='red'
		setMessage({...message})
		if(Object.keys(searchDataList).length == 0){
			await postOrderExportFile(urlPath.order,'export-file',Cookies.get('jwt'),searchData).then(result=>{
				console.log(result)
				message.message='Exporting Data Successfully'
				message.color='green'	
				setMessage({...message})
				window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
			})
		}else{
			setSearchData('export-search')
			message.message='Exporting Data ...'
			message.color='red'
			await postOrderExportFile(urlPath.order,'export-search',Cookies.get('jwt'),searchDataList).then(result=>{
				message.message='Exporting Data Successfully'
					message.color='green'
					setMessage(message)
				window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
			})
		}
		
		}
		
	}
	const orderFindSearch=async (e)=>{
		const {name,value} = e.target;
		setOrderSearch(value)
		const search ={}
		search[value] =''
		setSearchDataList({...search})
		// if(value == "Order Status"){
		// 	setStatusLoading(true)

		// 	await getCommon(urlPath.salesAtrribute,'Table',Cookies.get('jwt')).then(results=>{
		// 		console.log(results)
		// 		setOrderStatus(results.data)
		// 		setStatusLoading(false)
		// 	})
		// }
	}
	const onChangeForm=(e)=>{
		const {name,value} = e.target;
		const single ={...searchDataList}
		single[name]=value
		if(name == 'currentstatus'){

		setOrderListStatus(orderStatus.find(d=> d._id == value) != undefined && orderStatus.find(d=> d._id == value) != null ? orderStatus.find(d=> d._id == value).statusmesage:[])	
		}
		setSearchDataList(single)
	}
	const searchOrder=(e)=>{
		e.preventDefault()
		message.message='Searching Data ...'
		message.color='red'
		setMessage(message)
		setIsLoading(true)
		setData([])
		getSearchData(urlPath.order,Cookies.get('jwt'),searchDataList).then(result=>{
			const data = []
			message.message='Searching Data Successfully'
			message.color='green'
			setMessage(message)
			setOperationType('Table')
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					'Order_id': result.data[i].order,
					"Status":result.data[i].currentstatus !== undefined && result.data[i].currentstatus !== null ? <p style={{color:`${result.data[i].currentstatus.colortype}`}}>{result.data[i].currentstatus.name}</p> :'',
					"Method":result.data[i].modeofpayment,
					"Order_Status":<p style={{color:`${result.data[i].currentstatus.colortype}`}}>{result.data[i].currentlist}</p> 
			     }
				data.push(d)
			}
			console.log(data)
			setData([...data])
			setIsLoading(false)
		})
	}
	const handlePageChange = (page) => {
	
		setCurrentPage(page)
		   
	  };
	  const handleRemoveRow =(e,id)=>{
		console.log(e,id)
		console.log(e.target.checked)
		const checkData =[...checkedValue]
		if(e.target.checked){
			checkData.push(id)
			setCheckedValues([...checkData])
		}else{
	     const d=checkData.filter(df=> console.log(`${df}` != `${id}`))
	
		setCheckedValues([...d])
		}
	
		
	}
	const searchForm=(e)=>{
		const {name,value} = e.target;
		const single ={...searchDataList}
		single[name] = value
		setsearchDataList(single)
		// getSearchData
	 }
	return (
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Invoice`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Invoice`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'invoice'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
						
											<CardBody>
												{
													searchData == 'search' || Object.keys(searchDataList).length != 0 ?
													<Form onSubmit={(e)=>searchOrder(e)}>
												<div className="row">
												<Col md="3" sm="12">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select" onChange={(e)=>orderFindSearch(e)} >
											<option value="">--Select--</option>
											<option value="order">Order Id</option>
											<option value="name">Customer Name</option>
											<option value="email">Email</option>
											<option value="phone">Phone</option>
										</select>
									</FormGroup>
												</Col>
												
													
												<Col md="3" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Mode Of Payment
												</Label>
												
													<select className="form-select" name="modeofpayment" value={searchDataList['modeofpayment'] != undefined && searchDataList['modeofpayment'] != null ?searchDataList['modeofpayment'] :'' } onChange={(e)=>onChangeForm(e)} >
													<option value="">--Select--</option>
													{
														modePayment.map((d,i)=>(
															<option value={`${d.method}`}>{d.
																method
																}</option>
														))
													}
													
											
												</select>
												
												
											</FormGroup>
											</Col>
											<Col md="3" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Order Status
												</Label>
												
													<select className="form-select" name="currentstatus" value={searchDataList['currentstatus'] != undefined && searchDataList['currentstatus'] != null ?searchDataList['currentstatus'] :'' } onChange={(e)=>onChangeForm(e)} >
													<option value="">--Select--</option>
													{
														orderStatus.map((d,i)=>(
															<option value={`${d._id}`}>{d.name}</option>
														))
													}
													
											
												</select>
												
												
											</FormGroup>
											
													</Col>
														<Col md="3" sm="12" style={{paddingTop:"10px"}}>
														<FormGroup>
													<Label className="col-form-label pt-0">
														<span>*</span> Order Condition
													</Label>
												
													<select className="form-select" name="currentlist" value={searchDataList['currentlist'] != undefined &&  searchDataList['currentlist'] != null ?searchDataList['currentlist']:'' } onChange={(e)=>onChangeForm(e)} >
													<option value="">--Select--</option>
													{
														orderListStatus.map((k,i)=>(
															<option value={`${k}`}>{k}</option>
														))
													}
													
											
												</select>
												</FormGroup>
												
														</Col>
													<Col md="3" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Start Date
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="datetime-local"
													
													name='startdate'
													value={searchDataList['startdate']}
													onChange={(e)=>onChangeForm(e)}
													
												/>
											</FormGroup>
											
													</Col>
														<Col md="3" sm="12" style={{paddingTop:"10px"}}>
														<FormGroup>
													<Label className="col-form-label pt-0">
														<span>*</span> End Date
													</Label>
													<Input
														className="form-control"
														id="validationCustom01"
														type="datetime-local"
														
														name={'enddate'}
														value={searchDataList['enddate']  }
														onChange={(e)=>onChangeForm(e)}
														
													/>
												</FormGroup>
												
														</Col>
													{
														orderSearch != '' ?
														<Col md="3" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> {orderSearch}
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													required=""
													name={`${orderSearch}`}
													value={ searchDataList[orderSearch]}
													onChange={(e)=>onChangeForm(e)}
												/>
											</FormGroup>
											
													</Col>
														:''
													}
													
												
											
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${searchData}`} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="10" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													
													
												/>
											</FormGroup>
											
													</Col>
												<Col sm="2" style={{paddingTop:"30px"}}>
												
												<FormGroup className="mb-0">
												<div className="product-buttons text-center">
												<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
													
												</div>
											</FormGroup>
											
												</Col>
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right">
												<FormGroup >
												<select className="form-select" name="status" value={searchDataList.status} onChange={(e)=>searchForm(e)} >
													<option value="">--Multiple Deleted--</option>
													<option value="Deleted">Deleted</option>
													<option value="Duplicate">Duplicate</option>
													<option value="Select Export">Select Export</option>
												</select>
												</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('export')}
														style={{'marginRight':'10px'}}
													>
													<i class="fa fa-download" aria-hidden="true"></i>
													</Button>
													
								
												</div>
												<h6 style={{textAlign:'center',color:`${message.color}`}}>{message.message}</h6>
												<div className="clearfix"></div>
												{
													isLoading ?
													<UiLoading/>
													:<div id="basicScenario" className="product-physical">
													<Datatable
														myData={data}
														multiSelectOption={true}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={false}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
														checkedValues={checkedValue}
													/>
												</div>
												}
												
												</CardBody>
						</Card>
											
										:
										
											isLoading ?
											<UiLoading/>:
										<FormDatas saveData={saveData} formSubmite={formSubmite} orderStatus={orderStatus} orderListStatus={orderListStatus} singleData={singleData} operationType={operationType}/>
										
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
	);
};

export default Invoice;
