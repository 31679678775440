import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab, } from "react-tabs";
import MultiSelect from '@khanacademy/react-multi-select';
import { Button, Form,Container,Card,CardBody,Input,FormGroup,Label,Col,Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import urlPath from "../../url";
import { getCommon, getSingleCommon, postCommon } from "../../data/common";
import Cookies from "js-cookie";
import { getDropDown } from "../../data/catlog/product";
import { UiLoading } from "../ui/uiloading";
import { fileUpload } from "../../data/catlog/fileupload";
import { MENUITEMS } from "../../constants/menu";

const TabsetPage = ({title,description,menuItem,singleData}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [isLoading,setIsLoading] = useState(true)
	const [sociallink,setSocialLink]= useState([])
	const [document,setDocument]=useState([])
	const [country,setCountry]=useState([])
	const [value, setValue] = useState('')
	console.log(singleFormData)
	
	const selectDropDown=(e,type)=>{
		const single ={...singleFormData}
		const data=[]
		if(type== 'module'){
			if(single.moduelist != undefined &&  single.moduelist != null){
				for(let i=0 ; i < e.length;i++){
					if(!single.moduelist.some(d=> d.value == e[i])){
						single.moduelist.push(MENUITEMS.find(k=> k.value == e[i]))
					}
				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
						console.log(MENUITEMS.find(k=> k.value == e[i]))
					   data.push(MENUITEMS.find(k=> k.value == e[i]))
				}
				single.moduelist=data
            }
		}else if(type== 'document'){
			
			if(single.documentlist != undefined &&  single.documentlist != null){
				for(let i=0 ; i < e.length;i++){
					if(!single.documentlist.some(d=> d.value == e[i])){
						const d={
							value:document.find(k=> k.value == e[i]).value,
							name:document.find(k=> k.value == e[i]).label,
							number:'',
							file:''
						   }
						   single.documentlist.push(d)
					}

				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
					const d={
						value:document.find(k=> k.value == e[i]).value,
						name:document.find(k=> k.value == e[i]).label,
						number:'',
						file:''
					   }
					   data.push(d)
				}
				single.documentlist=data
            }
		}else if(type== 'sociallink'){
			if(single.sociallist != undefined &&  single.sociallist != null){
				for(let i=0 ; i < e.length;i++){
					
					if(!single.sociallist.some(d=> d.value == e[i])){
						const d={
							value:sociallink.find(k=> k.value == e[i]).value,
							name: sociallink.find(k=> k.value == e[i]).label,
							url:''
						   }
						   single.sociallist.push(d)
					}

				}
			
			}else{
				for(let i=0 ; i < e.length;i++){
					const d={
						value:sociallink.find(k=> k.value == e[i]).value,
						name: sociallink.find(k=> k.value == e[i]).label ,
						url:''
					   }
					   data.push(d)
				}
				single.sociallist=data
				single['sociallist'] =data
            }
			
		}

		single[type] =e
		console.log(single)
		
		setSingleFormData({...single})
	}
	const onChangeFormData=(e)=>{
		const single={...singleFormData}
       const {name,value}= e.target;
	   single[name]=value
	   console.log(single)
	   setSingleFormData({...single})
 	}
	const socialLinkChange=(e,type,i)=>{
		const {name,value}= e.target;
		const single={...singleFormData}
		
		single[type][i][name]= value
		setSingleFormData({...single})
	}
	const onChangeFileUplaodData=async(e,type)=>{
		console.log(type)
		e.preventDefault()
		const single = {...singleFormData}
		
		for(let i =0; i < e.target.files.length; i++ ){
        
            const data = new FormData();
            data.append('type', 'company');
            data.append('image', e.target.files[i]);
        await fileUpload(data).then((result) => {
              if (result.success) {
				single[type]= result.photo
				
              }
            });
        }
	single['documenttype']='company'
	console.log(single)
	setSingleFormData({...single})
	}
	const formSubmite =async (e) => {
		console.log('hello')
		e.preventDefault()
		
		singleFormData['operation']='Edit'
		console.log(singleFormData)
	await postCommon(urlPath.companyprofile,singleFormData,singleFormData._id).then(async result => {
			if (result.success) {
				alert('Updated Data Successfully')
			} else {

			}
		})

	}
	const checkTheInputValue=(e,parent,child)=>{
		const modulelist = [...singleFormData.moduelist]
		console.log(e.target.checked)
		const {name,value} = e.target;
		console.log(name)
		console.log(parent)
		if(child !== undefined && child !== null){
			
				modulelist.find(d=> d.title == parent).children.find(l=> l.title == child)[name]=e.target.checked
			
		// console.log(modulelist.find(d=> d.title == parent).children.find(l=> l.title == child))
		}else{
			
				modulelist.find(d=> d.title == parent)[name]=e.target.checked
					
		}
		console.log(modulelist)
		singleFormData.moduelist = modulelist
		setSingleFormData({...singleFormData})
	}
	return (
		<Fragment>
	
		<Container fluid={true}>
		
				<div>
				
								<>
								<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						
								<Tab className="nav-link" >Empoyee Details</Tab>
								<Tab className="nav-link" >Sub Module</Tab>
								
								<Tab className="nav-link" >Document</Tab>
								
								
								<Tab className="nav-link" >Social Link</Tab>
								
					</TabList>
							<Form className="needs-validation" onSubmit={(e)=>formSubmite(e)}>
								<TabPanel>
									<Row>
										<Col sm={3} md={3} lg={3}>
								<FormGroup>
								<Label className="col-form-label pt-0">
													<span>*</span> Employee Name
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={singleFormData.name}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Email
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="email"
													value={singleFormData.email}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Mobile
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
											
													name="mobile"
													value={singleFormData.mobile}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Alternative Number
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
											
													name="email"
													value={singleFormData.mobile}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Date Of Birth
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="date"
													
													name="dateofbirth"
													value={singleFormData.dateofbirth}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													User Id
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="userid"
													value={singleFormData.userid}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Password
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="password"
													
													name="password"
													value={singleFormData.password}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Department
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="supportemail"
													value={singleFormData.supportemail}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Designation
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="supportemobile"
													value={singleFormData.supportemobile}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
								
										
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Label
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="label"
													value={singleFormData.label}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Address
												</Label>
												<textarea
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="address"
													value={singleFormData.address}
													onChange={(e)=>onChangeFormData(e)}
												></textarea>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 City
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
													
													name="city"
													value={singleFormData.city}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Districts
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="districts"
													value={singleFormData.districts}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 State
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="state"
													value={singleFormData.state}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Country
												</Label>
												
													<select
												className="form-control"
												id="validationCustom0"
											
												required=""
												name="country"
												onChange={(e)=>onChangeFormData(e)}
												value={singleFormData.country}
											>
												<option  className="form-control">Default</option>
												{
													country.map((d,i)=>(
														<option value={`${d.value}`} className="form-control">{d.label}</option>
													))
												}
												
												
											</select>
										
											</FormGroup>	
										</Col>
										<Col sm={3} md={3} lg={3}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													Pincode
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="number"
													
													name="pincode"
													value={singleFormData.pincode}
													onChange={(e)=>onChangeFormData(e)}
												/>
											</FormGroup>	
										</Col>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Updated Password
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="passwordupdate"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="passwordupdate"
														value={false}
														checked={`${singleFormData.passwordupdate}` == 'false' || singleFormData.passwordupdate== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									
									
									</Row>
												
									
								</TabPanel>
								<TabPanel>
								<Col sm={12} md={12} lg={12}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Main Module Assign
												</Label>
												<MultiSelect
													options={ menuItem || []}
													selected={singleFormData.module || []}
													onSelectedChanged={(e)=>selectDropDown(e,'module')}
												/>
											</FormGroup>	
										</Col>
										{
											singleFormData.moduelist != undefined && singleFormData.moduelist != null &&singleFormData.moduelist.map((d,i)=>(
												d.type == 'link'?
												<div className="attribute-blocks">

												<h5 className="f-w-600 mb-3">	<Row>
														<Col xl="3" sm="3">
															<Label className="form-label">{d.title}</Label>
														</Col>
														<Col xl="9" sm="8">
															<FormGroup className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Read"
											checked={d.Read}
											onChange={(e)=>checkTheInputValue(e,d.label)}
										/>
																	Read
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Write"
											checked={d.Write}
											onChange={(e)=>checkTheInputValue(e,d.label)}
										/>
																	Write
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Delete"
											checked={d.Delete}
											onChange={(e)=>checkTheInputValue(e,d.label)}
										/>
																	Delete
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="View"
											checked={d.View}
											onChange={(e)=>checkTheInputValue(e,d.label)}
										/>
																	View
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Tarsh"
											checked={d.Tarsh}
											onChange={(e)=>checkTheInputValue(e,d.label)}
										/>
																	Tarsh
																</Label>
															</FormGroup>
														</Col>
													</Row></h5>
												
											</div>
												:			<div className="attribute-blocks">

												<h5 className="f-w-600 mb-3">{d.label} </h5>
												{
													d.children !== undefined && d.children != null && d.children.map((ks,l)=>(
														ks.type == 'link'?
														<Row>
														<Col xl="3" sm="3">
															<Label className="form-label">{ks.title}</Label>
														</Col>
														<Col xl="9" sm="8">
														<Col xl="9" sm="8">
															<FormGroup className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											onChange={(e)=>checkTheInputValue(e,d.label,ks.title)}
											name="Read"
											checked={d.Read}
									
										/>
																	Read
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Write"
											checked={d.Write}
											onChange={(e)=>checkTheInputValue(e,d.label,ks.title)}
										/>
																	Write
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Delete"
											checked={d.Delete}
											onChange={(e)=>checkTheInputValue(e,d.label,ks.title)}
										/>
																	Delete
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="View"
											checked={d.View}
											onChange={(e)=>checkTheInputValue(e,d.label,ks.title)}
										/>
																	View
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
											name="Tarsh"
											checked={d.Tarsh}
											onChange={(e)=>checkTheInputValue(e,d.label,ks.title)}
										/>
																	Tarsh
																</Label>
															</FormGroup>
														</Col>
														</Col>
													</Row>
														:
														ks.children !== undefined && ks.children != null && ks.children.map((kss,l)=>(
															<Row>
														<Col xl="3" sm="3">
															<Label className="form-label">{kss.title}</Label>
														</Col>
														<Col xl="9" sm="8">
															<FormGroup className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
										/>
																	Allow
																</Label>
																<Label className="d-block">
																<Input
											className="checkbox_animated"
											id="chk-ani1"
											type="checkbox"
										/>
																	Deny
																</Label>
															</FormGroup>
														</Col>
													</Row>
														))
														
													))
												}
											
												
											</div>
									
											))
										}
								</TabPanel>
								<TabPanel>
								<Col sm={12} md={12} lg={12}>
										<FormGroup>
											<Label className="col-form-label">
												Document 
											</Label>
											<div className="form-group" id="colors">
												<MultiSelect
													options={ document || []}
													selected={singleFormData.document || []}
													onSelectedChanged={(e)=>selectDropDown(e,'document')}
												/>
											</div>
										</FormGroup>	
										</Col>
								<Row>
									<Col sm={3}>
										Document
									</Col>
									<Col sm={3}>
										Number
									</Col>
									<Col sm={3}>
										file
									</Col>
								</Row>
                             {
								singleFormData.documentlist !== undefined && singleFormData.documentlist !== null?
								singleFormData.documentlist.map((d,l)=>(
									<Row>
										<Col sm={3} md={3} lg={3}>
								<FormGroup>
							
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={d.name}
												disabled={true}
												onChange={(e)=>socialLinkChange(e,'documentlist',l)}
												/>
											</FormGroup>
										</Col>
										<Col sm={3} md={3} lg={3}>
								<FormGroup>
								
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="number"
													value={d.number}
													onChange={(e)=>socialLinkChange(e,'documentlist',l)}

												/>
											</FormGroup>
										</Col>
										<Col sm={3} md={3} lg={3}>
								<FormGroup>
						
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
											     
													name="file"
													onChange={(e)=>socialLinkChange(e,'documentlist',l)}
												/>
											</FormGroup>
										</Col>
									</Row>
								))
								
								
								:''
							 }
							</TabPanel>
								<TabPanel>
								<Col sm={12} md={12} lg={12}>
										<FormGroup>
								<Label className="col-form-label pt-0">
													 Social Link
												</Label>
												<MultiSelect
													options={ sociallink || []}
													selected={singleFormData.sociallink || []}
													onSelectedChanged={(e)=>selectDropDown(e,'sociallink')}
												/>
											</FormGroup>	
										</Col>
							<Row>
									<Col sm={3}>
										Social Link
									</Col>
									<Col sm={3}>
										URL
									</Col>
									
								</Row>
							{
								singleFormData.sociallist !== undefined && singleFormData.sociallist !== null?
								singleFormData.sociallist.map((d,l)=>(
									<Row>
										<Col sm={3} md={3} lg={3}>
								<FormGroup>
							
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												required={true}
													name="name"
													value={d.name}
													onChange={(e)=>socialLinkChange(e,'sociallist',l)}
												/>
											</FormGroup>
										</Col>
										<Col sm={3} md={3} lg={3}>
								<FormGroup>
								
												<Input
													className="form-control"
													id="validationCustom01"
													type="text"
												
													name="url"
													value={d.url}
													onChange={(e)=>socialLinkChange(e,'sociallist',l)}
												/>
											</FormGroup>
										</Col>
										
									</Row>
								))
								
								
								:''
							 }
							</TabPanel>
							<div className="pull-right">
					<input type="submit"  color="primary" value={'Edit'} className="btn btn-primary"/>
					
				</div>
								</Form>
							
							
					
					
					
				</Tabs>
				
								</>
			
				
			</div>
				
		</Container>
	</Fragment>
	
	);
};

export default TabsetPage;
