import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";

const Datatable = ({allOPeration,handleChangrRow,changeRowData,onRowClicked,checkedValues,handleRemoveRow,handleRowsPerPageChange,currentPage,action,copyData,deletes,view,copy,edit, myData, myClass, multiSelectOption, pagination,viewData,editData,deleteData,handlePageChange }) => {
	console.log(checkedValues)
	const [open, setOpen] = useState(false);
	// const [checkedValues, setCheckedValues] = useState([...checkedValues]);
	// const [data, setData] = useState(myData);
	console.log(myData[0])
	// const renderEditable = (cellInfo) => {
	// 	console.log(cellInfo)
	// 	return (
	// 		<div
	// 			style={{ backgroundColor: "#fafafa" }}
	// 			contentEditable
	// 			suppressContentEditableWarning
	// 			onBlur={(e) => {
	// 				data[cellInfo.index][cellInfo.index.id] = e.target.innerHTML;
	// 				setData({ myData: data });
	// 			}}
	// 			dangerouslySetInnerHTML={{
	// 				__html: myData[cellInfo.index][cellInfo.index.id],
	// 			}}
	// 		/>
	// 	);
	// };

	// const handleDelete = (index) => {
	// 	if (window.confirm("Are you sure you wish to delete this item?")) {
	// 		const del = data;
	// 		del.splice(index, 1);
	// 		setData([...del]);
	// 	}
	// 	toast.success("Successfully Deleted !");
	// };
	// const onOpenModal = () => {
	// 	setOpen(true);
	// };

	// const onCloseModal = () => {
	// 	setOpen(false);
	// };

	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const columns = [];
	for (const key in myData[0]) {
		let editable = null;
		if (key === "image") {
			editable = null;
		}
		if (key === "status") {
			editable = null;
		}
		if (key === "avtar") {
			editable = null;
		}
		if (key === "vendor") {
			editable = null;
		}
		if (key === "order_status") {
			editable = null;
		}
		// if (key === "Mrp") {
		// 	editable = renderEditable;
		// }
		if (key !== "id" &&  key !== "Mrp" &&  key !== "Qty"  &&  key !== "Selling") {
			
			columns.push({
				name: <b>{Capitalize(key.toString())}</b>,
				header: <b>{Capitalize(key.toString())}</b>,
				selector: row => row[key],
				Cell: editable,
				"data-tag":"allowRowEvents",
				style: {
					textAlign: "center",
					
				},
				sortable: true,
				wrap: true,
			});
		}
		if (key == "Mrp") {
			
			columns.push({
				name: <b>{Capitalize(key.toString())}</b>,
				header: <b>{Capitalize(key.toString())}</b>,
				selector: row => row[key],
				cell: (row,) => (
					<div key={row.id}>
						<span id={`mrp-value-${row.id}`}
								className="mrp-value" >
							{row.Mrp}<i className="fa fa-edit" style={{width: 35,fontSize: 20,padding: 11,color: "rgb(00, ff, 69)",cursor:'pointer'}} onClick={()=>changeRowData(row.id,'mrp')}></i>
							</span>
							<input

								id={`mrp${row.id}`}
								className="mrp-hide"
								type="text"
								name='Mrp'
								value={row.Mrp}
							    onChange={(e) => handleChangrRow(e, row.id)}
								style={{display:'none'}}
							/>
						
					</div>
				),
				
				style: {
					textAlign: "center",
					
				},
				sortable: true,
				wrap: true,
			});
		}
		if (key == "Selling") {
			
			columns.push({
				name: <b>{Capitalize(key.toString())}</b>,
				header: <b>{Capitalize(key.toString())}</b>,
				selector: row => row[key],
				cell: (row,) => (
					<div key={row.id}>
						<span id={`selling-value-${row.id}`}
								className="mrp-value" >
							{row.Selling}<i className="fa fa-edit" style={{width: 35,fontSize: 20,padding: 11,color: "rgb(00, ff, 69)",cursor:'pointer'}} onClick={()=>changeRowData(row.id,'selling')}></i>
							</span>
							<input

								id={`selling${row.id}`}
								className="mrp-hide"
								type="text"
								name='Selling'
								value={row.Selling}
							    onChange={(e) => handleChangrRow(e, row.id)}
								style={{display:'none'}}
							/>
						
					</div>
				),
				
				style: {
					textAlign: "center",
					
				},
				sortable: true,
				wrap: true,
			});
		}
		if (key == "Qty") {
			
			columns.push({
				name: <b>{Capitalize(key.toString())}</b>,
				header: <b>{Capitalize(key.toString())}</b>,
				selector: row => row[key],
				cell: (row,) => (
					<div key={row.id}>
						<span id={`qty-value-${row.id}`}
								className="mrp-value" >
							{row.Qty}<i className="fa fa-edit" style={{width: 35,fontSize: 20,padding: 11,color: "rgb(00, ff, 69)",cursor:'pointer'}} onClick={()=>changeRowData(row.id,'qty')}></i>
							</span>
							<input

								id={`qty${row.id}`}
								className="mrp-hide"
								type="text"
								name='Qty'
								value={row.Qty}
							    onChange={(e) => handleChangrRow(e, row.id)}
								style={{display:'none'}}
							/>
						
					</div>
				),
				
				style: {
					textAlign: "center",
					
				},
				sortable: true,
				wrap: true,
			});
		}
	}
	

	
		columns.push({
			name: (
				<input type="checkbox" name='checkvalue' onChange={(e) => allOPeration(e,)} />
				// <button
				// 	className="btn btn-danger btn-sm btn-delete mb-0 b-r-4" onClick={(e)=>allOPeration(e)}>
				// 	All Checkbox
				// </button>
			),
			id: "delete",
			accessor: (str) => "delete",
			cell: (row) => (
				<div>
					<span>
						
						<input
							type="checkbox"
							name={row.id}
							// defaultChecked
							onChange={(e) => handleRemoveRow(e, row.id)}
							checked={checkedValues.some(ids => `${ids}` == `${row.id}`)}
						/>
					</span>
				</div>
			),
			style: {
				textAlign: "center",
			},
			sortable: false,
		});
	
			columns.push({
				name: <b>Action</b>,
				id: "delete",
				accessor: (str) => "delete",
				cell: (row, index) => (
					<div>
						<span >
							{
								deletes ?
								<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
									cursor:'pointer'
								}}
								onClick={() => deleteData('Deleted',index)}
								
							></i>
								:''
							}
						
						</span>
						<span>
							{
								edit?
								<i
								onClick={()=>editData('Edit',index)}
								className="fa fa-pencil"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "rgb(40, 167, 69)",
									cursor:'pointer'
								}}
							></i>
								:''
							}
							
						
						</span>
						<span>
							{
								view?
								<i
								onClick={()=>viewData('View',index)}
								className="fa fa-eye"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "rgb(00, ff, 69)",
									cursor:'pointer'
								}}
							></i>
								:''
							}
						
						
						</span>
						<span>
						{
							copy?
							<i
								onClick={()=>copyData('copy',index)}
								className="fa fa-copy"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "rgb(FF, FF, FF)",
									cursor:'pointer'
								}}
							></i>
							
							:''
						}
							
						
						</span>
					</div>
				),
				style: {
					textAlign: "center",
				},
				sortable: false,
			});
		
	

	return (
		<div>
			<Fragment>
				<DataTable
					data={myData}
					columns={columns}
					className={myClass}
					pagination={pagination}
					striped={true}
					center={true}
					onChangePage={handlePageChange}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handleRowsPerPageChange}
					
				/>

				<ToastContainer />
			</Fragment>
		</div>
	);
};

export default Datatable;
