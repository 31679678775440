
import React, { useState,Fragment } from 'react';
import SortableTree from 'react-sortable-tree';
import { removeNodeAtPath } from 'react-sortable-tree';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    CardHeader
  } from "reactstrap"
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import Breadcrumb from "../common/breadcrumb";
import { useEffect } from 'react';
import { getHeaderMenuTree,postHeaderMenuTree,getSingleHeaderMenuTree } from '../../data/menu/headertree';
import { UiLoading } from "../ui/uiloading";
import urlPath from '../../url';
import { getCommon,postCommon } from '../../data/common';
export  const  FooterMenuTree =()=> {
  const [treeData,setTreeData]=useState([])
  const [isLoading,setIsLoading]=useState(true)
  const [saveData,setSaveData] = useState(false)
  useEffect(() => {
    getCommon(urlPath.footerMenuTree).then(result=>{
      console.log(result)
      const treeDatas =[]
      for(let i=0;i<result.data.length;i++){
          const d={
            title:result.data[i].titles.name,
            titles:result.data[i].titles._id,
            children:[],
            expanded: true,
            ...result.data[i].titles
          }
          if(result.data[i].children !== 0){
            for(let j=0;j<result.data[i].children.length;j++){
              d.children.push({
                title:result.data[i].children[j].titles.name,
                titles:result.data[i].children[j].titles._id,
                children:[],
                expanded: true,
                ...result.data[i].children[j].titles
              })
              if(result.data[i].children[j].children !== 0){
                for(let k=0;k<result.data[i].children[j].children.length;k++){
                  d.children[j].children.push({
                    title:result.data[i].children[j].children[k].titles.name,
                    titles:result.data[i].children[j].children[k].titles._id,
                    children:[],
                    expanded: true,
                    ...result.data[i].children[j].children[k].titles
                  })
                  if(result.data[i].children[j].children[k].children !== 0){
                    for(let l=0;l<result.data[i].children[j].children[k].children.length;l++){
                      d.children[j].children[k].children.push({
                        title:result.data[i].children[j].children[k].children[l].titles.name,
                        titles:result.data[i].children[j].children[k].children[l].titles._id,
                        children:[],
                        expanded: true,
                        ...result.data[i].children[j].children[k].children[l].titles
                      })
                      if(result.data[i].children[j].children[k].children[l].children !== 0){
                        for(let m=0;m<result.data[i].children[j].children[k].children[l].children.length;m++){
                          d.children[j].children[k].children[l].children.push({
                            title:result.data[i].children[j].children[k].children[l].children[m].titles.name,
                            titles:result.data[i].children[j].children[k].children[l].children[m].titles._id,
                            children:[],
                            expanded: true,
                            ...result.data[i].children[j].children[k].children[l].children[m].titles
                          })
                        }
                        
                      }
                    }
                   
                  }
                }
              
              }
            
            }
        
              
          }
         
          treeDatas.push(d)
          
      }
      setTreeData(treeDatas)
      setIsLoading(false)
       
    })
  },[])
  const treeDataValue=(tree)=>{
   
    setTreeData([...tree])
  }
     
  const removeNode =(e,rowInfo)=>{
  
    let {node, treeIndex, path} = rowInfo;
    console.log(rowInfo.node)
 
    
  const remove_data = removeNodeAtPath({
      treeData: treeData,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })
   setTreeData(remove_data)
   
  //  postCommon({
  //  data:rowInfo.node,
  //  operation:'Delete',
  //  _id:rowInfo.node._id,
  //  treeData:remove_data
  // },urlPath.footerMenuTree ).then(result=>{
  //   console.log(result)
  // })
  }
    const submitHandeler=()=>{
      setSaveData(true)
      postCommon(urlPath.footerMenuTree,treeData).then(result=>{
        console.log(result)
        if(result.success){
          alert('Updated Data Successfully')
        }
          setSaveData(false)
      })
    }
    return (
      <Fragment>
      <Breadcrumb title={`Header Menu`} parent="Dashboard" operationType={'Tree'} sublink={'header-menu'}/>
      
          <Container fluid={true}>
            <Row>
              <Col sm="12">
 <Card><CardHeader>
                      {/* <h5> Header Menu </h5> */}
                      <div className="btn-popup pull-right" style={{margin:0,textAlign:'end'}}>
                          {saveData?
                            <i
                              class="fa fa-save btn btn-primary"
                           
                              onClick={() => submitHandeler()}
                            >Loading...</i>
                            :
                            <i
                            class="fa fa-save btn btn-primary"
                          
                            onClick={() => submitHandeler()}
                          ></i>
                          }
                            </div>
                    </CardHeader>
                      <CardBody>
                      
                        <div className="clearfix"></div>
                        {
                          isLoading ?
                          <UiLoading/>
                          :
                          <>
                         
                          <div style={{ height:  parseInt(treeData.length * 400) }}>
                          <div style={{ height: parseInt(treeData.length * 400) }}>

                          <SortableTree
                          treeData={treeData}
                          onChange={treeData => treeDataValue(treeData)}
                          isVirtualized={false}
                          generateNodeProps={(rowInfo ,index) => ({
                            buttons: [
                              <button label='Delete'
                              onClick={(event) => removeNode(event,rowInfo)}>Remove</button>
                                      
                                    
                                      
                                   ],
                                })}
                        />
                        </div>
                        </div>
                        </>
                        }
                       
                      </CardBody>
                    </Card>
               

              </Col>
            </Row>
          </Container>
     </Fragment>
    );
  
}

export default FooterMenuTree;