import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { getCommon, postCommon, getSingleCommon,getExportFile, postGallery } from '../../data/common'
import { getMedia,getSingleMedia,postMedia} from '../../data/media';
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import MediaImageLoading from "../common/mediaimageloading";
import { UiLoading } from "../ui/uiloading";
import urlPath, { numberOfpage } from "../../url";
import Cookies from "js-cookie";
import FormDatas from "./form";
const Media = () => {
	// const [searchData,setSearchData] = useState('')
	const [checkedValue,setCheckedValues] = useState([])
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const [data,setData]= useState([])
	const [searchData,setSearchData] = useState('')
	const [photo,setPhotoList] = useState([])
	const [showAddMoreButton, setShowAddMoreButton] = useState(true);
	const [imageLoading,setImageLoading] = useState(false)
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)	
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const [searchDataList,setsearchDataList] = useState({})
	const [message,setMessage] = useState('')
	const [color,setColor] = useState('')
	useEffect( () => {
		getCommon(urlPath.media,'Table',Cookies.get('jwt')).then(result => {
			const data = []
			for (let i = 0; i < result.data.length; i++) {
				const d = {
					id: result.data[i]._id,
					'Sl': `${parseInt(i + 1)}`,
					image: <img alt={`${result.data[i].name}`} src={`${urlPath.imageurl}/${result.data[i].uploadtype}/${result.data[i].url}`} style={{width:50,height:50}}/>,
					name: result.data[i].name,
					url:`${urlPath.imageurl}/${result.data[i].uploadtype}/${result.data[i].url}`,
					Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,

				}
				data.push(d)
				setData([...data])
			}
			setIsLoading(false)
		}).catch(err => {
			console.log(err)
		})

	}, [])
	const handleImageSelect = async(e) => {
		e.preventDefault();
		console.log(e.target.files.length)
        setShowAddMoreButton(true);
        setImageLoading(true)
		let photoList =[...photo]
	   for(let i =0; i < e.target.files.length; i++ ){
            console.log(i)
            console.log( e.target.files[i])
            const data = new FormData();
            data.append('type', 'products');
            data.append('image', e.target.files[i]);
         await postGallery(urlPath.media,data,Cookies.get('jwt')).then((result) => {
              if (result.success) {
              
                photoList.push(result.photo)
              }
            });
        }
		setPhotoList([...photo])
       
        setImageLoading(false)
		window.location.href='/media'

    };
	const showForm = (Type,id) => {
	
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.media,Cookies.get('jwt')).then(result => {
				setSingleData(result.data)
				setIsLoading(false)
			})

		} else {
			setIsLoading(false)
		}

	}
	const copyData = (type,url) => {
		console.log(url)
		  const textField = document.createElement('textarea');
		  textField.innerText = url;
		  document.body.appendChild(textField);
		  textField.select();
		  document.execCommand('copy');
		  textField.remove();
		};
		const searchForm=(e)=>{
			const {name,value} = e.target;
			const single ={...searchDataList}
			single[name] = value
			setsearchDataList(single)
			// getSearchData
		 }
		 const searchForamData=(e)=>{
			e.preventDefault();
			searchDataList.operation='Search'
			setIsLoading(true)
			postCommon(urlPath.category,searchDataList,Cookies.get('jwt')).then(result=>{
				const data = []
							for (let i = 0; i < result.data.length; i++) {
								const d = {
									id: result.data[i]._id,
									'Sl': `${parseInt(i + 1)}`,
									name: result.data[i].name,
									Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
				
								}
								data.push(d)
								setData([...data])
							}
							setOperationType('Table')
							setIsLoading(false)
			})
		 }
		 const showSearch=async(type)=>{
			setSearchData(type)
			if(type == 'export'){
				setColor('red')
				setMessage(`Data ${type}...`)
				await getExportFile(urlPath.excelreport,'category',Cookies.get('jwt')).then(result=>{
					window.location.href=`${urlPath.fileurl}/download/${result.data.fileName}`
					setColor('green')
					setMessage(`Data ${type} Successfully`)
					
				}).catch(err=>{
					console.log(err)
				})
			}
			
		}
	
		 const handlePageChange = (page) => {
			
			setCurrentPage(page)
			   
		  };
		  const handleRowsPerPageChange = (newRowsPerPage) => {
			console.log(newRowsPerPage)
			setRowsPerPage(newRowsPerPage)
			
		};
		const handleRemoveRow =(e,id)=>{
			console.log(e,id)
			console.log(e.target.checked)
			const checkData =[...checkedValue]
			if(e.target.checked){
				checkData.push(id)
				setCheckedValues([...checkData])
			}else{
			 const d=checkData.filter(df=> console.log(`${df}` != `${id}`))
		
			setCheckedValues([...d])
			}
		
			
		}
	return (
		<Fragment>
			<Breadcrumb title="Media" parent="Media" />
			
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<h5>Dropzone Media</h5>
					</CardHeader>
					<CardBody>
						<Row>
						<Col sm="12" md="12" xl="12">
						<MediaImageLoading showAddMoreButton={showAddMoreButton} imageLoading={imageLoading} handleImageSelect={(e)=>handleImageSelect(e)} image ={photo} />
						 </Col>
						</Row>
				
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<h5>Media File List</h5>
					</CardHeader>
					<CardBody>
					{ 
					operationType == 'Table'?
					<>
						{
								searchData == 'search'?
								<Form onSubmit={(e)=>searchForamData(e)}>
							<div className="row">
							
							<Col md="10" sm="12" style={{paddingTop:"10px"}}>
							<FormGroup>
						<Label className="col-form-label pt-0">
							<span>*</span> Name
						</Label>
						<Input
							className="form-control"
							id="validationCustom01"
							type="text"
							required=""
							name="name"
							value={searchDataList['name']}
							onChange={(e)=>searchForm(e)}
							
						/>
					</FormGroup>
					
							</Col>
						<Col sm="2" style={{paddingTop:"30px"}}>
						
						<FormGroup className="mb-0">
						<div className="product-buttons text-center">
						<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
							
						</div>
					</FormGroup>
					
						</Col>
				
								<div>
							
								</div>

							</div>
							</Form>
							:''
						}
							<div className="btn-popup pull-right" style={{textAlign:'right'}}>
												<Button
														type="button"
														color="primary"
														onClick={(e) => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
											
								
												</div>					
						<div
							id="batchDelete"
							className="category-table media-table coupon-list-delete"
						>
							{
								isLoading ?
								<UiLoading/>
								:<Datatable
								multiSelectOption={false}
								myData={data}
								pageSize={10}
								pagination={true}
								class="-striped -highlight"
								deleteData={(type,id) => showForm(type,id)}
								viewData={(type,id) => showForm(type,id)}
							    editData={(type,id) => showForm(type,id)}
								copyData={(type,id) => copyData(type,id,)}
								edit={false}
								deletes={true}
								view={true}
								copy={true}
								handlePageChange={(page)=>handlePageChange(page)}
								currentPage={currentPage}
								handleRowsPerPageChange={handleRowsPerPageChange}
								handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
								checkedValues={checkedValue}
							/>
							}
							
						</div>
						</>
						:
						isLoading ?
								<UiLoading/>
								:
								<Card>
												{/* <CardHeader>
														<h5>Description</h5>
														
													</CardHeader>	 */}
											<CardBody>
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
									<Button
											type="button"
											color="primary"
											onClick={() => showSearch('back')}
											style={{'marginRight':'10px'}}
										>
									<i class="fa fa-arrow-left" aria-hidden="true"></i>
										</Button>
								</div>
								<div className="clearfix"></div>
									<div id="basicScenario" className="product-physical">

									</div>
						<FormDatas singleData={singleData}/>
						</CardBody>
												</Card>
					
						}
						
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Media;
