import React, { Fragment, useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import urlPath from "../../url";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, CardFooter } from "reactstrap";
import { getCommonStatus } from "../../data/common";
const CMS = () => {
	let navigate = useNavigate(); 
	const [operationType, setOperationType] = useState('Table')
	const [dataList,setDataList] =useState([
		{
		title:'Type Of Description',
		name:'Type Of Description',
		description:'',
		url:`${urlPath.descriptiontype}`,
		total:0,
		active:0,
		inactive:0,
		linkTable:`${process.env.PUBLIC_URL}/description-type/Table`,
		linkInactive:`${process.env.PUBLIC_URL}/description-type/Inactive`,
		linkActive:`${process.env.PUBLIC_URL}/description-type/Active`,
		linkAdd:`${process.env.PUBLIC_URL}/description-type/Add`,
		isLoading:true,
		},
		{
			title:'Type Of Product',
			name:'Type Of Product',
			url:`${urlPath.producttype}`,
			total:0,
			active:0,
			inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/different-type-product/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/different-type-product/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/different-type-product/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/different-type-product/Add`,
			isLoading:true,
		},
		{
			title:'Product Description',
			name:'Product Description',
			url:`${urlPath.productDescription}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/product-description/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/product-description/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/product-description/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/product-description/Add`,
			isLoading:true,
		},
		{
			title:'List Of Page',
			name:'List Of Page',
			url:`${urlPath.pagelist}`,
			total:0,
	     	active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/type-of-page/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/type-of-page/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/type-of-page/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/type-of-page/Add`,
			isLoading:true,
		},
		{
			title:'Page Content',
			name:'Page Content',
			url:`${urlPath.pagelist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/type-of-page-content/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/type-of-page-content/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/type-of-page-content/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/type-of-page-content/Add`,
			isLoading:true,
		},
		{
			title:'Page Structure',
			name:'Page Structure',
			url:`${urlPath.varientlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
			isLoading:true,
		},
		{
			title:'Page Banner',
			name:'Page Banner',
			url:`${urlPath.pagebanner}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/page-banner/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/page-banner/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/page-banner/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/page-banner/Add`,
			isLoading:true,
		},
		{
			title:'Type Of Document',
			name:'Type Of Document',
			url:`${urlPath.varientlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/page-banner/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/page-banner/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/page-banner/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/page-banner/Add`,
			isLoading:true,
		},
		{
			title:'Type Of Link',
			name:'Type Of Link',
			url:`${urlPath.varientlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/page-banner/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/page-banner/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/page-banner/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/page-banner/Add`,
			isLoading:true,
		},
		{
			title:'Type Of Tax',
			name:'Type Of Tax',
			url:`${urlPath.varientlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/page-banner/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/page-banner/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/page-banner/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/page-banner/Add`,
			isLoading:true,
		},
		{
			title:'Type Of Department',
			name:'Type Of Department',
			url:`${urlPath.varientlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
			isLoading:true,
		},
		{
			title:'Type Of Designation',
			name:'Type Of Designation',
			url:`${urlPath.varientlist}`,
			total:0,
		    active:0,
		    inactive:0,
			linkTable:`${process.env.PUBLIC_URL}/varient-list/Table`,
			linkInactive:`${process.env.PUBLIC_URL}/varient-list/Inactive`,
			linkActive:`${process.env.PUBLIC_URL}/varient-list/Active`,
			linkAdd:`${process.env.PUBLIC_URL}/varient-list/Add`,
			isLoading:true,
		},
		
	])
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	
	useEffect(()=>{
		for(let i=0; i <dataList.length; i++){
			getCommonStatus(dataList[i].url).then(result=>{
				console.log(result)
				dataList[i].total= result.total
				dataList[i].active= result.active
				dataList[i].inactive= result.inactive
				dataList[i].isLoading=false
				console.log(dataList[i])
				setDataList([...dataList])
			})
		}
		
	   },[])
	   const urlRedirect = (path) =>{ 
		navigate(path);
	   }
	return (
		<Fragment>

			{/* <!-- Container-fluid starts--> */}
			<Fragment>
				<Breadcrumb title={`CMS`} parent="Dashboard" operationType={operationType} sublink={'cms'}/>
				{/* <!-- Container-fluid starts--> */}
		
						<Container fluid={true}>
							<Row>
							{
									dataList.map((d,i)=>(
										<Col sm="6">

										<Card><CardHeader>
													   <h5>{d.name}</h5>
												   </CardHeader>
													   <CardBody>
													   
														   <div className="clearfix"></div>
														   <p></p>
													   </CardBody>
													   <CardFooter>
					  							<div className="product-buttons text-center">
												  <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkTable)}>
											   <span><img src="/table.png" style={{width:'25px'}} />({!d.isLoading?`${d.total}` :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="success" style={{backgroundColor:'green'}} onClick={()=>urlRedirect(d.linkActive)}>
											   <span><img src="/active.png" style={{width:'25px'}} />({!d.isLoading?(d.active) :<p>isLoading...</p>})</span>
											   </Button>
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkInactive)}>
											   <span><img src="/inactive.png" style={{width:'25px'}} />({!d.isLoading?d.inactive :<p>isLoading...</p>})</span>
											   </Button>
										   
											   <Button type="button" color="primary" onClick={()=>urlRedirect(d.linkAdd)}><span><img src="/add.png" style={{width:'25px'}} /></span></Button>
											   
									   
										   </div>
					  </CardFooter>
												   </Card>
									   
									   </Col>	
									))
								}
							</Row>
						</Container>
						

				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default CMS;

