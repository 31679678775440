import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { getCategory } from '../../../data/catlog/category';
import { getBrand } from '../../../data/catlog/brand';
import { UiLoading } from "../../ui/uiloading";
import { getCommon } from "../../../data/common";
import urlPath from "../../../url";
import TextEditor from "../../ui/texteditor";
import { fileUpload,deletedUploadFile } from "../../../data/catlog/fileupload";
import Cookies from "js-cookie";
const FormDatas = ({isLoading,formSubmite,singleData,attribute,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [attributeData,setattributeData]= useState(attribute)
	const [dropDownLoading,setDropDownLoading]= useState(false)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const megaMenu=async (e)=>{
		const {name,value}= e.target
		console.log(name,value)
		setDropDownLoading(true)
	let url =''
	 if(value== 'brand'){
		url=`${urlPath.brand}`
	   
	 }else  if(value== 'category'){
		url=`${urlPath.category}`
	 }else if(value== 'page'){
		url=`${urlPath.pagelist}`
	 }else if(value== 'type of product'){
		url=`${urlPath.producttype}`
	 }
	 await getCommon(url,'Table',Cookies.get('jwt')).then(result=>{
		if(result.success){
			setSingleFormData({...singleFormData, linktype:value})
			setattributeData(result.data)
			setDropDownLoading(false)
		}else{
			setattributeData([])
			setDropDownLoading(false)		
		}
	})
	}
	const handleBannerImageSelect= async(e) => {
		const single ={...singleFormData}
		console.log(e.target.files[0])
        e.preventDefault();
      
			const data = new FormData();
            data.append('type', 'banner');
            data.append('image', e.target.files[0]);
         await fileUpload(data).then((result) => {
              if (result.success) {
				single.mobilebanner= result.photo
              }
            });
			single.uploadtype='banner'
		setSingleFormData({...single})
      

    };
	const handleImageSelect = async(e) => {
		const single ={...singleFormData}
		console.log(e.target.files[0])
        e.preventDefault();
      
			const data = new FormData();
            data.append('type', 'banner');
            data.append('image', e.target.files[0]);
         await fileUpload(data).then((result) => {
              if (result.success) {
				single.banner= result.photo
              }
            });
			single.uploadtype='banner'
		setSingleFormData({...single})
      

    };
	const pageAddData=(e,type)=>{
		const {name,value} = e.target;
		const single ={...singleFormData}
		single[type]=value
		setSingleFormData({...single})
	}
	const deletedFile=async (e,type,file,filetype)=>{
		e.preventDefault()
		const single ={...singleFormData}
	
	
	 await deletedUploadFile(singleFormData[filetype],type).then((result) => {
		  if (result.success) {
			delete single.uploadtype
			delete single[filetype]
			
			setSingleFormData({...single})
		  }
		});
		

	}
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
							
							<Card>
							<CardHeader>
									<h5>General</h5>
								</CardHeader>
								<CardBody>
							
								
									<div className="digital-add needs-validation">
										<div class="form-group">
											<Label className="col-form-label pt-0">
													Script Type<span>*</span>
													<span>:</span>
												</Label>
												<select
													className="form-control"
													id="validationCustom0"
													type="text"
													required=""
													name="typeofscript"
													value={singleFormData.typeofscript}
													onChange={(e)=>onChangeFormData(e)}
												>
												<option  className="form-control">Default</option>
												<option  value="header" className="form-control">Header</option>
												<option  value="body" className="form-control">Body</option>
												<option  value="footer" className="form-control">Footer</option>
											
											    </select>
											</div>
											<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Type Of Script
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="textarea"
												required={true}
												name="scripttype"
												value={singleFormData.scripttype}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true'?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false'?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
