import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
import { getCategory } from '../../../data/catlog/category';
import { getBrand } from '../../../data/catlog/brand';
import { UiLoading } from "../../ui/uiloading";
import { getCommon } from "../../../data/common";
import urlPath from "../../../url";
import TextEditor from "../../ui/texteditor";
import { fileUpload } from "../../../data/catlog/fileupload";
const FormDatas = ({isLoading,formSubmite,singleData,attribute,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})
	const [imageLoading,setImageLoading] = useState(false)
	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
		
	}
	const handleImageSelect = async(e) => {
      
        setImageLoading(true)
		console.log(e.target.files)
        
            const data = new FormData();
            data.append('type', 'typeoflink');
            data.append('image', e.target.files[0]);
         await fileUpload(data).then((result) => {
              if (result.success) {
				singleFormData.photo =result.photo
              
              }
            });
        
		
		singleFormData.uploadtype='typeoflink'
        setSingleFormData(singleFormData)
        setImageLoading(false)

    };
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
						
								<FormGroup>
											<Label className="col-form-label pt-0">
											<span style={{color:'red'}}>*</span>Name
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required={true}
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												SVG Code
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												
												name="svg"
												value={singleFormData.svg}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label pt-0">
												Description
											</Label>
											<textarea
												className="form-control"
												id="validationCustom05"
												type="text"
												required=""
												name='description'
												value={singleFormData.description}
												onChange={(e)=>onChangeFormData(e)}
											></textarea>
										</FormGroup>
										{
											imageLoading ? 
											<h6 style={{textAlign:'center',color:'red'}}>Loading...</h6>
											:	<FormGroup>
											<Label className="col-form-label pt-0">
												 Social Image
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="file" 
												accept="image/*"
												onChange={(e)=>handleImageSelect(e)}
												
											/>
										</FormGroup>
										}
									
										{
											singleFormData.photo !== ''?
												<div  style={{ position: 'relative' }}>
                      						  <img src={`${urlPath.imageurl}/${singleFormData.uploadtype}/${singleFormData.photo}`}  style={{ width: '100%', height: '100px' }} />
											<button
												className="deleteButton"
												// onClick={() => handleImageDelete(index)}
												style={{position:'absolute',top:'5px', left:'5px',color:'#fff',backgroundColor:'#f00',borderWidth:0}}
											>
												X
											</button>
                  							  </div>
											
										
											
											:''
										}
										<FormGroup>
											<Label className="col-form-label pt-0">
												Order
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												name="order"
												value={singleFormData.order}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
										
										<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
												
											</div>
										</FormGroup>
						
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
