
import React, { useState,Fragment } from 'react';
import SortableTree from 'react-sortable-tree';
import { removeNodeAtPath } from 'react-sortable-tree';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    CardHeader
  } from "reactstrap"
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import Breadcrumb from "../../common/breadcrumb";
import { useEffect } from 'react';

import { UiLoading } from "../../ui/uiloading";
import urlPath from '../../../url';
import { getCommon,postCommon, postCommonPage } from '../../../data/common';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
export  const  SubPageStructueRelation =()=> {
    const {pageid,id} = useParams()
  const [treeData,setTreeData]=useState([])
  const [isLoading,setIsLoading]=useState(true)
  const [saveData,setSaveData] = useState(false)

    useEffect(()=>{
        // Cookies.set('jwt',id)
        getCommon(urlPath.subpageStructure,pageid,Cookies.get('jwt')).then(result=>{
          console.log(result.data)
          const treeDatas =[]
          for(let i=0;i < result.data.subdescriptionrelation.length;i++){
              const d={
                title:result.data.subdescriptionrelation[i].titles.name,
                titles:result.data.subdescriptionrelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.subdescriptionrelation[i].children !== 0){
                for(let j=0;j< result.data.subdescriptionrelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.subdescriptionrelation[i].children[j].titles.name,
                    titles:result.data.subdescriptionrelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.subdescriptionrelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.subdescriptionrelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.subdescriptionrelation[i].children[j].children[k].titles.name,
                        titles:result.data.subdescriptionrelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.subdescriptionrelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.subdescriptionrelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data[i].children[j].children[k].children[l].titles.name,
                            titles:result.data[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          if(result.data.subdescriptionrelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.subdescriptionrelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.subdescriptionrelation[i].children[j].children[k].children[l].children[m].titles.name,
                                titles:result.data.subdescriptionrelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
              
          }
          setTreeData(treeDatas)
          setIsLoading(false)
           
        })
      },[])
  const treeDataValue=(tree)=>{
   
    setTreeData([...tree])
  }
     
  const removeNode =(e,rowInfo)=>{
  
    let {node, treeIndex, path} = rowInfo;
    console.log(rowInfo.node)
 
    
  const remove_data = removeNodeAtPath({
      treeData: treeData,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })
   setTreeData(remove_data)
   
  //  postCommon({
  //  data:rowInfo.node,
  //  operation:'Delete',
  //  _id:rowInfo.node._id,
  //  treeData:remove_data
  // },urlPath.headerMenuTree ).then(result=>{
  //   console.log(result)
  // })
  }
 
    const submitHandeler=async()=>{
        setSaveData(true)
     await postCommonPage(urlPath.pageStructure,treeData,pageid,Cookies.get('jwt')).then(result=>{
          setSaveData(false)
          alert("Data Updated Successfully")
        })
      }
    return (
      <Fragment>
      <Breadcrumb title={`Header Menu`} parent="Dashboard" operationType={'Tree'} sublink={'header-menu'}/>
      
          <Container fluid={true}>
            <Row>
              <Col sm="12">
 <Card><CardHeader>
                      {/* <h5> Header Menu </h5> */}
                      <div className="btn-popup pull-right" style={{margin:0,textAlign:'end'}}>
                          {saveData?
                            <i
                              class="fa fa-save btn btn-primary"
                           
                              onClick={() => submitHandeler()}
                            >Loading...</i>
                            :
                            <i
                            class="fa fa-save btn btn-primary"
                          
                            onClick={() => submitHandeler()}
                          ></i>
                          }
                            </div>
                    </CardHeader>
                      <CardBody>
                      
                        <div className="clearfix"></div>
                        {
                          isLoading ?
                          <UiLoading/>
                          :
                          <>
                         
                          <div style={{ height:  parseInt(treeData.length * 400) }}>
                          <div style={{ height: parseInt(treeData.length * 400) }}>

                          <SortableTree
                          treeData={treeData}
                          onChange={treeData => treeDataValue(treeData)}
                          isVirtualized={false}
                          generateNodeProps={(rowInfo ,index) => ({
                            buttons: [
                              <button label='Delete'
                              onClick={(event) => removeNode(event,rowInfo)}>Remove</button>
                                      
                                    
                                      
                                   ],
                                })}
                        />
                        </div>
                        </div>
                        </>
                        }
                       
                      </CardBody>
                    </Card>
               

              </Col>
            </Row>
          </Container>
     </Fragment>
    );
  
}

export default SubPageStructueRelation;