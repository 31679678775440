import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Card, CardFooter, Form, Input, Label } from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import TextEditor from "../../ui/texteditor";
import ImageUploader from "../../common/imageuploader";
import { postCommon } from "../../../data/common";
import urlPath from "../../../url";
import AddTwoRow from "../../ui/addTwoRow";
import AddOneRow from "../../ui/addOneRow";
import { fileUpload } from "../../../data/catlog/fileupload";
import ProductImage from "../../common/productImage";
import Cookies from "js-cookie";
const TabsetPage = ({data,title,description,saveData,formSubmite}) => {
	console.log("Gopal Rate")
	console.log(data)
	const [datalist,setdataList] = useState(data)
	const [value, setValue] = useState('')
	const [imageLoading,setImageLoading]=useState(false)
	const [showAddMoreButton,setShowAddMoreButton] =useState(false)
	console.log(description)
	const onChange = (e) =>{
		setValue(e)
	}
	const handleImageSelect = async(e,type,index) => {
        e.preventDefault();
        setShowAddMoreButton(true);
        setImageLoading(true)
		const d =[...datalist]
		console.log(type)
		console.log(index)
		const photo =[...datalist[index][type]]
       
        for(let i =0; i < e.target.files.length; i++ ){
          
            const data = new FormData();
            data.append('type', 'products');
            data.append('image', e.target.files[i]);
         await fileUpload(data).then((result) => {
              if (result.success) {
              
                photo.push(result.photo)
              }
            });
        }
		d[index][type]=photo
		setdataList(d)
        setImageLoading(false)

    };
	const onEditorChange = (evt,type, index) => {
		const allData =[...datalist]
		allData[index][type] =evt.editor.getData()
		setdataList(allData)
	};
	const submitForm=(e)=>{
		e.preventDefault()
		postCommon(urlPath.productDescription,data,Cookies.get('jwt')).then(result=>{
			window.location.href='/product-description'
		})
	}
	const addTwoValueChange = (e,i,type,index) => {
		    const d =[...datalist]
		
			const allData =[...datalist[index][type]]
			
			const { name, value } = e.target;
			if (allData.length === parseInt(i) + 1) {
				allData.push({
				title: '',
				value: '',
			  });
			}
			
			
			allData[i][name]=value
			
			d[index][type]= allData
			
			setdataList(d)
	};

	
		
		

		const removeTwoValueChange=(evt,i,type, index)=>{
			
			const d =[...datalist]
			console.log(i)
			const allData =[...datalist[index][type]]
			allData.splice(i, 1);
			if (allData.length == 0) {
				allData.push({
					title: '',
					value: '',
				  });
				}
				
			
				d[index][type]= allData
				
				setdataList(d)
		}
	const changeValue=(e,type,index)=>{
		const d =[...datalist]
			const {name,value} = e.target;
			d[index][name]=value
			
			d[index][type]= d
			setdataList(d)
	}
	return (
		<Fragment>
			<div>
				<Tabs>
					{/* <Card> */}
					<h6></h6>
					<TabList className="nav nav-tabs tab-coupon">
						{
							description !== undefined && description !== null && description.map((d,i)=>(
								d.pagetype !== 'blank'?
								<Tab className="nav-link" >{d.name}</Tab>:''
							))
						}
					
					</TabList>
					{
						description !== undefined && description !== null && description.map((d,i)=>(
							<TabPanel>
							<Form className="needs-validation" onSubmit={(e)=>formSubmite(e,data)}>
								<h6>The Product Name is {title} <span style={{color:'red'}}>{d.name}</span></h6>
								{
									d.pagetype === 'description'?
									
										<TextEditor title={`${d.name}`} description={datalist[datalist.findIndex(k=> k.descriptiontype == d._id)][d.pagetype]} name={`${d.pagetype}`} onEditorChange={(e)=>onEditorChange(e,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))} datadisable={false}/>
									
									:d.pagetype === 'photo'?
									<ProductImage showAddMoreButton={datalist[datalist.findIndex(k=> k.descriptiontype == d._id)][d.pagetype].length !==0 ? true:false} imageLoading={imageLoading} handleImageSelect={(e)=>handleImageSelect(e,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))} image ={datalist[datalist.findIndex(k=> k.descriptiontype == d._id)][d.pagetype] } />
									
									:d.pagetype === 'oneitem'?
									
									<AddOneRow multiRow={datalist[datalist.findIndex(k=> k.descriptiontype == d._id)][d.pagetype]} addTwoValueChange={(e,k)=>addTwoValueChange(e,k,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))} removeTwoValueChange={(e,k)=>removeTwoValueChange(e,k,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))}/>
									
									:d.pagetype === 'twoitem'?
										
										<AddTwoRow  multiRow={datalist[datalist.findIndex(k=> k.descriptiontype == d._id)][d.pagetype]} addTwoValueChange={(e,k)=>addTwoValueChange(e,k,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))} removeTwoValueChange={(e,k)=>removeTwoValueChange(e,k,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))}/>
										
									:d.pagetype !== 'blank'?
									<Input
									className="form-control"
									id="validationCustom02"
									type={`${d.pagetype}`}
									name={`${d.pagetype}`}
									value={datalist[datalist.findIndex(k=> k.descriptiontype == d._id)][d.pagetype]}
									onChange={(e)=>changeValue(e,d.pagetype,datalist.findIndex(k=> k.descriptiontype == d._id))}
								/>
								:''
								}
								<CardFooter>
								<div className="pull-right">
										{
											saveData ?
											<button className="btn btn-primary" disabled={true}>Save...</button>
											:<input  type="submit" className="btn btn-primary"  value="Save" />
										}
										
								</div>
									
								</CardFooter>
							</Form>
						</TabPanel>
						))
					}
				{/* </Card>	 */}
					
				</Tabs>
				
			</div>
		</Fragment>
	);
};

export default TabsetPage;
