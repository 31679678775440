import React, { Fragment, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Breadcrumb from "../../common/breadcrumb";
// import data from "../../../assets/data/pro_list";
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import FormDatas from './form'
import { getCategory, postCategory, getSingleCategory } from '../../../data/catlog/category'
import { getCommon, postCommon, getSingleCommon } from '../../../data/common'
import { UiLoading } from "../../ui/uiloading";
import { useParams } from "react-router-dom";
import urlPath, { numberOfpage } from "../../../url";
const EmailMarketing = () => {
	const [searchDataList,setsearchDataList] = useState({})
	const [checkedValue,setCheckedValues] = useState([])
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const {slug} = useParams()
	const [searchData,setSearchData] = useState('')
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,photo:[] })
	const showForm = (Type,id) => {
	
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.cart,Cookies.get('jwt')).then(result => {
				setSingleData(result.data)
				setIsLoading(false)
			})

		} else {
			setIsLoading(false)
		}

	}
	useEffect(() => {
	
			getCommon(urlPath.cart,'Table',Cookies.get('jwt')).then(result => {
			
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						name: result.data[i].name,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
	
					}
					data.push(d)
					setData([...data])
				}
				setIsLoading(false)
			}).catch(err => {
				console.log(err)
			})
		
			
		
	

	}, [])
	
	const formSubmite = (e, singleFormData) => {
		e.preventDefault()
		setIsLoading(true)
		singleFormData.operation=operationType
		
		postCommon(urlPath.cart,singleFormData,Cookies.get('jwt')).then(async result => {
			if (result.success) {
		await getCommon(urlPath.cart,'Table',Cookies.get('jwt')).then(result => {
					
					const data = []
					for (let i = 0; i < result.data.length; i++) {
						const d = {
							id: result.data[i]._id,
							'Sl': `${parseInt(i + 1)}`,
							name: result.data[i].name,
							Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
		
						}
						data.push(d)
						setData([...data])
					}
					setOperationType('Table')
					setIsLoading(false)
				}).catch(err => {
					console.log(err)
				})
			} else {

			}
		})

	}
	const showSearch=(type)=>{
	
		setSearchData(type)
	}
	const handlePageChange = (page) => {
	
		setCurrentPage(page)
		   
	  };
	  const handleRowsPerPageChange = (newRowsPerPage) => {
		console.log(newRowsPerPage)
		setRowsPerPage(newRowsPerPage)
		
	};
	const handleRemoveRow =(e,id)=>{
		console.log(e,id)
		console.log(e.target.checked)
		const checkData =[...checkedValue]
		if(e.target.checked){
			checkData.push(id)
			setCheckedValues([...checkData])
		}else{
		 const d=checkData.filter(df=> console.log(`${df}` != `${id}`))
	
		setCheckedValues([...d])
		}
	
		
	}
	const searchForm=(e)=>{
		const {name,value} = e.target;
		const single ={...searchDataList}
		single[name] = value
		setsearchDataList(single)
		// getSearchData
	 }
	return (
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Email Campaign`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Email Campaign`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'email-campaign'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
											<CardBody>
												{
													searchData == 'search'?
													<Form>
												<div className="row">
												<Col md="5">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select" >
											<option value="">--Select--</option>
											<option value="1">Date</option>
											<option value="4">Product Name</option>
											
										</select>
									</FormGroup>
												</Col>
												<Col md="5" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Country
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												
												
											/>
										</FormGroup>
										
												</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="10" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													
													
												/>
											</FormGroup>
											
													</Col>
												<Col sm="2" style={{paddingTop:"30px"}}>
												
												<FormGroup className="mb-0">
												<div className="product-buttons text-center">
												<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
													
												</div>
											</FormGroup>
											
												</Col>
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right">
												<FormGroup >
												<select className="form-select" name="status" value={searchDataList.status} onChange={(e)=>searchForm(e)} >
													<option value="">--Multiple Deleted--</option>
													<option value="Deleted">Deleted</option>
													<option value="Duplicate">Duplicate</option>
													<option value="Select Export">Select Export</option>
												</select>
												</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('import')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-upload" aria-hidden="true"></i>
													</Button>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('export')}
														style={{'marginRight':'10px'}}
													>
													<i class="fa fa-download" aria-hidden="true"></i>
													</Button>
											
								
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
													{
														isLoading ?
														<UiLoading/>
														:<Datatable
														myData={data}
														multiSelectOption={false}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={true}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRowsPerPageChange={handleRowsPerPageChange}
														handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
														checkedValues={checkedValue}
													/>
													}
													
												</div>
												</CardBody>
						</Card>
											
										:
										isLoading ?
														<UiLoading/>
														:
										<FormDatas isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData,operationType)} />
										
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
	);
};

export default EmailMarketing;

