import React, { Fragment, useState } from "react";

import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row,Form } from "reactstrap";
const FormDatas = ({formSubmite,singleData,operationType}) => {
	const [singleFormData,setSingleFormData] = useState({...singleData})

	const onChangeFormData = (e) =>{
		const {name,value} = e.target;
		singleFormData[name]=value
		setSingleFormData({...singleFormData})
	}
	
	
	return (
	
			<Container fluid={true}>
				
					<Form onSubmit={(e)=>formSubmite(e,singleFormData)}>
					<Row className="product-adding">
						<Col xl="12">
{/* 							
							<Card>
								<CardHeader>
									<h5>Description</h5>
								</CardHeader>
								<CardBody> */}
								<FormGroup>
											<Label className="col-form-label pt-0">
                                            Description Type<span style={{color:'red'}}>*</span>
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required={true}
												name="name"
												value={singleFormData.name}
												onChange={(e)=>onChangeFormData(e)}
												
											/>
										</FormGroup>
								
										<div class="form-group">
											<label>
                                            Description Option<span style={{color:'red'}}>*</span>
												<span>:</span>
											</label>
											<select
												className="form-control"
												id="validationCustom0"
												type="text"
												required={true}
												name="pagetype"
												value={singleFormData.pagetype}
												onChange={(e)=>onChangeFormData(e)}
											>
												<option  className="form-control">Default</option>
												<option value="description" className="form-control">Description</option>
												<option value="photo" className="form-control">Image</option>
												<option  value="file" className="form-control">File</option>
												<option  value="textarea" className="form-control">Text Area</option>
												<option  value="refurl" className="form-control">Ref Url</option>
												<option  value="input" className="form-control">Input</option>
                                                <option  value="twoitem" className="form-control">Multiple Two</option>
                                                <option  value="oneitem" className="form-control">Multiple One</option>
                                                <option  value="blank" className="form-control">Blank</option>
											</select>
											</div>
											<FormGroup>
											<Label className="col-form-label">
												<span>*</span> Status
											</Label>
											<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani"
														type="radio"
														name="status"
														value={true}
														checked={`${singleFormData.status}` == 'true' || singleFormData.status== 'true' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Enable
												</Label>
												<Label className="d-block">
													<Input
														className="radio_animated"
														id="edo-ani1"
														type="radio"
														name="status"
														value={false}
														checked={`${singleFormData.status}` == 'false' || singleFormData.status== 'false' ?true:false}
														onChange={(e)=>onChangeFormData(e)}
													/>
													Disable
												</Label>
											</div>
										</FormGroup>
									<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={`${operationType}`}/>
											
											</div>
										</FormGroup>
								{/* </CardBody>
							</Card> */}
							
						</Col>
					
					</Row>
					</Form>
				
				
			</Container>
		
	);
};

export default FormDatas;
