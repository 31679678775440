import React, { Fragment,useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import {  useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import setAuthToken from '../../util/setToken';
import urlPath from "../../url";
import { postCommon } from "../../data/common";

const LoginTabset = () => {
	const [submitLoading,setSubmitLoading]= useState(false)
	const [errorMessage,setErrorMessage] = useState({message:''})
	const [singleData,setSingleData]= useState({userid:'',password:''})
	const [password,setPassword] = useState(false)
	const history = useNavigate();

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	
    const submiteForm=(e)=>{
		e.preventDefault()
		setSubmitLoading(true)
		postCommon(`${urlPath.admin}`,singleData).then(result=>{
			
			if(result.success){
				localStorage.setItem('user',JSON.stringify(result.data) )
				setAuthToken(result.token);
				window.location.href='/dashboard'
			}else{
				setErrorMessage({message:result.message})
			}
			
		})
	}
	const changeValue=(e)=>{
		setErrorMessage({})
		const {name,value} = e.target;
	
		  singleData[name]=value
		setSingleData({...singleData})
	}
	const forGotPassword=(type)=>{
		if(type == 'Login'){
			setPassword(false)
		}else{
			setPassword(true)
		}
		
	}
	return (
		<div>
			<Fragment>
				{/* <Tabs> */}
					{/* <TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							Login
						</Tab>
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<Unlock />
							Register
						</Tab>
					</TabList> */}

					{/* <TabPanel> */}
					{
						!password ?<Form className="form-horizontal auth-form" onSubmit={(e)=>submiteForm(e)}>
						<h6 style={{color:'#fff',backgroundColor:'#FF0000',textAlign:'center'}}>{errorMessage.message}</h6>
						<FormGroup>
							<Input
								required={true}
								name="userid"
								type="text"
								className="form-control"
								placeholder="User Id"

								id="exampleInputEmail1"
								value={singleData.userid}
								onChange={(e)=>changeValue(e)}
								
							/>
						</FormGroup>
						<FormGroup>
							<Input
								required={true}
								name="password"
								type="password"
								className="form-control"
								placeholder="Password"
								value={singleData.password}
								onChange={(e)=>changeValue(e)}
							/>
						</FormGroup>
						<div className="form-terms">
							<div className="custom-control custom-checkbox me-sm-2">
								<Label className="d-block">
									<Input
										className="checkbox_animated"
										id="chk-ani2"
										type="checkbox"
									/>
									Reminder Me{" "}
									<span className="pull-right">
										{" "}
										<Button style={{color:'#fff'}} className="btn btn-default forgot-pass p-0" onClick={()=>forGotPassword()}>
											lost your password
										</Button>
									</span>
								</Label>
							</div>
						</div>
						<div className="form-button">
							
							<input className="btn btn-primary" type="submit" value="Login" />
								
							
						</div>
						
					</Form>:
					<Form className="form-horizontal auth-form" onSubmit={(e)=>submiteForm(e)}>
						<br/>
					<br/>
					<br/>
					<h6 style={{color:'#fff',backgroundColor:'#FF0000',textAlign:'center'}}>{errorMessage.message}</h6>
					<FormGroup>
						<Input
							required={true}
							name="userid"
							type="text"
							className="form-control"
							placeholder="Email / Mobile Number"

							id="exampleInputEmail1"
							value={singleData.userid}
							onChange={(e)=>changeValue(e)}
						
						/>
					</FormGroup>
					
					<div className="form-terms">
						<div className="custom-control custom-checkbox me-sm-2">
							<Label className="d-block">
								
								<span className="pull-right">
									{" "}
									<Button style={{color:'#FFF'}} className="btn btn-default forgot-pass p-0" onClick={()=>forGotPassword('Login')}>
										Login
									</Button>
								</span>
							</Label>
						</div>
					</div>
					<div className="form-button">
						
						<input className="btn btn-primary" type="submit" value="Forgot Password" />
							
						
					</div>
					
					<br/>
					<br/>
					<br/>
					<br/>
				</Form>
					}
						
					
			</Fragment>
		</div>
	);
};

export default LoginTabset;
