import React, { Fragment, useState,useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Cookies from 'js-cookie';
import "react-toastify/dist/ReactToastify.css";
// import { data } from "../../../assets/data/category";
import FormDatas from './form'
import { getAttribute, postAttribute, getSingleAttribute } from '../../../data/catlog/attribute'
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Label,FormGroup,Input, Form } from "reactstrap";
import { UiLoading } from "../../ui/uiloading";
import urlPath, { numberOfpage } from "../../../url";
import { getCommon, getSingleCommon, postCommon } from "../../../data/common";
import { useParams } from "react-router-dom";

const SalesAttribute = () => {
	const [searchDataList,setsearchDataList] = useState({})
	const [checkedValue,setCheckedValues] = useState([])
	const [rowsPerPage, setRowsPerPage] = useState(numberOfpage);
	const [currentPage,setCurrentPage] = useState(1);
	const {slug} = useParams()
	const [searchData,setSearchData] = useState('')
	const [saveData,setSaveData] = useState(false)
	const [cookiesValue,setCookiesValue]= useState(Cookies.get('jwt'))
	const [operationType, setOperationType] = useState('Table')
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState([])
	const [singleData, setSingleData] = useState({ status: true,typeofstatus:true,photo:[],orderfinalstage:'No' })
	const showForm = (Type,id) => {
		setOperationType(Type)
		setIsLoading(true)
		if (Type == 'Edit' || Type == 'View' || Type == 'Deleted') {
			getSingleCommon(data[parseInt((currentPage - 1) * `${rowsPerPage}` + id)].id,urlPath.salesAtrribute,Cookies.get('jwt')).then(result => {
				
				setSingleData(result.data)
				setIsLoading(false)
			})

		} else {
			setSingleData({ status: true,typeofstatus:true,photo:[],orderfinalstage:'No' })
			setIsLoading(false)
		}

	}
	useEffect(() => {
	
			getCommon(urlPath.salesAtrribute,'Table',cookiesValue).then(result => {
			
				const data = []
				for (let i = 0; i < result.data.length; i++) {
					const d = {
						id: result.data[i]._id,
						'Sl': `${parseInt(i + 1)}`,
						name: result.data[i].name,
						order:result.data[i].order,
						Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
	
					}
					data.push(d)
					setData([...data])
				}
				setIsLoading(false)
			}).catch(err => {
				console.log(err)
			})
		
			
		
	

	}, [])
	
	const formSubmite = (e, singleFormData) => {
		e.preventDefault()
		singleFormData.operation=operationType
		let validationData = true
		if(singleFormData['order'] != undefined && singleFormData['order'] != null ){
			if(!/^[0-9]+$/.test(singleFormData['order'])){
				validationData =false
				alert('Enter Numeric')
				
			}else{
				validationData =true
			}
		}
		if(validationData){
			setSaveData(true)
			postCommon(urlPath.salesAtrribute,singleFormData,Cookies.get('jwt')).then(async result => {
				if (result.success) {
					if(operationType == 'Add'){
						setIsLoading(true)
					await getCommon(urlPath.salesAtrribute,'Table',Cookies.get('jwt')).then(result => {
							
							const data = []
							for (let i = 0; i < result.data.length; i++) {
								const d = {
									id: result.data[i]._id,
									'Sl': `${parseInt(i + 1)}`,
									name: result.data[i].name,
									order:result.data[i].order,
									Status: result.data[i].status ? <p style={{ color: '#00ff00' }}>ON</p> : <p style={{ color: '#ff0000' }}>OFF</p>,
				
								}
								data.push(d)
								setData([...data])
							}
							setOperationType('Table')
							setIsLoading(false)
						}).catch(err => {
							console.log(err)
						})
				}else{
					alert(`${operationType} Data Updated Successfully`)
					setSaveData(false)
				}
				} else {
	
				}
			})
		}else{
			alert("Order Validation Failed")
		}
		

	}
	const showSearch=(type)=>{
	
		setSearchData(type)
	}
	const handleRemoveRow =(e,id)=>{
		console.log(e,id)
		console.log(e.target.checked)
		const checkData =[...checkedValue]
		if(e.target.checked){
			checkData.push(id)
			setCheckedValues([...checkData])
		}else{
	     const d=checkData.filter(df=> console.log(`${df}` != `${id}`))
	
		setCheckedValues([...d])
		}
	
		
	}
	const searchForm=(e)=>{
		const {name,value} = e.target;
		const single ={...searchDataList}
		single[name] = value
		setsearchDataList(single)
		// getSearchData
	 }
	 const handlePageChange = (page) => {
	
		setCurrentPage(page)
		   
	  };
	return (
		<Fragment>
		{
				operationType == 'Table' ?
				<Breadcrumb title={`Sales Attribute`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'#'} type={true}/>
				:<Breadcrumb title={`Sales Attribute`} parent="Dashboard" operationType={operationType} sublink={'dashboard'} mainlink={'sales-attribute'} type={true}/>
			}
			
			{/* <!-- Container-fluid starts--> */}
			
				<Container fluid={true}>
						<Row>
							<Col sm="12">

								{
									operationType == 'Table' ?
						<Card>
											<CardBody>
												{
													searchData == 'search'?
													<Form>
												<div className="row">
												<Col md="5">
												<FormGroup >
										<Label className="col-form-label">
											<span>*</span> Search Type
										</Label>
										<select className="form-select" >
											<option value="">--Select--</option>
											<option value="1">eBooks</option>
											<option value="2">Graphic Design</option>
											<option value="3">3D Impact</option>
											<option value="4">Application</option>
											<option value="5">Websites</option>
										</select>
									</FormGroup>
												</Col>
												<Col md="5" sm="12" style={{paddingTop:"10px"}}>
												<FormGroup>
											<Label className="col-form-label pt-0">
												<span>*</span> Country
											</Label>
											<Input
												className="form-control"
												id="validationCustom01"
												type="text"
												required=""
												name="country"
												
												
											/>
										</FormGroup>
										
												</Col>
											<Col sm="2" style={{paddingTop:"30px"}}>
											
											<FormGroup className="mb-0">
											<div className="product-buttons text-center">
											<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
												
											</div>
										</FormGroup>
										
											</Col>
									
													<div>
												
													</div>

												</div>
												</Form>
													:searchData == 'import'?	<Form>
													<div className="row">
												
													<Col md="10" sm="12" style={{paddingTop:"10px"}}>
													<FormGroup>
												<Label className="col-form-label pt-0">
													<span>*</span> Upload File
												</Label>
												<Input
													className="form-control"
													id="validationCustom01"
													type="file"
													required=""
													name="country"
													
													
												/>
											</FormGroup>
											
													</Col>
												<Col sm="2" style={{paddingTop:"30px"}}>
												
												<FormGroup className="mb-0">
												<div className="product-buttons text-center">
												<input type="submit" className="btn btn-primary" name="Submit" value={"Search"} />
													
												</div>
											</FormGroup>
											
												</Col>
										
														<div>
													
														</div>
	
													</div>
													</Form>
													:''
												}
												
												<div className="btn-popup pull-right">
												<FormGroup >
												<select className="form-select" name="status" value={searchDataList.status} onChange={(e)=>searchForm(e)} >
													<option value="">--Multiple Deleted--</option>
													<option value="Deleted">Deleted</option>
													<option value="Duplicate">Duplicate</option>
													<option value="Select Export">Select Export</option>
												</select>
												</FormGroup>
												<Button
														type="button"
														color="primary"
														onClick={() => showSearch('search')}
														style={{'marginRight':'10px'}}
													>
												<i class="fa fa-search" aria-hidden="true"></i>
													</Button>
												
												
													<Button
														type="button"
														color="primary"
														onClick={() => showForm('Add')}
													>
													<i class="fa fa-plus" aria-hidden="true"></i>
													</Button>
								
												</div>
												<div className="clearfix"></div>
												<div id="basicScenario" className="product-physical">
													{
														isLoading ?
														<UiLoading/>
														:<Datatable
														myData={data}
														multiSelectOption={false}
														pageSize={10}
														pagination={true}
														class="-striped -highlight"
														deleteData={(type,id) => showForm(type,id)}
														viewData={(type,id) => showForm(type,id)}
														editData={(type,id) => showForm(type,id)}
														edit={true}
														deletes={true}
														view={true}
														copy={false}
														handlePageChange={(page)=>handlePageChange(page)}
														currentPage={currentPage}
														handleRemoveRow={(e,id)=>handleRemoveRow(e,id)}
														checkedValues={checkedValue}
													/>
													}
													
												</div>
												</CardBody>
						</Card>
											
										:isLoading ?
										<UiLoading/>
										:
										<Card>
					
											<CardBody>
											<div className="btn-popup pull-right" style={{textAlign:'right'}}>
									<Button
											type="button"
											color="primary"
											onClick={() => showSearch('back')}
											style={{'marginRight':'10px'}}
										>
									<i class="fa fa-arrow-left" aria-hidden="true"></i>
										</Button>
								</div>
								<div className="clearfix"></div>
										<FormDatas saveData={saveData} isLoading={isLoading} operationType={operationType} singleData={singleData} formSubmite={(e, singleFormData) => formSubmite(e, singleFormData,operationType)} />
										</CardBody>
										</Card>
									}

							</Col>
						</Row>
							
					</Container>
				</Fragment>
	);
};

export default SalesAttribute;
